import App from "./App";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme/theme";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import ApolloProvider from "./apollo-client/ApolloProvider";
import React from "react";
import "./sentry";
import "./hotjar.ts";
import "./assets/styles.css";
import "./config/charts";
import "./Firebase";
import { AuthProvider } from "./Auth/AuthProvider.tsx";
import { BrowserRouter } from "react-router-dom";
import { NetworkMonitoring } from "./NetworkMonitoring/NetworkMonitoring.tsx";

const el = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(el);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <NetworkMonitoring>
          <AuthProvider>
            <ApolloProvider>
              <ChakraProvider cssVarsRoot="body" theme={theme}>
                <App />
              </ChakraProvider>
            </ApolloProvider>
          </AuthProvider>
        </NetworkMonitoring>
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>,
);
