// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import config from "../config";
import { FirebaseOptions } from "@firebase/app";

// Your web app's Firebase configuration
const firebaseConfig: FirebaseOptions = {
  apiKey: config.firebase.apiKey,
  authDomain:
    window.location.hostname === "localhost"
      ? config.firebase.authDomain
      : window.location.hostname,
  projectId: config.firebase.projectId,
  storageBucket: config.firebase.storageBucket,
  messagingSenderId: config.firebase.messagingSenderId,
  appId: config.firebase.appId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;
