export const currencies = [
  {
    id: "CHF",
    name: "Swiss Franc",
    symbol: "CHF",
  },
  {
    id: "EUR",
    name: "Euro",
    symbol: "€",
  },
  {
    id: "GBP",
    name: "British Pound Sterling",
    symbol: "£",
  },
  {
    id: "USD",
    name: "US Dollar",
    symbol: "$",
  },
];

export const findCurrency = (id: string) => {
  return currencies.find((c) => c.id === id);
};
