// a wrapper around the firebase auth
import {
  applyActionCode,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  User,
} from "firebase/auth";
import fb from "../Firebase";
import { useEffect, useMemo, useState } from "react";
import { confirmPasswordReset } from "@firebase/auth";
import config from "../config";

export interface Auth {
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  signup: (email: string, password: string) => Promise<void>;
  user: User | null;
  loginWithGoogle: () => Promise<void>;
  loading: boolean;
}

export const useAuth = () => {
  const auth = getAuth(fb);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const googleProvider = useMemo(() => new GoogleAuthProvider(), []);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      auth.useDeviceLanguage();
      setLoading(false);
      if (!user) {
        setUser(null);
        return;
      }
      setUser(user);
    });
  }, [auth]);

  const login = async (email: string, password: string) => {
    await setPersistence(auth, browserLocalPersistence);
    await signInWithEmailAndPassword(auth, email, password);
  };

  const logout = async () => {
    await signOut(auth);
  };

  const signup = async (email: string, password: string) => {
    await setPersistence(auth, browserLocalPersistence);
    await createUserWithEmailAndPassword(auth, email, password);
  };

  const loginWithGoogle = async () => {
    await setPersistence(auth, browserLocalPersistence);
    config.isLocalhost
      ? await signInWithPopup(auth, googleProvider)
      : await signInWithRedirect(auth, googleProvider);
  };

  const resetPassword = async (email: string) => {
    await sendPasswordResetEmail(auth, email, {
      url: `${window.location.origin}/login`,
      handleCodeInApp: true,
    });
  };

  const changePassword = async (code: string, newPassword: string) => {
    await confirmPasswordReset(auth, code, newPassword);
  };

  const verifyEmail = () => {
    if (!auth.currentUser) {
      return;
    }
    return sendEmailVerification(auth.currentUser);
  };

  const verifyCode = async (code: string) => {
    return applyActionCode(auth, code);
  };

  return {
    login,
    logout,
    signup,
    user,
    loginWithGoogle,
    loading,
    resetPassword,
    changePassword,
    verifyEmail,
    verifyCode,
  };
};
