import { HStack, IconButton, VStack } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { UpcomingBillsList } from "./UpcomingBillsList";
import { UpcomingBillForm } from "./UpcomingBillForm";
import { UpcomingTransaction } from "./types";
import { SectionHeading } from "../components/SectionHeading";
import { gql } from "@apollo/client";
import {
  AccountHeaderAccountsFragment,
  UpcomingBillsFragment,
} from "../generated/graphql";
import { useTranslation } from "react-i18next";

interface UpcomingBillsProps {
  upcomingBills: UpcomingBillsFragment[];
  accounts: AccountHeaderAccountsFragment[];
}

export const UpcomingBills = ({
  upcomingBills,
  accounts,
}: UpcomingBillsProps) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [activeTransaction, setActiveTransaction] = useState<
    UpcomingTransaction | undefined
  >(undefined);

  const handleEditUpcomingTransaction = (t: UpcomingTransaction) => {
    setActiveTransaction(t);
    setEditing(true);
  };

  const handleEditComplete = () => {
    setEditing(false);
    setActiveTransaction(undefined);
  };

  return (
    <VStack spacing={4} alignItems="stretch" width="100%">
      <HStack justifyContent="space-between" alignItems="center" mb={3}>
        <SectionHeading>{t("upcomingTransactions.title")}</SectionHeading>
        <IconButton
          icon={<AddIcon />}
          size="sm"
          aria-label={t("upcomingTransactions.addButton")}
          onClick={() => setEditing(true)}
        />
      </HStack>
      <VStack layerStyle={"frame"} spacing={2} width="100%">
        {editing ? (
          <UpcomingBillForm
            onSave={handleEditComplete}
            onCancel={handleEditComplete}
            transaction={activeTransaction as UpcomingTransaction}
            accounts={accounts}
          />
        ) : (
          <UpcomingBillsList
            onEdit={handleEditUpcomingTransaction}
            upcomingBills={upcomingBills as unknown as UpcomingTransaction[]}
          />
        )}
      </VStack>
    </VStack>
  );
};

UpcomingBills.fragments = {
  transactions: gql`
    fragment UpcomingBills on month_upcoming_transactions {
      id
      date
      type
      value
      wording
      frequency
    }
  `,
};
