import { Flex } from "@chakra-ui/react";
import { IncomeIcon } from "../components/icons/IncomeIcon";
import { ExpenseIcon } from "../components/icons/ExpenseIcon";

import { gql } from "@apollo/client";
import {
  TransactionsAggregateFragment,
  BalancesAggregateFragment,
} from "../generated/graphql";
import { KeyNumber } from "../components/KeyNumber";
import { useTranslation } from "react-i18next";
import { Currency } from "../components/Currency";
import { BalanceIcon } from "../components/icons/BalanceIcon.tsx";

type AccountStatsProps = {
  data: {
    balance: BalancesAggregateFragment;
    income: TransactionsAggregateFragment;
    expenses: TransactionsAggregateFragment;
  };
};

export const AccountStats = ({ data }: AccountStatsProps) => {
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  const income = data?.income.aggregate?.sum?.value || 0;
  const expenses = data?.expenses.aggregate?.sum?.value || 0;
  const balance = data?.balance?.aggregate?.sum?.balance || 0;

  return (
    <Flex gap={4} direction={{ base: "column", md: "row" }}>
      <KeyNumber
        icon={<BalanceIcon boxSize={{ base: 12, md: 16 }} />}
        label={t("common.balanceAvailable")}
        value={<Currency value={balance} />}
      />
      <KeyNumber
        icon={<IncomeIcon boxSize={{ base: 12, md: 16 }} />}
        label={t("common.income")}
        value={<Currency value={income} />}
      />
      <KeyNumber
        icon={<ExpenseIcon boxSize={{ base: 12, md: 16 }} />}
        label={t("common.expense", { count: 2 })}
        value={<Currency value={expenses} />}
      />
    </Flex>
  );
};

AccountStats.fragments = {
  transactions_agg: gql`
    fragment TransactionsAggregate on transactions_new_aggregate {
      aggregate {
        sum {
          value
        }
      }
    }
  `,
  balance_agg: gql`
    fragment BalancesAggregate on accounts_new_aggregate {
      aggregate {
        sum {
          balance
        }
      }
    }
  `,
};
