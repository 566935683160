import logo from "../assets/logo.svg";
import { CenterLayout } from "../components/CenterLayout";
import { Image } from "@chakra-ui/react";

export const SplashScreen = () => {
  return (
    <CenterLayout>
      <Image src={logo} h={12}></Image>
    </CenterLayout>
  );
};
