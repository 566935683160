import { sub } from "date-fns";
import colorLib from "@kurkle/color";
import i18n from "../i18n";
import { enUS, fr } from "date-fns/locale";
import { isNil } from "lodash";

export const intervalToUTC = ({ start, end }: { start: Date; end: Date }) => {
  return {
    start: sub(start, { minutes: start.getTimezoneOffset() }),
    end: sub(end, { minutes: end.getTimezoneOffset() }),
  };
};

export const toUTC = (isoDate: string) => {
  return sub(new Date(isoDate), {
    minutes: new Date(isoDate).getTimezoneOffset(),
  });
};

export const transparentize = (value: string | number[], opacity: number) => {
  const alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return colorLib(value).alpha(alpha).rgbString();
};

export const formatDate = (updated_at: string) => {
  const date = new Date(updated_at);
  const today = new Date();

  let day = "";
  if (date.toDateString() === today.toDateString()) {
    day = i18n.t("common.today"); // Translates "today"
  } else {
    // Define options for date format
    const options = {
      year: "numeric" as const,
      month: "long" as const,
      day: "numeric" as const,
    };
    day = new Intl.DateTimeFormat(i18n.language, options).format(date); // Locale-specific date
  }

  // Define options for time format
  const timeOptions = { hour: "2-digit" as const, minute: "2-digit" as const };
  const time = new Intl.DateTimeFormat(i18n.language, timeOptions).format(date);
  const at = i18n.t("common.at"); // Translates "at"

  return `${day} ${at} ${time}`;
};

export const toDateFnsLocale = (locale: string) => {
  switch (locale.substring(0, 2)) {
    case "en":
      return enUS;
    case "fr":
      return fr;
    default:
      return enUS;
  }
};

export const toLocaleNumber = (value: number, locale: string) => {
  if (isNil(value)) return "";
  return value.toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const parseNumber = (value: string) => {
  if (value === "") return 0;
  // Replace comma with dot for internal processing and remove other non-numeric characters
  const processedValue = value
    .replace(/,/g, ".")
    .replace(/(?!^)-+/g, "") // Remove all minus signs except possibly the first character
    .replace(/[^\d.-]/g, "");
  // Convert to number and return the numeric value or an empty string for invalid inputs
  const numericValue = parseFloat(processedValue);
  return isNaN(numericValue) ? 0 : numericValue;
};

export const formatCompactCurrency = (value: number, lang: string) => {
  const formatter = Intl.NumberFormat(lang, {
    notation: "compact",
    style: "currency",
    currency: "EUR",
  });
  return formatter.format(value);
};
