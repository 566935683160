import { createContext } from "react";
import { Auth, useAuth } from "./useAuth.ts";

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<Auth | undefined>(undefined);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const auth = useAuth();

  return (
    <AuthContext.Provider value={{ ...auth }}>{children}</AuthContext.Provider>
  );
};
