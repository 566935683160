import { Center, Container, ContainerProps } from "@chakra-ui/react";
import { ReactNode } from "react";

export const CenterLayout = ({
  children,
  ...props
}: { children: ReactNode } & ContainerProps) => {
  return (
    <Center w={"100vw"} h={"100vh"} overflow={"scroll"}>
      <Container as={"main"} w={"max"} {...props}>
        {children}
      </Container>
    </Center>
  );
};
