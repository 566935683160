import { FormEvent } from "react";
import { FirebaseError } from "firebase/app";
import { handleAuthErrors } from "./authErrors.ts";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Center,
  CloseButton,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useAuth } from "./useAuth.ts";
import { useTranslation } from "react-i18next";

interface ResetPasswordFormData extends HTMLFormControlsCollection {
  email: HTMLInputElement;
}

interface ResetPasswordFormElement extends HTMLFormElement {
  readonly elements: ResetPasswordFormData;
}

const ForgotPassword = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { resetPassword } = useAuth();
  const { t } = useTranslation();

  const handleReset = async (event: FormEvent<ResetPasswordFormElement>) => {
    event.preventDefault();
    const formData = event.currentTarget.elements;
    try {
      await resetPassword(formData.email.value);
      navigate("/reset-password-email-sent");
    } catch (error) {
      if (error instanceof FirebaseError) {
        toast({
          title: t("auth.resetPasswordError"),
          description: t(handleAuthErrors(error)),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Center w={"100vw"} h={"100vh"} bg="casBackground" overflow={"hidden"}>
      <Box layerStyle={"frame"} p={8} position={"relative"} mt={8} maxW={"md"}>
        <CloseButton
          onClick={() => navigate("/login")}
          position={"absolute"}
          right={4}
          top={4}
        />
        <form onSubmit={handleReset}>
          <VStack
            spacing={4}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
          >
            <VStack spacing={0} alignItems={"stretch"}>
              <Text textStyle={"sectionTitle"}>{t("auth.resetPassword")}</Text>
              <Text textStyle="sectionSubtitle">
                {t("auth.resetPasswordDescription")}
              </Text>
            </VStack>
            <FormControl isRequired>
              <FormLabel htmlFor="email">{t("common.email")}</FormLabel>
              <Input id="email" type="email" />
            </FormControl>
            <Button type="submit" w={"full"} variant={"primary"}>
              {t("auth.resetPasswordButton")}
            </Button>
          </VStack>
        </form>
      </Box>
    </Center>
  );
};

export default ForgotPassword;
