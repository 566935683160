import { gql, useMutation } from "@apollo/client";

export const useMarkAsRead = () => {
  const [mutate, { loading }] = useMutation(QUERY, {
    update: (cache, { data }) => {
      const id = data?.update_user_notifications_by_pk?.id;
      cache.modify({
        id: cache.identify({ __typename: "user_notifications", id }),
        fields: {
          acknowledged() {
            return true;
          },
        },
      });
    },
  });

  const markAsRead = async (id: string, onCompleted?: () => void) => {
    await mutate({
      variables: {
        id,
        acknowledged: true,
      },
      onCompleted,
    });
  };

  return { markAsRead, loading, query: QUERY };
};

export const QUERY = gql`
  mutation MarkAsRead($id: uuid!, $acknowledged: Boolean!) {
    update_user_notifications_by_pk(
      pk_columns: { id: $id }
      _set: { acknowledged: $acknowledged }
    ) {
      acknowledged
      topic
      updated_at
      created_at
      id
    }
  }
`;
