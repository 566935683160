import { defineStyleConfig } from "@chakra-ui/react";

export const Button = defineStyleConfig({
  variants: {
    primary: {
      backgroundColor: "casBlue",
      color: "white",
      _hover: {
        backgroundColor: "#183DA1",
      },
    },
    pill: {
      borderRadius: "xl",
      borderWidth: "1px",
      borderColor: "currentcolor",
      fontSize: "md",
      fontWeight: "regular",
    },
  },
});
