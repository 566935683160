import { capitalize } from "lodash";
import { AccountHeaderAccountsFragment } from "../generated/graphql.ts";

const AccountName = ({
  account,
}: {
  account: AccountHeaderAccountsFragment;
}) => {
  return (
    <>
      {capitalize(account.name)} ({capitalize(account.institution?.name)})
    </>
  );
};

export default AccountName;
