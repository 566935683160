import { RecurrenceFrequency, TransactionType } from "../Transactions/types";

export type UpcomingTransactionsRes = {
  current_month_upcoming_expenses: UpcomingTransaction[];
};

export type UpcomingTransaction = {
  id: number;
  wording: string;
  value: number;
  formatted_value: string;
  date: string;
  frequency?: RecurrenceFrequency;
};

export type UpcomingTransactionFormData = {
  id?: number;
  wording: string;
  value: string;
  date: string;
  type: TransactionType;
  frequency?: RecurrenceFrequency;
  accountId?: string;
};

export const nullUpcomingTransaction: UpcomingTransactionFormData = {
  wording: "",
  value: "0",
  date: "",
  type: "order",
  frequency: "one_off",
};

export const transactionTypes: { label: string; value: TransactionType }[] = [
  { label: "card", value: "card" },
  { label: "order", value: "order" },
  { label: "transfer", value: "transfer" },
  { label: "check", value: "check" },
  { label: "withdrawal", value: "withdrawal" },
];
