import * as Sentry from "@sentry/react";
import { HttpClient } from "@sentry/integrations";
import config from "./config";

Sentry.init({
  dsn: config.sentryUrl,
  integrations: [
    // Add browser profiling integration to the list of integrations
    new Sentry.BrowserProfilingIntegration(),
    new Sentry.BrowserTracing(),
    new HttpClient(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/cashbah.hasura\.app\/v1\/graphql/,
  ],

  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  release: process.env.npm_package_version,
  enabled: process.env.NODE_ENV === "production",
  // This option is required for capturing headers and cookies.
  sendDefaultPii: true,
});
