import { gql, useMutation } from "@apollo/client";

const query = gql`
  mutation DeleteUpcomingTransaction($id: uuid!) {
    delete_upcoming_transactions_by_pk(id: $id) {
      id
    }
  }
`;
export const useDeleteUpcomingTransaction = () => {
  const [deleteUpcomingTransaction, { loading }] = useMutation(query);

  return { deleteUpcomingTransaction, loading };
};
