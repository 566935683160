import { Route, Routes } from "react-router-dom";
import { OnboardingLayout } from "./OnboardingLayout";
import { OnboardingProfileSetup } from "./OnboardingProfileSetup";
import { OnboardingBudgetSetup } from "./OnboardingBudgetSetup.tsx";
import { OnboardingLinkAccount } from "./OnboardingLinkAccount.tsx";
import { OnboardingChecklist } from "./OnboardingChecklist.tsx";
import { OnboardingVerifyEmail } from "./OnboardingVerifyEmail.tsx";

export const Onboarding = () => {
  return (
    <OnboardingLayout>
      <Routes>
        <Route path="/" element={<OnboardingChecklist />} />
        <Route path="setup-profile" element={<OnboardingProfileSetup />} />
        <Route path="setup-budget" element={<OnboardingBudgetSetup />} />
        <Route path="link-account" element={<OnboardingLinkAccount />} />
        <Route path="verify-email" element={<OnboardingVerifyEmail />} />
      </Routes>
    </OnboardingLayout>
  );
};
