import { Button, ButtonGroup } from "@chakra-ui/react";
import {
  startOfDay,
  addMonths,
  isSameMonth,
  startOfMonth,
  subMonths,
  addMinutes,
  isSameYear,
} from "date-fns";
import { ControllerRenderProps } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface MonthSliderProps extends ControllerRenderProps {
  defaultValue: Date;
}

const MonthSlider = ({ defaultValue, ...field }: MonthSliderProps) => {
  const { i18n } = useTranslation();
  const now = new Date(defaultValue);

  const formatMonth = (date: Date) => {
    return date.toLocaleString(i18n.language, { month: "long" });
  };

  const options = [
    {
      value: subMonths(startOfMonth(now), 1),
      label: formatMonth(subMonths(startOfMonth(now), 1)),
    },
    { value: now, label: formatMonth(now) },
    {
      value: addMonths(startOfMonth(now), 1),
      label: formatMonth(addMonths(startOfMonth(now), 1)),
    },
  ];

  const isActive = (date: Date) => {
    return isSameMonth(date, new Date(field.value));
  };

  const handleClick = (date: Date) => {
    if (
      isSameMonth(date, new Date(field.value)) &&
      isSameYear(date, new Date(field.value))
    ) {
      field.onChange(date.toISOString().split("T")[0]);
      return;
    }
    // Convert the date to the start of the day to eliminate the time part
    const startOfDayDate = startOfDay(date);

    // Get the timezone offset in minutes
    const timezoneOffset = date.getTimezoneOffset();

    // Adjust the date by the timezone offset to get UTC date
    const adjustedDate = addMinutes(startOfDayDate, -timezoneOffset);

    // Set the date using the ISO string of the adjusted date
    field.onChange(adjustedDate.toISOString().split("T")[0]);
  };

  return (
    <>
      <ButtonGroup variant="outline">
        {options.map((option, index) => (
          <Button
            key={index}
            colorScheme="blue"
            isActive={isActive(option.value)}
            onClick={() => handleClick(option.value)}
          >
            {option.label}
          </Button>
        ))}
      </ButtonGroup>
      <input type="hidden" {...field} />
    </>
  );
};
export default MonthSlider;
