import { Button, HStack, List, Text, VStack } from "@chakra-ui/react";
import { SectionHeading } from "../components/SectionHeading.tsx";
import { Link } from "react-router-dom";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { TransactionFragment } from "../generated/graphql.ts";
import { TransactionItem } from "../Transactions/TransactionList.tsx";

interface LastTransactionsProps {
  data: TransactionFragment[];
  inert?: boolean;
}

const LastTransactions = ({ data }: LastTransactionsProps) => {
  const { t } = useTranslation();

  return (
    <VStack spacing={4} alignItems="stretch" width="full">
      <HStack justifyContent="space-between" width="full">
        <SectionHeading>{t("transactions.last")}</SectionHeading>
      </HStack>
      <VStack
        layerStyle={"frame"}
        spacing={4}
        width="100%"
        alignItems={"stretch"}
      >
        <Button
          variant={"link"}
          size={"sm"}
          alignSelf={"flex-end"}
          as={Link}
          to={"/accounts"}
          leftIcon={<ArrowForwardIcon />}
        >
          {t("transactions.seeAll")}
        </Button>
        {!data?.length ? (
          <Text fontSize={"sm"} color={"casBlueGrey"}>
            {t("transactions.noData")}
          </Text>
        ) : (
          <List spacing={2}>
            {data?.map((transaction) => (
              <TransactionItem
                key={transaction.id}
                transaction={transaction}
                inert
              />
            ))}
          </List>
        )}
      </VStack>
    </VStack>
  );
};

export default LastTransactions;
