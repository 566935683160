import { first, uniqBy } from "lodash";
import { Transaction, TransactionsRes } from "./types";

export const mapTransaction = (transactionDto: any) => {
  const transaction = { ...transactionDto };

  if (transaction.tagged_transactions?.length) {
    transaction.tag = (first(transaction.tagged_transactions) as any).tag;
  }
  transaction.externalId = transaction.external_id;

  return transaction;
};

export const mapTransactions: (
  transactionsRes: TransactionsRes,
) => Transaction[] = (transactionsRes: any) => {
  if (!transactionsRes) return [];
  const mappedTransactions = transactionsRes.transactions.map(
    mapTransaction,
  ) as Transaction[];
  return uniqBy(mappedTransactions, "externalId");
};
