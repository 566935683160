export type BankAccountTypeName =
  | "article83"
  | "capitalisation"
  | "card"
  | "checking"
  | "crowdlending"
  | "deposit"
  | "joint"
  | "lifeinsurance"
  | "loan"
  | "madelin"
  | "market"
  | "pea"
  | "pee"
  | "per"
  | "perco"
  | "perp"
  | "real_estate"
  | "rsp"
  | "savings"
  | "unknown";

export const investmentAccounts: BankAccountTypeName[] = [
  "pea",
  "pee",
  "perco",
  "perp",
  "lifeinsurance",
  "capitalisation",
  "market",
  "madelin",
  "real_estate",
  "per",
  "article83",
];

export type Balance = {
  expenses: number;
  incomes: number;
  balance: number;
};

export type Connection = {
  id: number;
  state?: ConnectionState;
  errorMessage?: string;
  lastUpdate?: string;
  active: boolean;
  error?: ConnectionState;
};

export enum ConnectionState {
  SCARequired = "SCARequired",
  webauthRequired = "webauthRequired",
  additionalInformationNeeded = "additionalInformationNeeded",
  decoupled = "decoupled",
  validating = "validating",
  actionNeeded = "actionNeeded",
  passwordExpired = "passwordExpired",
  wrongpass = "wrongpass",
  rateLimiting = "rateLimiting",
  websiteUnavailable = "websiteUnavailable",
  bug = "bug",
  notSupported = "notSupported",
}

export type NormalizedAccountType =
  | "checking"
  | "savings"
  | "investment"
  | "liabilities"
  | "misc"
  | "real_estate";

export type Currency = {
  id: number;
  crypto: boolean;
  precision: number;
};

export const coreAccounts: NormalizedAccountType[] = [
  "checking",
  "savings",
  "investment",
  "misc",
  "liabilities",
  "real_estate",
];

export const accountTypeToCoreAccount: Record<
  NormalizedAccountType,
  NormalizedAccountType[]
> = {
  checking: ["checking"],
  savings: ["savings"],
  investment: ["investment"],
  liabilities: ["liabilities"],
  real_estate: ["real_estate"],
  misc: ["misc"],
};
