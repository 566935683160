import {
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  useBoolean,
} from "@chakra-ui/react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { FieldValues, UseControllerProps } from "react-hook-form";

const Password = <T extends FieldValues>(props: UseControllerProps<T>) => {
  const [flag, setFlag] = useBoolean();

  return (
    <InputGroup>
      <Input id="new-password" type={flag ? "text" : "password"} {...props} />
      <InputRightElement>
        <IconButton
          size={"sm"}
          aria-label={"auth.togglePasswordVisibility"}
          onClick={setFlag.toggle}
          icon={<Icon as={flag ? MdVisibility : MdVisibilityOff} />}
        />
      </InputRightElement>
    </InputGroup>
  );
};

export default Password;
