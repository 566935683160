import { useNetworkState } from "@uidotdev/usehooks";
import { ReactNode } from "react";
import { ErrorPage } from "../Error/ErrorPage.tsx";

export const NetworkMonitoring = ({ children }: { children: ReactNode }) => {
  const { online } = useNetworkState();

  if (!online) {
    return <ErrorPage errorCode={"NetworkError"} />;
  }

  return children;
};
