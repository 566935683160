import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icon";

export const IncomeIcon = (props: IconProps) => (
  <Icon viewBox="0 0 70 70" {...props}>
    <circle cx="35" cy="35" r="35" fill="#E7EDFF" />
    <path
      d="M37.6871 34.843C41.7793 34.843 45.1081 31.5137 45.1081 27.4215C45.1081 23.3293 41.7793 20 37.6871 20C33.5949 20 30.2656 23.3293 30.2656 27.4215C30.2656 31.5137 33.5949 34.843 37.6871 34.843ZM36.8082 23.0592V22.8576C36.8082 22.3719 37.2014 21.9787 37.6871 21.9787C38.1723 21.9787 38.566 22.3719 38.566 22.8576V23.0604C39.6119 23.2848 40.3989 24.2164 40.3989 25.3285C40.3989 25.8137 40.0051 26.2074 39.52 26.2074C39.0342 26.2074 38.641 25.8137 38.641 25.3285C38.641 25.0186 38.3885 24.766 38.0785 24.766H37.2905C36.9805 24.766 36.728 25.0186 36.728 25.3285C36.728 25.5061 36.8129 25.6748 36.9559 25.7803L37.6871 26.3217L39.4637 27.6365C40.0473 28.0684 40.3989 28.7592 40.4035 29.4858V29.494C40.4076 30.1139 40.1703 30.6987 39.7344 31.1399C39.411 31.4674 39.0067 31.6871 38.566 31.7797V31.9854C38.566 32.4711 38.1723 32.8643 37.6871 32.8643C37.2014 32.8643 36.8082 32.4711 36.8082 31.9854V31.7827C36.3793 31.6912 35.985 31.4803 35.6662 31.1651C35.225 30.7297 34.9801 30.1485 34.9766 29.5285C34.9736 29.0434 35.3645 28.6473 35.8496 28.6444H35.8555C36.3383 28.6444 36.7315 29.0346 36.7344 29.518C36.7356 29.8213 36.9796 30.077 37.2998 30.077C37.7855 30.0741 37.6046 30.0751 38.0867 30.0723C38.3987 30.0698 38.6481 29.8167 38.6457 29.5057V29.4975C38.6446 29.3217 38.5596 29.1541 38.4178 29.0492L37.6871 28.5084L35.91 27.1936C35.3217 26.7576 34.9701 26.0604 34.9701 25.3285C34.9701 24.2141 35.7594 23.2818 36.8082 23.0592Z"
      fill="#396AFF"
    />
    <path
      d="M25.6625 39.066C25.4372 38.6754 24.9368 38.5395 24.5438 38.7662L20.4095 41.1528C20.018 41.3793 19.8837 41.88 20.1097 42.272L24.3352 49.5905C24.5614 49.9819 25.0617 50.1163 25.4539 49.8903L29.5882 47.5032C29.9802 47.2771 30.114 46.7764 29.8879 46.3844L25.6625 39.066Z"
      fill="#396AFF"
    />
    <path
      d="M49.3515 37.6051C48.9536 37.0525 48.1837 36.9277 47.6318 37.3256C45.9571 38.532 42.7415 40.8488 42.486 41.0334C42.3683 41.1336 42.2446 41.2244 42.1157 41.3047C41.6101 41.6229 41.0235 41.7928 40.4112 41.7928H36.2012C35.7161 41.7928 35.3223 41.3996 35.3223 40.9139C35.3223 40.4276 35.7167 40.035 36.2012 40.035H40.7036C41.3651 40.035 41.8948 39.4842 41.8673 38.8215C41.8415 38.1928 41.3048 37.7053 40.6755 37.7053H37.2483C37.0163 37.4598 36.762 37.2348 36.4895 37.0338C35.5526 36.343 34.3948 35.9346 33.1415 35.9346C30.9131 35.9346 28.696 37.3385 27.7766 39.2123L31.3719 45.4391H38.1161C39.4403 45.4391 40.7446 45.1004 41.8925 44.4401C42.2903 44.2115 42.7163 43.9367 43.178 43.6051C45.1128 42.2153 49.0685 39.3277 49.0714 39.326C49.6245 38.9287 49.7499 38.1576 49.3515 37.6051Z"
      fill="#396AFF"
    />
  </Icon>
);
