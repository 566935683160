import { gql, useMutation } from "@apollo/client";

const query = gql`
  mutation UpdateUpcomingTransaction(
    $id: uuid!
    $date: date!
    $type: String!
    $value: numeric!
    $wording: String!
    $frequency: recurring_transaction_frequency
  ) {
    update_upcoming_transactions_by_pk(
      pk_columns: { id: $id }
      _set: {
        date: $date
        frequency: $frequency
        type: $type
        value: $value
        wording: $wording
      }
    ) {
      id
    }
  }
`;
export const useUpdateUpcomingTransaction = () => {
  const [updateUpcomingTransaction, { loading }] = useMutation(query);

  return { updateUpcomingTransaction, loading };
};
