import { gql, useMutation } from "@apollo/client";
import {
  SaveAccountMutation,
  SaveAccountMutationVariables,
} from "../generated/graphql";

export const useSaveAccount = () => {
  return useMutation<SaveAccountMutation, SaveAccountMutationVariables>(
    SAVE_ACCOUNT,
  );
};

const SAVE_ACCOUNT = gql`
  mutation SaveAccount($account: accounts_new_insert_input!) {
    insert_accounts_new_one(
      object: $account
      on_conflict: {
        constraint: accounts_new_pkey
        update_columns: [name, institution_id, type, balance, currency]
      }
    ) {
      id
    }
  }
`;
