import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";
import { CategoryBudget, toChartData, toChartOptions } from "./mappers";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { SectionHeading } from "../components/SectionHeading";
import {
  AccountsCountFragment,
  ExpensesByCategoryFragment,
} from "../generated/graphql";
import { gql } from "@apollo/client";
import { MdSettings } from "react-icons/md";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { WidgetPlaceholder } from "../components/WidgetPlaceholder.tsx";
import { useCurrency } from "../utils/useCurrency.ts";

ChartJS.register(...registerables);

interface SpendingDetailsProps {
  data: {
    accountsCount: AccountsCountFragment;
    expenses_by_category: ExpensesByCategoryFragment[];
  };
}

export const SpendingDetails = ({ data }: SpendingDetailsProps) => {
  const { t } = useTranslation();
  const { format, currency } = useCurrency();

  const accountsCount = data?.accountsCount?.aggregate?.count || 0;

  const expenses = useMemo(() => data?.expenses_by_category || [], [data]);

  const chartOptions = toChartOptions(expenses as CategoryBudget[], currency);
  const chartData = toChartData(expenses as CategoryBudget[]);

  if (!data.expenses_by_category?.length || !accountsCount) {
    return <WidgetPlaceholder widgetId={"spending_details"} />;
  }

  return (
    <VStack spacing={4} alignItems="stretch" width="100%">
      <HStack justifyContent="space-between" width="full">
        <SectionHeading>
          {t("spendingDetails.current", {
            month: new Date(),
            formatParams: { month: { month: "long" } },
          })}
        </SectionHeading>
      </HStack>
      <VStack layerStyle={"frame"} spacing={4} width="100%">
        <Button
          as={Link}
          to={"/settings?tab=budget"}
          variant={"link"}
          leftIcon={<MdSettings />}
          size={"sm"}
          alignSelf={"flex-end"}
        >
          {t("settings.manageBudget")}
        </Button>
        <Bar
          options={chartOptions}
          data={chartData}
          plugins={[ChartDataLabels]}
        />
        <VStack spacing={4} width="100%">
          {expenses.map((data) => {
            return (
              <HStack
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                key={data.category}
              >
                <HStack>
                  <Box
                    height="8px"
                    sx={{ aspectRatio: "1/1" }}
                    backgroundColor="casTeal"
                  ></Box>
                  <Text
                    fontSize={{ base: "sm", md: "md" }}
                    casing={"capitalize"}
                  >
                    {t(`category.${data.category}`)}
                  </Text>
                </HStack>
                <Text fontSize={{ base: "sm", md: "md" }}>
                  {format(data.value || 0)}
                </Text>
              </HStack>
            );
          })}
        </VStack>
      </VStack>
    </VStack>
  );
};

SpendingDetails.fragments = {
  expensesByCategory: gql`
    fragment ExpensesByCategory on user_month_expenses_breakdown {
      value: amount
      category
      target
    }
  `,
};
