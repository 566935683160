import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icon";

export const ShoppingIcon = (props: IconProps) => (
  <Icon viewBox="0 0 70 70" {...props}>
    <circle cx="35" cy="35" r="35" fill="#FFF5D9" />
    <path
      d="M45.9695 45.9125L44.3701 28.3531C44.327 27.8694 43.922 27.5 43.4364 27.5H40.6239V25.625C40.6239 24.1175 40.0389 22.7037 38.9795 21.6444C37.937 20.6 36.4858 20 34.9989 20C31.8976 20 29.3739 22.5237 29.3739 25.625V27.5H26.5614C26.0758 27.5 25.6708 27.8694 25.6276 28.3531L24.032 45.9106C23.9364 46.9587 24.2889 48.0031 24.9976 48.7794C25.7064 49.5556 26.7151 50 27.767 50H42.2326C43.2826 50 44.2914 49.5556 45.0001 48.7812C45.7108 48.005 46.0614 46.9587 45.9695 45.9125ZM38.7489 27.5H31.2489V25.625C31.2489 23.5569 32.9308 21.875 34.9989 21.875C35.9926 21.875 36.9601 22.2725 37.6539 22.9681C38.3608 23.675 38.7489 24.6181 38.7489 25.625V27.5Z"
      fill="#FFBB38"
    />
  </Icon>
);
