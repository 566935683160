import {
  Box,
  chakra,
  HStack,
  IconButton,
  Progress,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Currency } from "../components/Currency";
import { GoalProgress } from "../generated/graphql";
import { formatDistance } from "date-fns";
import { useTranslation } from "react-i18next";
import { toDateFnsLocale } from "../utils/utils.ts";
import { capitalize } from "lodash";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";

interface GoalItemProps {
  goal: GoalProgress;
}

export const GoalItem = ({ goal }: GoalItemProps) => {
  const { t, i18n } = useTranslation();

  if (!goal) {
    return null;
  }

  return (
    <HStack justifyContent={"flex-start"}>
      <VStack
        alignItems={"stretch"}
        width="full"
        flex={1}
        fontSize={{ base: "sm", md: "md" }}
      >
        <HStack
          as={"header"}
          justifyContent={"space-between"}
          color={"casDarkBlue"}
          fontWeight={500}
        >
          <Text> {goal.name}</Text>
          <Text>
            <Currency value={goal.target_amount} />
          </Text>
        </HStack>
        <HStack justifyContent={"space-between"}>
          <Box position={"relative"} flex={1}>
            <Progress
              value={goal.progress}
              colorScheme={goal.progress >= 100 ? "green" : "gray"}
              size={"sm"}
              h={6}
              borderRadius={"xl"}
              w={"full"}
            />
            <Text
              color={"blackAlpha.800"}
              position={"absolute"}
              left={4}
              bottom={0.5}
              fontSize={"sm"}
            >
              <Currency
                value={goal.contributions_total || 0}
                options={{ maximumFractionDigits: 0, minimumFractionDigits: 0 }}
              />
            </Text>
          </Box>
          <IconButton
            as={Link}
            to={`/goals/${goal.id}`}
            aria-label={t("goalsSnapshot.editGoal")}
            icon={<MdEdit />}
            variant={"ghost"}
            size={"sm"}
          />
        </HStack>
        <Text fontSize={{ base: "sm", md: "md" }} color={"casBlueGrey"}>
          {!!goal.target_date && (
            <chakra.span color={"casBlueGrey"}>
              &#127919;{" "}
              {capitalize(
                formatDistance(new Date(goal.target_date), new Date(), {
                  addSuffix: true,
                  locale: toDateFnsLocale(i18n.language),
                }),
              )}
            </chakra.span>
          )}
        </Text>
      </VStack>
    </HStack>
  );
};
