import {
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Center,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { capitalize } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { gql, useSuspenseQuery } from "@apollo/client";
import {
  Accounts_New_Insert_Input,
  ExchangeRateQuery,
  OfflineAccountDetailsQuery,
} from "../generated/graphql";
import { Controller, useForm } from "react-hook-form";
import { useSaveAccount } from "../Accounts/useAccounts";
import { accountTypes } from "../Accounts/accountTypes";
import { findCurrency } from "../Accounts/currencies";
import { SelectInstitutions } from "./SelectInstitutions";
import { useTranslation } from "react-i18next";
import bg from "../assets/header_bg_light.svg";
import { differenceInDays } from "date-fns";
import { parseNumber, toLocaleNumber } from "../utils/utils.ts";
import { useError } from "../Error/useError.ts";
import SelectCurrency from "../components/SelectCurrency.tsx";
import { useCurrency } from "../utils/useCurrency.ts";
import { useMemo } from "react";
import { useProfile } from "../User/useProfile.tsx";

type AccountFormData = {
  name: string;
  institutionId: string;
  type: string;
  balance: number;
  currency: string; //currency code
};

export const OfflineAccountDetails = () => {
  const { accountId } = useParams();
  const navigate = useNavigate();
  const { data: accountData } = useSuspenseQuery<OfflineAccountDetailsQuery>(
    AccountQuery,
    {
      variables: {
        id: accountId,
      },
      skip: !accountId,
    },
  );
  const { format } = useCurrency();
  const [saveAccount, { loading: savingAccount }] = useSaveAccount();
  const profile = useProfile();
  const toastr = useToast();
  const { handle } = useError();
  const { t, i18n } = useTranslation();

  const { register, handleSubmit, control, watch } = useForm<AccountFormData>({
    values: toFormData(accountData?.account),
  });

  const accountCurrency = watch("currency");

  const { data: exchangeRateData } = useSuspenseQuery<ExchangeRateQuery>(
    exchangeRateQuery,
    {
      variables: {
        curr: [accountCurrency, profile.preferredCurrency],
      },
      skip: !accountCurrency || !profile.preferredCurrency,
    },
  );

  const lastUpdateInDays = differenceInDays(
    new Date(),
    new Date(accountData?.account?.updated_at),
  );

  const balance = watch("balance");
  const exchangeRate = useMemo(() => {
    const accountRate = exchangeRateData?.exchange_rates_latest.find(
      (rate) => rate.currency === accountCurrency,
    )?.rate;
    const preferredRate = exchangeRateData?.exchange_rates_latest.find(
      (rate) => rate.currency === profile.preferredCurrency,
    )?.rate;
    return preferredRate / accountRate;
  }, [exchangeRateData, profile.preferredCurrency, accountCurrency]);

  const convertedBalance = useMemo(() => {
    const validBalance = balance || 0;
    const validExchangeRate = exchangeRate || 1;

    // Convert the balance to the preferred currency using the exchange rate
    const converted = validBalance * validExchangeRate;

    // Format the converted balance
    return format(converted);
  }, [exchangeRate, format, balance]);

  const onSubmit = async (formData: AccountFormData) => {
    await saveAccount({
      variables: {
        account: {
          ...(accountId ? { id: accountId } : {}),
          ...(formData.institutionId
            ? { institution_id: formData.institutionId }
            : {}),
          name: formData.name,
          type: formData.type,
          balance: +formData.balance,
          currency: formData.currency,
          state: "active", // offline account is always active
          balances: {
            data: [
              {
                balance: +formData.balance,
              },
            ],
          },
        } as Accounts_New_Insert_Input,
      },
      onCompleted: async () => {
        toastr({
          title: t("offlineAccountDetails.saveSuccess"),
          status: "success",
          isClosable: true,
        });
        navigate("/net-worth");
      },
      onError: handle,
    });
  };

  return (
    <Center
      w={"100vw"}
      h={"100vh"}
      overflow={"scroll"}
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <Container
        as={"main"}
        maxW={"container.sm"}
        boxShadow={"lg"}
        backgroundColor={"white"}
        p={8}
        borderRadius={"2xl"}
      >
        <VStack alignItems={"flex-start"} spacing={2} mb={4}>
          <Heading textStyle={"sectionTitle"} size={"md"}>
            {accountId
              ? t("offlineAccountDetails.editTitle", {
                  name: accountData?.account?.name,
                })
              : t("offlineAccountDetails.addTitle")}
          </Heading>
          {lastUpdateInDays > 30 && (
            <Alert status={"warning"}>
              <AlertIcon />
              {t("offlineAccountDetails.outOfDate")}{" "}
            </Alert>
          )}
          {!accountId ? (
            <Text textStyle={"sectionSubtitle"}>
              {t("offlineAccountDetails.description")}
            </Text>
          ) : null}
        </VStack>
        <VStack
          spacing={4}
          as={"form"}
          onSubmit={handleSubmit(onSubmit)}
          alignItems={"flex-start"}
        >
          <FormControl id="name">
            <FormLabel>{t("common.name")}</FormLabel>
            <Input type="text" {...register("name")} />
          </FormControl>
          <FormControl id="institution">
            <FormLabel>{t("common.institution")}</FormLabel>
            <Controller
              render={({ field }) => <SelectInstitutions {...field} />}
              name={"institutionId"}
              control={control}
            />
          </FormControl>
          <FormControl id="type">
            <FormLabel>{t("common.type")}</FormLabel>
            <Controller
              render={({ field }) => (
                <Select {...field}>
                  <option value={""}>
                    {t("offlineAccountDetails.selectAccountType")}
                  </option>
                  {accountTypes.map((accountType) => (
                    <option key={accountType.name} value={accountType.name}>
                      {capitalize(accountType.displayName)}
                    </option>
                  ))}
                </Select>
              )}
              name={"type"}
              control={control}
            />
          </FormControl>
          <FormControl id="currency">
            <FormLabel>{t("common.currency")}</FormLabel>
            <Controller
              render={({ field }) => <SelectCurrency {...field} />}
              name={"currency"}
              control={control}
            />
          </FormControl>
          <FormControl id="balance">
            <FormLabel>{t("common.balance")}</FormLabel>
            <InputGroup>
              <Controller
                render={({ field }) => (
                  <Input
                    {...field}
                    inputMode={"numeric"}
                    value={toLocaleNumber(field.value, i18n.language)}
                    onBlur={(e) => field.onChange(parseNumber(e.target.value))}
                  />
                )}
                name={"balance"}
                control={control}
              />
              <InputRightElement>
                <Text>{findCurrency(watch("currency") || "EUR")?.symbol}</Text>
              </InputRightElement>
            </InputGroup>
            {accountData?.account?.exchange_rate?.rate !== 1 && (
              <FormHelperText>
                {t("offlineAccountDetails.convertedBalance", {
                  balance: convertedBalance,
                })}
              </FormHelperText>
            )}
          </FormControl>
          <ButtonGroup>
            <Button
              role={"link"}
              colorScheme={"blue"}
              variant={"outline"}
              onClick={() => navigate("/net-worth")}
            >
              {t("common.cancel")}
            </Button>
            <Button colorScheme="blue" type="submit" isLoading={savingAccount}>
              {t("common.save")}
            </Button>
          </ButtonGroup>
        </VStack>
      </Container>
    </Center>
  );
};

const AccountQuery = gql`
  query OfflineAccountDetails($id: uuid!) {
    account: accounts_new_by_pk(id: $id) {
      id
      name
      balance
      currency
      institution_id
      type
      updated_at
      exchange_rate {
        rate
        currency
      }
    }
  }
`;

const exchangeRateQuery = gql`
  query ExchangeRate($curr: [String!]) {
    exchange_rates_latest(where: { currency: { _in: $curr } }) {
      rate
      currency
    }
  }
`;

const toFormData = (account?: OfflineAccountDetailsQuery["account"]) => {
  if (!account) {
    return {
      name: "",
      institutionId: "",
      type: "",
      balance: 0,
      currency: "EUR",
    };
  }
  return {
    name: account.name as string,
    institutionId: account.institution_id as string,
    type: account.type as string,
    balance: account.balance as number,
    currency: account.currency as string,
  };
};
