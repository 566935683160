import {
  Box,
  Center,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Editable,
  EditablePreview,
  EditableTextarea,
  FormControl,
  FormLabel,
  Heading,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { Currency } from "../components/Currency";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { TransactionDetailsQuery } from "../generated/graphql";
import { mapTransaction } from "./mappers";
import { Controller, useForm } from "react-hook-form";
import MonthSlider from "../components/MonthSlider";
import { useTranslation } from "react-i18next";
import { CategorySelector } from "../components/CategorySelector";
import { Contribution, GoalsContributions } from "./GoalsContributions.tsx";
import { utcToZonedTime } from "date-fns-tz";
import { useAuth } from "../Auth/useAuth.ts";

type FormData = {
  wording: string;
  application_date: Date;
  category: number | undefined | null;
};

export const TransactionDetails = () => {
  const { user } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t, i18n } = useTranslation();
  const { transactionId } = useParams();
  const navigate = useNavigate();
  const { data: transactionData } = useQuery<TransactionDetailsQuery>(
    TRANSACTION_DETAILS,
    {
      variables: {
        user_id: user?.uid,
        transaction_id: transactionId,
      },
      skip: !transactionId || !user,
    },
  );

  const tz = transactionData?.user?.profile?.timezone || "UTC";
  const initialDate = useMemo(() => {
    if (!transactionData?.transaction?.date) {
      return new Date();
    }
    return utcToZonedTime(
      new Date(transactionData.transaction?.application_date),
      tz,
    );
  }, [transactionData?.transaction?.application_date, tz]);

  const [saveTransaction, { loading: savingTransaction }] = useMutation(
    SAVE_TRANSACTION,
    {
      refetchQueries: ["TransactionDetails"],
    },
  );

  const [saveContribution, { loading: savingContribution }] =
    useMutation(SAVE_CONTRIBUTION);

  const [deleteContribution, { loading: deletingContribution }] =
    useMutation(DELETE_CONTRIBUTION);
  const transaction = mapTransaction(transactionData?.transaction);

  const { setValue, getValues, watch, control } = useForm<FormData>({
    ...(transactionData
      ? {
          values: {
            wording: transactionData.transaction?.wording || "",
            application_date: new Date(
              transactionData.transaction?.application_date || "",
            ),
            category: transactionData.transaction?.category_id,
          },
          mode: "onBlur",
        }
      : {}),
  });

  const category = watch("category");
  const contributions = useMemo(() => {
    return (
      transactionData?.transaction?.goals_contributions.map((contribution) => ({
        goal_id: contribution.goal.id,
        amount: contribution.amount,
      })) || []
    );
  }, [transactionData?.transaction?.goals_contributions]);

  const goalOptions = useMemo(() => {
    return transactionData?.goals.map((goal) => ({
      label: goal.name,
      value: goal.id,
    }));
  }, [transactionData?.goals]);

  const date = useMemo(() => {
    if (!transaction.date) {
      return "";
    }
    return new Intl.DateTimeFormat(i18n.language, {
      month: "long",
      day: "numeric",
      year: "numeric",
    }).format(new Date(transaction.date));
  }, [transaction]);

  useEffect(() => {
    if (transactionId) {
      onOpen();
    } else {
      onClose();
    }
  }, [transactionId, onOpen, onClose]);

  const handleClose = async () => {
    try {
      const formData = getValues();
      await saveTransaction({
        variables: {
          id: transactionId,
          wording: formData.wording,
          tag_id: formData.category ?? null,
          application_date: formData.application_date,
        },
      });

      onClose();
      navigate("../");
    } catch (err) {
      console.error(err);
    }
  };

  const handleSaveContribution = async (contribution: Contribution) => {
    try {
      await saveContribution({
        variables: {
          contribution: {
            goal_id: contribution.goal_id,
            amount: contribution.amount,
            date: new Date().toISOString(),
            transaction_id: transaction?.id,
            account_id: transaction?.account_id,
          },
        },
        refetchQueries: ["TransactionDetails"],
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteContribution = async (index: number) => {
    try {
      const contribution =
        transactionData?.transaction?.goals_contributions[index];

      await deleteContribution({
        variables: {
          id: contribution?.id as string,
        },
        refetchQueries: ["TransactionDetails"],
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={handleClose} size="sm">
      <DrawerOverlay />
      <DrawerContent>
        {savingContribution || savingTransaction ? (
          <Box position={"absolute"} top={3.5} right={5}>
            <Spinner size={"sm"} />
          </Box>
        ) : (
          <DrawerCloseButton />
        )}
        <DrawerHeader>
          <Center>
            <VStack spacing={2}>
              <Heading size="xl">
                <Currency value={transaction?.value || 0} />
              </Heading>
              <Text fontSize="sm">
                {t("transactionDetails.bookedDate", { date })}
              </Text>
            </VStack>
          </Center>
        </DrawerHeader>

        <DrawerBody>
          <VStack spacing={6} alignItems={"stretch"} as={"form"}>
            <FormControl>
              <FormLabel htmlFor="wording">
                {t("transactionDetails.wording")}
              </FormLabel>
              <Controller
                render={({ field }) => (
                  <Editable onChange={field.onChange} value={field.value}>
                    <EditablePreview />
                    <EditableTextarea />
                  </Editable>
                )}
                name={"wording"}
                control={control}
              />
            </FormControl>

            <VStack alignItems={"stretch"} spacing={4}>
              <Heading size={"md"} color={"casDarkBlue"}>
                {t("common.budget")}
              </Heading>

              <FormControl>
                <FormLabel htmlFor="category">
                  {t("transactionDetails.budgetCategory")}
                </FormLabel>
                <Controller
                  render={({ field }) => <CategorySelector {...field} />}
                  name={"category"}
                  control={control}
                />
              </FormControl>

              <FormControl>
                <FormLabel>
                  {t("transactionDetails.allocateToBudget")}
                </FormLabel>
                <Controller
                  render={({ field }) => (
                    <MonthSlider {...field} defaultValue={initialDate} />
                  )}
                  name={"application_date"}
                  control={control}
                />
              </FormControl>
              <Checkbox
                isChecked={category === 4}
                size={"md"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setValue("category", 4);
                  }
                }}
              >
                {t("transactionDetails.excludeFromBudget")}
              </Checkbox>
            </VStack>

            {(category === 3 || category === 4) && (
              <GoalsContributions
                contributions={contributions}
                options={goalOptions || []}
                saving={savingContribution}
                onSave={handleSaveContribution}
                onRemove={handleDeleteContribution}
                deleting={deletingContribution}
                max={Math.abs(transaction?.value || 0)}
              />
            )}
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const SAVE_TRANSACTION = gql`
  mutation SaveTransaction(
    $id: uuid!
    $wording: String!
    $tag_id: Int
    $application_date: date!
  ) {
    update_transactions_new_by_pk(
      pk_columns: { id: $id }
      _set: {
        wording: $wording
        category_id: $tag_id
        application_date: $application_date
      }
    ) {
      id
    }
  }
`;

export const TRANSACTION_DETAILS = gql`
  query TransactionDetails($user_id: String!, $transaction_id: uuid!) {
    user: users_by_pk(auth_provider_id: $user_id) {
      id
      profile {
        id
        timezone
      }
    }
    transaction: transactions_new_by_pk(id: $transaction_id) {
      id
      date
      wording
      value
      application_date
      category_id
      account_id
      goals_contributions {
        id
        amount
        goal {
          id
          name
        }
      }
    }
    goals(order_by: { name: asc }) {
      name
      id
    }
  }
`;

const SAVE_CONTRIBUTION = gql`
  mutation SaveContribution($contribution: goals_contributions_insert_input!) {
    insert_goals_contributions_one(object: $contribution) {
      id
    }
  }
`;

const DELETE_CONTRIBUTION = gql`
  mutation DeleteContribution($id: uuid!) {
    delete_goals_contributions_by_pk(id: $id) {
      id
    }
  }
`;
