import { useProfile } from "../User/useProfile.tsx";
import { useTranslation } from "react-i18next";

export const useCurrency = () => {
  const profile = useProfile();
  const { i18n } = useTranslation();

  return {
    currency: profile?.preferredCurrency,
    format: (value: number, options?: Intl.NumberFormatOptions) => {
      if (value === undefined || value === null || !profile) {
        return null;
      }

      return value.toLocaleString(i18n.language, {
        ...(options || {}),
        style: "currency",
        currency: profile.preferredCurrency,
      });
    },
    formatCompact: (value: number, options?: Intl.NumberFormatOptions) => {
      if (value === undefined || value === null || !profile) {
        return null;
      }

      return value.toLocaleString(i18n.language, {
        ...(options || {}),
        style: "currency",
        currency: profile.preferredCurrency,
        notation: "compact",
      });
    },
  };
};
