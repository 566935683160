import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icon";

export const NotificationIconActive = (props: IconProps) => {
  return (
    <Icon {...props}>
      <g clipPath="url(#clip0_1421_18)">
        <path
          d="M11.4419 25C9.28888 25 7.53564 23.2479 7.53564 21.0938C7.53564 20.6625 7.88564 20.3125 8.31689 20.3125C8.74815 20.3125 9.09814 20.6625 9.09814 21.0938C9.09814 22.3866 10.1502 23.4375 11.4419 23.4375C12.7336 23.4375 13.7856 22.3866 13.7856 21.0938C13.7856 20.6625 14.1356 20.3125 14.5669 20.3125C14.9981 20.3125 15.3481 20.6625 15.3481 21.0938C15.3481 23.2479 13.5951 25 11.4419 25V25Z"
          fill="currentColor"
        />
        <path
          d="M20.0357 21.875H2.84824C1.84307 21.875 1.02539 21.0573 1.02539 20.0521C1.02539 19.5187 1.25771 19.0136 1.66283 18.6666C1.68896 18.6438 1.717 18.623 1.74618 18.6041C3.2753 17.2697 4.15039 15.35 4.15039 13.3228V10.4166C4.15039 6.39591 7.42226 3.125 11.442 3.125C11.6087 3.125 11.7889 3.12805 11.9556 3.15628C12.3816 3.22704 12.6692 3.63026 12.5982 4.05521C12.5275 4.48017 12.117 4.7678 11.6993 4.69685C11.6159 4.6833 11.5244 4.6875 11.442 4.6875C8.28381 4.6875 5.71289 7.25727 5.71289 10.4166V13.3228C5.71289 15.8396 4.60968 18.2209 2.68898 19.8551C2.67334 19.8677 2.6598 19.8792 2.64301 19.8906C2.61498 19.9261 2.58789 19.9802 2.58789 20.0521C2.58789 20.1937 2.70672 20.3125 2.84824 20.3125H20.0357C20.1775 20.3125 20.2963 20.1937 20.2963 20.0521C20.2963 19.9791 20.2692 19.9261 20.24 19.8906C20.2244 19.8792 20.2108 19.8677 20.1952 19.8551C18.2734 18.2198 17.1713 15.8396 17.1713 13.3228V12.1876C17.1713 11.7563 17.5213 11.4063 17.9525 11.4063C18.3838 11.4063 18.7338 11.7563 18.7338 12.1876V13.3228C18.7338 15.3511 19.6098 17.2718 21.1411 18.6073C21.1691 18.626 21.1962 18.6459 21.2212 18.6678C21.6265 19.0136 21.8588 19.5187 21.8588 20.0521C21.8588 21.0573 21.0411 21.875 20.0357 21.875V21.875Z"
          fill="currentColor"
        />
        <path
          d="M25 4.5C25 7.53757 22.5376 10 19.5 10C16.4624 10 14 7.53757 14 4.5C14 1.46243 16.4624 -1 19.5 -1C22.5376 -1 25 1.46243 25 4.5Z"
          fill="currentColor"
          stroke="white"
          strokeWidth="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1421_18">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
