import { Tag } from "../types/tag";

export type TransactionsRes = {
  transactions: Transaction[];
};

export type Transaction = {
  id: number;
  date: string;
  wording: string;
  value: number;
  tag: Tag;
  externalId: string;
  group_id: number;
  recurrence: {
    frequency: RecurrenceFrequency;
    status: RecurrenceStatus;
  };
};

export type TransactionType =
  | "transfer"
  | "order"
  | "check"
  | "deposit"
  | "payback"
  | "withdrawal"
  | "loan_payment"
  | "bank"
  | "card"
  | "deferred_card"
  | "summary_card"
  | "unknown";

export type RecurrenceFrequency =
  | "daily"
  | "weekly"
  | "monthly"
  | "yearly"
  | "one_off";

export type RecurrenceStatus = "pending" | "accepted" | "declined";

export enum TransactionCategory {
  "needs" = 2,
  "wants" = 1,
  "goals" = 3,
  "off_budget" = 4,
  "income" = 5,
}
