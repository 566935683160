import { OnlineAccountForm } from "../AddAccount/OnlineAccountForm.tsx";
import { Link, useSearchParams } from "react-router-dom";
import { OnboardingLinkAccountSuccess } from "./OnboardingLinkAccountSuccess.tsx";
import { OnboardingLinkAccountError } from "./OnboardingLinkAccountError.tsx";
import { Box, CloseButton, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const OnboardingLinkAccount = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  if (searchParams.get("status") === "success") {
    return <OnboardingLinkAccountSuccess />;
  } else if (searchParams.get("status") === "error") {
    return <OnboardingLinkAccountError />;
  }

  return (
    <Box position={"relative"} layerStyle={"frame"}>
      <CloseButton
        as={Link}
        to={"/onboarding"}
        position={"absolute"}
        right={2}
        top={2}
      />
      <Box mb={8}>
        <Text textStyle={"sectionTitle"} lineHeight={2}>
          {t("onboardingLinkAccount.title")}
        </Text>
        <Text fontSize={"sm"} color={"casBlueGrey"}>
          {t("onboardingLinkAccount.description")}
        </Text>
      </Box>
      <OnlineAccountForm redirect={"onboarding/link-account"} />
    </Box>
  );
};
