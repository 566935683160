import { gql } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { Box, BoxProps, Heading, Stack, Text, VStack } from "@chakra-ui/react";
import Slider from "react-slick";
import { TipFragment } from "../generated/graphql.ts";
import { SectionHeading } from "../components/SectionHeading.tsx";
import { omit } from "lodash";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkGemoji from "remark-gemoji";

interface CoachingCornerProps {
  data: {
    financial_tips: TipFragment[];
  };
}

export const CoachingCorner = ({ data }: CoachingCornerProps) => {
  const { t } = useTranslation();
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  if (!data?.financial_tips.length) return null;

  return (
    <VStack spacing={4} alignItems="stretch" width="full">
      <Box>
        <SectionHeading mb={1.5}>{t("coachingCorner.title")}</SectionHeading>
        <Text fontSize="sm" color={"casBlueGrey"}>
          {t("coachingCorner.description")}
        </Text>
      </Box>
      <Box
        sx={{
          ".slick-track": {
            display: "flex",
            alignItems: "stretch",
            gap: { base: 0, md: 4 },
          },
          ".slick-slide": {
            float: "none",
          },
          ".slick-slide > div": {
            flex: 1,
            height: "full",
          },
          ".slick-arrow": {
            color: "casDarkBlue",
          },
        }}
      >
        <Slider {...settings}>
          {data.financial_tips.map((tip) => (
            <SliderItem key={tip.id} data={tip} h={"full"} />
          ))}
        </Slider>
      </Box>
    </VStack>
  );
};

interface SliderItemProps extends BoxProps {
  data: TipFragment;
}

const SliderItem = ({ data, ...props }: SliderItemProps) => {
  return (
    <Stack
      {...omit(props, "style")}
      overflow={"hidden"}
      spacing={4}
      borderRadius={"md"}
      bg={"white"}
      p={4}
    >
      <Heading size="sm" color={"casDarkBlue"}>
        {data.title}
      </Heading>
      <Box
        sx={{
          p: {
            fontSize: "sm",
            lineHeight: 1.5,
            mb: 1.5,
          },
          a: {
            color: "casBlue",
            textDecoration: "underline",
          },
          "a:hover": {
            color: "casBlue",
            textDecoration: "underline",
            opacity: 0.8,
          },
          h4: {
            fontSize: "md",
            color: "casDarkBlue",
            lineHeight: 1.75,
          },
        }}
      >
        <ReactMarkdown
          children={data.body as string}
          remarkPlugins={[remarkGfm, remarkGemoji]}
        />
      </Box>
    </Stack>
  );
};

CoachingCorner.fragments = {
  tip: gql`
    fragment Tip on financial_tips {
      body
      title
      id
    }
  `,
};
