import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./Auth/ProtectedRoute";
import { Dashboard } from "./Dashboard/Dashboard";
import { AccountTransactions } from "./Transactions/AccountTransactions";
import "./sentry";
import { Accounts } from "./AddAccount/Accounts";
import { Onboarding } from "./Onboarding/Onboarding";
import { TransactionDetails } from "./Transactions/TransactionDetails";
import { UncategorizedTransactions } from "./Transactions/UncategorizedTransactions";
import { Settings } from "./Settings/Settings";
import { GoalDetails } from "./Goals/GoalDetails";
import { OfflineAccountDetails } from "./AddAccount/OfflineAccountDetails";
import { OnlineAccountDetails } from "./AddAccount/OnlineAccountDetails";
import { AddAccountSelect } from "./AddAccount/AddAccountSelect.tsx";
import { Suspense } from "react";
import { SplashScreen } from "./SplashScreen/SplashScreen.tsx";
import Login from "./Auth/Login.tsx";
import Signup from "./Auth/Signup.tsx";
import ForgotPassword from "./Auth/ForgotPassword.tsx";
import ChangePassword from "./Auth/ChangePassword.tsx";
import PasswordResetEmailSent from "./Auth/PasswordResetEmailSent.tsx";
import { ErrorPage } from "./Error/ErrorPage.tsx";
import NetWorth from "./NetWorth/NetWorth.tsx";

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route
        path="/reset-password-email-sent"
        element={<PasswordResetEmailSent />}
      />
      <Route
        index
        element={
          <ProtectedRoute>
            <Suspense fallback={<SplashScreen />}>
              <Dashboard />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Suspense fallback={<SplashScreen />}>
              <Dashboard />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="/onboarding/*"
        element={
          <ProtectedRoute>
            <Onboarding />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/add-account"
        element={
          <ProtectedRoute>
            <Suspense fallback={<SplashScreen />}>
              <Accounts />
            </Suspense>
          </ProtectedRoute>
        }
      >
        <Route path={"select"} element={<AddAccountSelect />} />
        <Route path={"online"} element={<OnlineAccountDetails />} />
        <Route path={"manual"} element={<OfflineAccountDetails />} />
      </Route>
      <Route
        path="/goals/create"
        element={
          <ProtectedRoute>
            <Suspense fallback={<SplashScreen />}>
              <GoalDetails />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="/goals/:id"
        element={
          <ProtectedRoute>
            <Suspense fallback={<SplashScreen />}>
              <GoalDetails />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="/net-worth"
        element={
          <ProtectedRoute>
            <Suspense fallback={<SplashScreen />}>
              <NetWorth />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="/accounts"
        element={
          <ProtectedRoute>
            <Suspense fallback={<SplashScreen />}>
              <AccountTransactions />
            </Suspense>
          </ProtectedRoute>
        }
      >
        <Route
          path={"transaction/:transactionId"}
          element={
            <ProtectedRoute>
              <TransactionDetails />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="/offline-accounts/:accountId"
        element={
          <ProtectedRoute>
            <Suspense fallback={<SplashScreen />}>
              <OfflineAccountDetails />
            </Suspense>
          </ProtectedRoute>
        }
      />
      <Route
        path="/transactions/uncategorized"
        element={
          <ProtectedRoute>
            <Suspense fallback={<SplashScreen />}>
              <UncategorizedTransactions />
            </Suspense>
          </ProtectedRoute>
        }
      >
        <Route
          path={"transaction/:transactionId"}
          element={
            <ProtectedRoute>
              <TransactionDetails />
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
