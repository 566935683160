import { currencies } from "../Accounts/currencies.ts";
import { Select, SelectProps } from "@chakra-ui/react";

const SelectCurrency = (props: SelectProps) => {
  return (
    <Select {...props}>
      {currencies.map((currency) => (
        <option key={currency.id} value={currency.id}>
          {currency.name} ({currency.symbol})
        </option>
      ))}
    </Select>
  );
};

export default SelectCurrency;
