import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import {
  UpdateProfileMutation,
  UpdateProfileMutationVariables,
} from "../generated/graphql.ts";
import { useAuth } from "../Auth/useAuth.ts";

export const OnboardingLinkAccountSuccess = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [updateProfile, { loading: savingProfile }] = useMutation<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >(UPDATE_PROFILE, {
    refetchQueries: ["OnboardingChecklist"],
    onCompleted: () => {
      navigate("/dashboard");
    },
  });

  const handleComplete = async () => {
    await updateProfile({
      variables: {
        user_id: user?.uid as string,
        onboarding_status: "COMPLETE",
      },
      refetchQueries: ["OnboardingChecklist"],
    });
  };

  return (
    <Box textAlign="center" py={10} px={6}>
      <CheckCircleIcon boxSize={12} color={"casGreen"} />
      <Heading as="h2" size="lg" mt={6} mb={2} color={"casDarkBlue"}>
        {t("onboardingLinkAccountSuccess.title")}
      </Heading>
      <Text color={"casBlueGrey"}>
        {t("onboardingLinkAccountSuccess.description")}
      </Text>
      <Button
        mt={8}
        colorScheme="blue"
        onClick={handleComplete}
        isLoading={savingProfile}
      >
        {t("onboardingLinkAccountSuccess.cta")}
      </Button>
    </Box>
  );
};

const UPDATE_PROFILE = gql`
  mutation UpdateProfile(
    $user_id: String!
    $onboarding_status: onboarding_status!
  ) {
    update_profiles(
      where: { user_id: { _eq: $user_id } }
      _set: { onboarding_status: $onboarding_status }
    ) {
      returning {
        id
        onboarding_status
      }
    }
  }
`;
