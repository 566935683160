import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ReactNode } from "react";
import config from "../config";
import { SplashScreen } from "../SplashScreen/SplashScreen.tsx";
import { useAuth } from "../Auth/useAuth.ts";
import { FirebaseError } from "firebase/app";
import { useError } from "../Error/useError.ts";
import errorHandler from "../utils/errorHandler.ts";

const httpLink = createHttpLink({
  uri: config.gqlApi,
});

interface GQLProviderProps {
  children: ReactNode;
}

const CasApolloProvider = ({ children }: GQLProviderProps) => {
  const { user, loading } = useAuth();
  const { handleFBError } = useError();

  if (loading) {
    return <SplashScreen />;
  }

  const authLink = setContext(async (_, { headers }) => {
    try {
      const token = await user?.getIdToken();
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : "",
        },
      };
    } catch (error) {
      if (error instanceof FirebaseError) {
        handleFBError(error);
      }
      errorHandler.handle({ error });
      return headers;
    }
  });

  const client = new ApolloClient({
    link: from([authLink, httpLink]),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default CasApolloProvider;
