import { useTranslation } from "react-i18next";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Progress,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  FieldValues,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import { MdPercent } from "react-icons/md";
import { useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { GetEstimatedIncomeQuery } from "../generated/graphql.ts";
import { isEmpty, sum } from "lodash";
import { Currency } from "../components/Currency.tsx";
import { useAuth } from "../Auth/useAuth.ts";

export interface BudgetFormData {
  needsAllocation: number;
  wantsAllocation: number;
  goalsAllocation: number;
  totalAllocation: number;
}

export const OnboardingBudgetSetup = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { data } = useQuery<GetEstimatedIncomeQuery>(GetEstimatedIncome, {
    variables: {
      auth_provider_id: user?.uid,
    },
    skip: !user,
  });

  const methods = useForm<BudgetFormData>({
    defaultValues: {
      needsAllocation: 50,
      wantsAllocation: 30,
      goalsAllocation: 20,
    },
  });

  const watchedValues = methods.watch([
    "needsAllocation",
    "wantsAllocation",
    "goalsAllocation",
  ]);
  const totalAllocation = useMemo(() => sum(watchedValues), [watchedValues]);

  useEffect(() => {
    if (totalAllocation !== 100) {
      methods.setError("totalAllocation", {
        type: "totalAllocation",
        message: t("budgetSetup.totalAllocationError"),
      });
    } else {
      methods.clearErrors("totalAllocation");
    }
  }, [methods, totalAllocation, t]);

  return (
    <FormProvider {...methods}>
      <VStack
        alignItems={"stretch"}
        w={"container.md"}
        spacing={4}
        layerStyle={"frame"}
      >
        <VStack alignItems={"stretch"} spacing={2}>
          <Text textStyle="sectionTitle">{t("budgetSetup.title")}</Text>
          <Text textStyle="sectionSubtitle">{t("budgetSetup.subtitle")}</Text>
        </VStack>
        <Flex gap={4} direction={{ base: "column", md: "row" }}>
          <Box order={{ base: 2, md: 1 }}>
            <BudgetForm />
          </Box>
          <Box order={{ base: 1, md: 2 }}>
            <BudgetPreview
              estimatedMonthlyIncome={
                data?.users_by_pk?.profile?.estimated_monthly_income || 0
              }
            />
          </Box>
        </Flex>
      </VStack>
    </FormProvider>
  );
};

const BudgetForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useFormContext<BudgetFormData>();
  const allocationRegisterOptions = useMemo(
    () => ({
      required: true,
      valueAsNumber: true,
    }),
    [t],
  );

  const [saveBudget, { loading, error }] = useMutation(SaveBudget, {
    refetchQueries: ["OnboardingChecklist"],
  });

  const onSubmit = async (formData: FieldValues) => {
    await saveBudget({
      variables: {
        budget: {
          needs_allocation: formData.needsAllocation,
          wants_allocation: formData.wantsAllocation,
          goals_allocation: formData.goalsAllocation,
        },
        profile: {
          onboarding_status: "ACCOUNT_LINKING",
        },
      },
      refetchQueries: ["Profiles"],
    });
    navigate("../");
  };

  return (
    <VStack
      as={"form"}
      onSubmit={handleSubmit(onSubmit)}
      spacing={4}
      alignItems={"stretch"}
    >
      {!!error && (
        <Alert status={"error"}>
          <AlertIcon />
          <AlertDescription>
            {t("gqlError.error", { error: error.name })}
          </AlertDescription>
        </Alert>
      )}

      {!!errors.totalAllocation && (
        <Alert status={"error"}>
          <AlertIcon />
          <AlertDescription>
            {t("budgetSetup.totalAllocationError")}
          </AlertDescription>
        </Alert>
      )}

      <FormControl isRequired isInvalid={!!errors.needsAllocation}>
        <FormLabel>{t("category.needs")}</FormLabel>
        <InputGroup>
          <Input
            type="number"
            {...register("needsAllocation", allocationRegisterOptions)}
            placeholder="0"
          />
          <InputRightElement>
            <MdPercent />
          </InputRightElement>
        </InputGroup>
        <FormHelperText fontSize="sm">
          {t("budgetSetup.needsAllocationLabel")}
        </FormHelperText>
        <FormErrorMessage>{errors.needsAllocation?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isRequired isInvalid={!!errors.wantsAllocation}>
        <FormLabel>{t("category.wants")}</FormLabel>
        <InputGroup>
          <Input
            type="number"
            {...register("wantsAllocation", allocationRegisterOptions)}
            placeholder="0"
          />
          <InputRightElement>
            <MdPercent />
          </InputRightElement>
        </InputGroup>
        <FormHelperText fontSize="sm">
          {t("budgetSetup.wantsAllocationLabel")}
        </FormHelperText>
        <FormErrorMessage>{errors.wantsAllocation?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isRequired isInvalid={!!errors.goalsAllocation}>
        <FormLabel>{t("category.goals")}</FormLabel>
        <InputGroup>
          <Input
            type="number"
            {...register("goalsAllocation", allocationRegisterOptions)}
            placeholder="0"
          />
          <InputRightElement>
            <MdPercent />
          </InputRightElement>
        </InputGroup>
        <FormHelperText fontSize="sm">
          {t("budgetSetup.goalsAllocationLabel")}
        </FormHelperText>
        <FormErrorMessage>{errors.goalsAllocation?.message}</FormErrorMessage>
      </FormControl>
      <ButtonGroup>
        <Button
          as={Link}
          variant={"outline"}
          colorScheme={"blue"}
          to={"/onboarding"}
        >
          {t("common.cancel")}
        </Button>
        <Button
          variant={"primary"}
          type="submit"
          isLoading={loading}
          isDisabled={!isEmpty(errors)}
        >
          {t("budgetSetup.formSubmitButton")}
        </Button>
      </ButtonGroup>
    </VStack>
  );
};

interface BudgetPreviewProps {
  estimatedMonthlyIncome: number;
}

const BudgetPreview = ({ estimatedMonthlyIncome }: BudgetPreviewProps) => {
  const { t } = useTranslation();
  const methods = useFormContext();
  const formData = methods.watch();

  const needsAmount =
    Math.round(estimatedMonthlyIncome * (formData.needsAllocation / 100)) || 0;
  const wantsAmount =
    Math.round(estimatedMonthlyIncome * (formData.wantsAllocation / 100)) || 0;
  const goalsAmount =
    Math.round(estimatedMonthlyIncome * (formData.goalsAllocation / 100)) || 0;

  return (
    <Box p={4} bg={"gray.50"} borderRadius={"md"}>
      <Heading as="h3" size="sm" mb={1}>
        {t("budgetPreview.title")}
      </Heading>
      {!estimatedMonthlyIncome ? (
        <VStack alignItems={"flex-start"} maxW={"70ch"} spacing={2}>
          <Text color={"gray.500"} fontSize={"sm"}>
            {t("budgetPreview.noIncome")}
          </Text>
          <Button
            to={"../setup-profile"}
            as={Link}
            variant={"link"}
            size={"sm"}
          >
            {t("budgetPreview.updateProfile")}
          </Button>
        </VStack>
      ) : (
        <VStack alignItems={"stretch"} spacing={4}>
          <Text fontSize={"sm"} color={"gray.500"}>
            {t("budgetPreview.subtitle", { estimatedMonthlyIncome })}
          </Text>
          <VStack alignItems={"stretch"} width="full" flex={1}>
            <Heading fontSize={"sm"} color={"casDarkBlue"}>
              {t("category.needs")}
            </Heading>
            <HStack
              spacing={2}
              justifyContent={"stretch"}
              position={"relative"}
            >
              <Progress
                value={formData.needsAllocation}
                colorScheme={"purple"}
                size={"sm"}
                h={6}
                borderRadius={"xl"}
                flex={1}
                max={100}
                bg={"purple.100"}
              />
              <Text
                color={"whiteAlpha.900"}
                fontSize={"xs"}
                position={"absolute"}
                left={4}
                fontWeight={600}
              >
                <Currency
                  value={needsAmount || 0}
                  options={{
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }}
                />
              </Text>
            </HStack>
          </VStack>
          <VStack alignItems={"stretch"} width="full" flex={1}>
            <Heading fontSize={"sm"} color={"casDarkBlue"}>
              {t("category.wants")}
            </Heading>
            <HStack
              spacing={2}
              justifyContent={"stretch"}
              position={"relative"}
            >
              <Progress
                value={formData.wantsAllocation}
                colorScheme={"purple"}
                size={"sm"}
                h={6}
                borderRadius={"xl"}
                flex={1}
                max={100}
                bg={"purple.100"}
              />
              <Text
                color={"whiteAlpha.900"}
                fontSize={"xs"}
                position={"absolute"}
                left={4}
                fontWeight={600}
              >
                <Currency
                  value={wantsAmount || 0}
                  options={{
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }}
                />
              </Text>
            </HStack>
          </VStack>
          <VStack alignItems={"stretch"} width="full" flex={1}>
            <Heading fontSize={"sm"} color={"casDarkBlue"}>
              {t("category.goals")}
            </Heading>
            <HStack
              spacing={2}
              justifyContent={"stretch"}
              position={"relative"}
            >
              <Progress
                value={formData.goalsAllocation}
                colorScheme={"purple"}
                size={"sm"}
                h={6}
                borderRadius={"xl"}
                flex={1}
                max={100}
                bg={"purple.100"}
              />
              <Text
                color={"whiteAlpha.900"}
                fontSize={"xs"}
                position={"absolute"}
                left={4}
                fontWeight={600}
              >
                <Currency
                  value={goalsAmount || 0}
                  options={{
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }}
                />
              </Text>
            </HStack>
          </VStack>
        </VStack>
      )}
    </Box>
  );
};

const GetEstimatedIncome = gql`
  query GetEstimatedIncome($auth_provider_id: String!) {
    users_by_pk(auth_provider_id: $auth_provider_id) {
      profile {
        estimated_monthly_income
      }
    }
  }
`;

const SaveBudget = gql`
  mutation InsertUserBudget(
    $budget: budgets_insert_input!
    $profile: profiles_insert_input!
  ) {
    insert_budgets_one(
      object: $budget
      on_conflict: {
        constraint: budgets_user_id_key
        update_columns: [wants_allocation, needs_allocation, goals_allocation]
      }
    ) {
      needs_allocation
      goals_allocation
      wants_allocation
    }
    insert_profiles_one(
      object: $profile
      on_conflict: {
        update_columns: [onboarding_status]
        constraint: profiles_user_id_key
      }
    ) {
      onboarding_status
    }
  }
`;
