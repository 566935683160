import { useTranslation } from "react-i18next";
import { useAuth } from "./useAuth.ts";
import {
  Box,
  Button,
  Center,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { isEmpty } from "lodash";
import Password from "../components/Password.tsx";

type FormData = {
  newPassword: string;
  confirmPassword: string;
};

const ChangePassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { changePassword, verifyCode } = useAuth();
  const [searchParams] = useSearchParams();
  const {
    handleSubmit,
    getValues,
    control,
    formState: { errors, isValid },
  } = useForm<FormData>({
    mode: "onTouched",
  });

  const commonRules = {
    required: t("auth.passwordRequired"),
    minLength: {
      value: 6,
      message: t("auth.weakPassword"),
    },
  };

  const handleReset = async ({ newPassword }: FormData) => {
    const code = searchParams.get("oobCode");

    if (!code) {
      console.error("Invalid link");
      return;
    }

    try {
      await verifyCode(code);
    } catch (error) {
      console.error(error);
      return;
    }

    try {
      await changePassword(code, newPassword);
      navigate("/login");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Center w={"100vw"} h={"100vh"} bg="casBackground" overflow={"hidden"}>
      <Box layerStyle={"frame"} p={8} position={"relative"} mt={8} w={"md"}>
        <CloseButton
          onClick={() => navigate("/login")}
          position={"absolute"}
          right={4}
          top={4}
        />
        <form onSubmit={handleSubmit(handleReset)}>
          <VStack
            spacing={4}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
          >
            <Text textStyle={"sectionTitle"}>{t("auth.changePassword")}</Text>
            <FormControl isRequired isInvalid={!isEmpty(errors["newPassword"])}>
              <FormLabel htmlFor="newPassword">
                {t("auth.newPassword")}
              </FormLabel>
              <Controller
                control={control}
                render={({ field }) => <Password {...field} />}
                name={"newPassword"}
                rules={{
                  ...commonRules,
                }}
              />
              <ErrorMessage
                errors={errors}
                name="newPassword"
                render={({ message }) =>
                  message && <FormErrorMessage>{message}</FormErrorMessage>
                }
              />
            </FormControl>
            <FormControl
              isRequired
              isInvalid={!isEmpty(errors["confirmPassword"])}
            >
              <FormLabel htmlFor="confirmPassword">
                {t("auth.confirmPassword")}
              </FormLabel>
              <Controller
                control={control}
                render={({ field }) => <Password {...field} />}
                rules={{
                  ...commonRules,
                  validate: (value) =>
                    value === getValues("newPassword") ||
                    t("auth.passwordMismatch"),
                }}
                name={"confirmPassword"}
              />
              <ErrorMessage
                errors={errors}
                name="confirmPassword"
                render={({ message }) =>
                  message && <FormErrorMessage>{message}</FormErrorMessage>
                }
              />
            </FormControl>
            <Button
              type="submit"
              w={"full"}
              variant={"primary"}
              isDisabled={!isValid}
            >
              {t("auth.changePasswordButton")}
            </Button>
          </VStack>
        </form>
      </Box>
    </Center>
  );
};

export default ChangePassword;
