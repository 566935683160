import { Outlet } from "react-router-dom";
import { Page } from "../Layout/Page/Page";
import { Box, Grid, GridItem, VStack } from "@chakra-ui/react";

import { TransactionList } from "./TransactionList";
import { GoBack } from "../components/GoBack.tsx";
import { gql, useSuspenseQuery } from "@apollo/client";
import {
  TransactionFragment,
  UncategorizedTransactionsQuery,
} from "../generated/graphql";
import { SectionHeading } from "../components/SectionHeading";
import { useTranslation } from "react-i18next";

export const UncategorizedTransactions = () => {
  const { t } = useTranslation();
  const { data } = useSuspenseQuery<UncategorizedTransactionsQuery>(
    UNCATEGORIZED_TRANSACTIONS_QUERY,
  );

  return (
    <Page>
      <Box py={6}>
        <GoBack to={"/"} />
      </Box>
      <Grid gap={4} templateColumns="repeat(6, 1fr)">
        <GridItem colSpan={{ base: 6, md: 4 }}>
          <VStack spacing={4} alignItems="stretch" width="100%">
            <SectionHeading>
              {t("uncategorizedTransactions.title")}
            </SectionHeading>
            <Box bg="white" p={6} borderRadius="xl" width="100%">
              <TransactionList
                transactions={
                  (data?.transactions || []) as TransactionFragment[]
                }
              />
              <Outlet />
            </Box>
            <Outlet />
          </VStack>
        </GridItem>
      </Grid>
    </Page>
  );
};

const UNCATEGORIZED_TRANSACTIONS_QUERY = gql`
  query UncategorizedTransactions {
    transactions: transactions_new(
      where: {
        category_id: { _is_null: true }
        _and: { value: { _lt: "0" } }
        deleted_at: { _is_null: true }
        account: { type: { _eq: "checking" } }
        date: { _lte: "now()" }
      }
      order_by: { date: desc }
    ) {
      ...Transaction
    }
  }
  ${TransactionList.fragments.entry}
`;
