import {
  chakra,
  Circle,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Text,
} from "@chakra-ui/react";
import { MdWarning } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { UserOutstandingTasksFragment } from "../../generated/graphql.ts";

interface UpdateAccountsTaskProps {
  onClick: (id: string) => void;
  data: UserOutstandingTasksFragment;
}

const UpdateAccountsTask = ({
  onClick,
  data: {
    id,
    metadata: { count },
  },
}: UpdateAccountsTaskProps) => {
  const { t } = useTranslation();

  const handleClick = () => {
    onClick(id);
  };

  if (!count) {
    return null;
  }

  return (
    <LinkBox w={"full"}>
      <HStack layerStyle={"hoverableListItem"}>
        <Circle bg={"orange.400"} size={8} centerContent={true}>
          <Icon as={MdWarning} color={"white"} boxSize={4} />
        </Circle>

        <LinkOverlay onClick={handleClick}>
          <Text color={"casDarkBlue"} fontWeight={700}>
            <chakra.span color={"orange.400"}>{count}</chakra.span>{" "}
            {t("outstandingTasks.accountsToUpdate", { count })}
          </Text>
        </LinkOverlay>
      </HStack>
    </LinkBox>
  );
};

export default UpdateAccountsTask;
