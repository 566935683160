import { Link } from "react-router-dom";
import { Page } from "../Layout/Page/Page.tsx";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Heading,
  HStack,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import { SectionHeading } from "../components/SectionHeading.tsx";
import { GoBack } from "../components/GoBack.tsx";
import { useTranslation } from "react-i18next";

export const AddAccountSelect = () => {
  const { t } = useTranslation();
  return (
    <Page title="Add new account">
      <Container
        mt={{ base: 2, md: 18 }}
        maxW={"container.lg"}
        bg={"casBackground"}
      >
        <Box py={6}>
          <GoBack to={"/settings?tab=accounts"} />
        </Box>
        <Box mb={8}>
          <SectionHeading>{t("addAssets.title")}</SectionHeading>
        </Box>
        <Stack
          spacing={4}
          width={"full"}
          alignItems={"stretch"}
          direction={{ base: "column", md: "row" }}
        >
          <Card color={"casDarkBlue"} flex={1}>
            <CardBody>
              <HStack spacing={4} justifyContent={"space-between"} mb={4}>
                <Heading size="md">
                  {t("addAssets.automaticSync.title")}
                </Heading>
                <Tag colorScheme={"green"}>{t("common.secure")}</Tag>
              </HStack>
              <Text fontSize={"md"}>
                {t("addAssets.automaticSync.description")}
              </Text>
              <Text fontSize={"sm"} color={"casBlueGrey"}>
                {t("addAssets.automaticSync.helper")}
              </Text>
            </CardBody>
            <CardFooter>
              <Button
                variant="solid"
                colorScheme="blue"
                as={Link}
                to={"../online"}
              >
                {t("addAssets.automaticSync.cta")}
              </Button>
            </CardFooter>
          </Card>

          <Card color={"casDarkBlue"} flex={1}>
            <CardBody>
              <Heading size="md" mb={4}>
                {t("addAssets.manual.title")}
              </Heading>
              <Text fontSize={"md"}>{t("addAssets.manual.description")}</Text>
              <Text fontSize={"sm"} color={"casBlueGrey"}>
                {t("addAssets.manual.helper")}
              </Text>
            </CardBody>
            <CardFooter>
              <Button
                variant="outline"
                colorScheme="blue"
                as={Link}
                to={"../manual"}
              >
                {t("addAssets.manual.cta")}
              </Button>
            </CardFooter>
          </Card>
        </Stack>
      </Container>
    </Page>
  );
};
