import { extendTheme } from "@chakra-ui/react";
import { Button } from "./Button";
import { Input } from "./Input";
import { FormLabel } from "./FormLabel";
import { Select } from "./Select.ts";

export const theme = extendTheme({
  fonts: {
    body: "inter, sans-serif",
    heading: "inter, sans-serif",
  },
  textStyles: {
    sectionTitle: {
      fontSize: { base: "lg", md: "xl" },
      fontWeight: "bold",
      color: "casDarkBlue",
    },
    sectionSubtitle: {
      fontSize: { base: "sm", md: "md" },
      fontWeight: "normal",
      color: "casBlueGrey",
    },
    noData: {
      fontSize: { base: "md", md: "lg" },
      fontWeight: "medium",
      color: "casBlueGrey",
    },
  },
  layerStyles: {
    frame: {
      bg: "white",
      borderRadius: "xl",
      padding: 4,
    },
    hoverableListItem: {
      bg: "white",
      padding: 2,
      w: "full",
      spacing: 4,
      transition: "all 0.2s ease-in-out",
      _hover: {
        bg: "blue.100",
      },
    },
  },
  colors: {
    blue: {
      900: "#1A365D",
      700: "#183DA1",
      600: "#1A3F9A",
      500: "#2D60FF",
      100: "#E7EDFF",
    },
    casBackground: "#F5F7FA",
    casOrange: "#FEAA09",
    casDarkBlue: "#343C6A",
    casDarkTeal: "#052121",
    casBlue: "#2D60FF",
    casDarkerBlue: "#183DA1",
    casRed: "#FE5C73",
    casGreen: "#41D4A8",
    casTeal: "#16DBCC",
    casDarkerTeal: "#1EC6B8",
    casBlueGrey: "#718EBF",
    casLightGrey: "#EDF0F7",
    border: "#E6EFF5",
  },
  borderRadius: {
    none: "0",
    sm: "0.125rem",
    base: "0.25rem",
    md: "0.375rem",
    lg: "0.5rem",
    xl: "0.75rem",
    "2xl": "1rem",
    "3xl": "1.5rem",
    full: "9999px",
  },
  components: {
    Button,
    Input,
    FormLabel,
    Select,
  },
});
