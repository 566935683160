import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  IconButton,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { CategorizeTransactionsTask } from "./CategorizeTransactionsTask/CategorizeTransactionsTask.tsx";
import { gql, useQuery } from "@apollo/client";
import {
  UserOutstandingTasksFragment,
  UserOutstandingTasksQuery,
} from "../generated/graphql";
import FixConnectionsErrorsTask from "./FixConnectionsErrorsTask/FixConnectionsErrorsTask.tsx";
import { useTranslation } from "react-i18next";
import { PossibleTasks } from "./types.ts";
import UpdateAccountsTask from "./UpdateAccountsTask/UpdateAccountsTask.tsx";
import { useRef } from "react";
import { NotificationIcon } from "../components/icons/NotificationIcon.tsx";
import { NotificationIconActive } from "../components/icons/NotificationIconActive.tsx";
import { useMarkAsRead } from "./MarkAsRead/useMarkAsRead.ts";
import { profileFragments } from "../User/useProfile.tsx";

export const OutstandingTasks = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);
  const { data, loading } = useQuery<UserOutstandingTasksQuery>(QUERY);

  const { markAsRead } = useMarkAsRead();

  const profile = data?.profiles[0];
  const onboardingComplete = profile?.onboarding_status === "REMOVED";
  const outstandingTasks = (data?.user_notifications || [])
    ?.filter((task) => task?.metadata?.count > 0 || task?.metadata?.account_id)
    .reduce(
      (acc, task) => {
        const name = task?.topic as PossibleTasks;
        acc[name] = task;
        return acc;
      },
      {} as Record<PossibleTasks, UserOutstandingTasksFragment>,
    );

  if (!onboardingComplete) {
    return null;
  }

  return (
    <>
      <IconButton
        isRound={true}
        icon={
          data?.user_notifications?.length ? (
            <NotificationIconActive />
          ) : (
            <NotificationIcon />
          )
        }
        aria-label={t("outstandingTasks.showOutstandingTasks")}
        bg={"casBackground"}
        color={data?.user_notifications?.length ? "casRed" : "casBlueGrey"}
        onClick={onOpen}
        isDisabled={loading}
      />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color={"casDarkBlue"}>
            {t("outstandingTasks.self")}
          </DrawerHeader>

          <DrawerBody>
            {data?.user_notifications?.length ? (
              <VStack alignItems={"stretch"} spacing={2}>
                {outstandingTasks["connection_in_error_state"] && (
                  <FixConnectionsErrorsTask
                    onClick={markAsRead}
                    data={outstandingTasks["connection_in_error_state"]}
                  />
                )}
                {outstandingTasks["uncategorized_transactions"] && (
                  <CategorizeTransactionsTask
                    onClick={markAsRead}
                    data={outstandingTasks["uncategorized_transactions"]}
                  />
                )}
                {outstandingTasks["accounts_not_updated_in_30_days"] && (
                  <UpdateAccountsTask
                    onClick={markAsRead}
                    data={outstandingTasks["accounts_not_updated_in_30_days"]}
                  />
                )}
              </VStack>
            ) : (
              <Heading color="casBlueGrey" fontSize="md">
                {t("outstandingTasks.noData")}
              </Heading>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

OutstandingTasks.fragments = {
  notifications: gql`
    fragment UserOutstandingTasks on user_notifications {
      id
      topic
      metadata
      created_at
      acknowledged
    }
  `,
};

export const QUERY = gql`
  query UserOutstandingTasks {
    profiles {
      ...Profile
    }
    user_notifications(
      where: {
        acknowledged: { _eq: false }
        topic: {
          _in: [
            "connection_in_error_state"
            "uncategorized_transactions"
            "accounts_not_updated_in_30_days"
          ]
        }
      }
      order_by: { created_at: asc }
    ) {
      ...UserOutstandingTasks
    }
  }
  ${OutstandingTasks.fragments.notifications}
  ${profileFragments}
`;
