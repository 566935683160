import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  Box,
  Button,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { theme } from "../theme/theme.ts";
import { Currency } from "../components/Currency.tsx";
import { capitalize } from "lodash";
import { ErrorMessage } from "@hookform/error-message";
import { MdEuro } from "react-icons/md";
import { useCurrency } from "../utils/useCurrency.ts";

export type Contribution = {
  goal_id: string;
  amount: number;
};

interface ContributionsFormProps {
  contributions: Contribution[];
  options: {
    label: string;
    value: string;
  }[];
  saving?: boolean;
  deleting?: boolean;
  max: number;
  onSave: (contribution: Contribution) => void;
  onRemove: (index: number) => void;
}

export const GoalsContributions = ({
  contributions,
  options,
  onSave,
  onRemove,
  saving,
  deleting,
  max,
}: ContributionsFormProps) => {
  const { t } = useTranslation();
  const { format } = useCurrency();
  const [deletingIndex, setDeletingIndex] = useState<number>(-1);
  const {
    register,
    control,
    getValues,
    formState: { errors, isValid },
  } = useForm<{
    contributions: Contribution[];
  }>({
    values: {
      contributions,
    },
    mode: "onBlur",
  });

  const contributionsFields = useFieldArray({
    control,
    name: "contributions",
    rules: {
      validate: (value) => {
        return value.length > 0;
      },
    },
  });

  const contributionMaxValue = useMemo(() => {
    const contributions = getValues("contributions") || [];
    const contributionTotal = contributions.reduce(
      (acc, field) => acc + field.amount || 0,
      0,
    );

    // Calculate the remaining balance after existing contributions
    const remainingBalance = max - contributionTotal;

    // The max value for a new contribution is the remaining balance, but not more than the transaction value.
    return Math.max(0, remainingBalance);
  }, [contributions, max]);

  const handleSave = (index: number) => {
    onSave(getValues("contributions")[index]);
  };

  const handleRemove = (index: number) => {
    setDeletingIndex(index);
    onRemove(index);
  };

  return (
    <VStack alignItems={"stretch"}>
      <HStack justifyContent={"space-between"}>
        <Heading size={"md"} color={"casDarkBlue"}>
          {t("transactionDetails.contributions")}
        </Heading>
        <IconButton
          variant={"ghost"}
          aria-label={t("transactionDetails.addContribution")}
          icon={<AddIcon />}
          onClick={() => contributionsFields.append({ goal_id: "", amount: 0 })}
        />
      </HStack>
      {!contributionsFields.fields.length && (
        <Text color={"casBlueGrey"}>
          {t("transactionDetails.noContributions")}
        </Text>
      )}
      <List spacing={4}>
        {contributionsFields.fields.map((field, index) => {
          return field.goal_id ? (
            <ListItem key={field.id}>
              <HStack
                justifyContent={"space-between"}
                _last={{
                  borderBottom: `1px solid ${theme.colors.border}`,
                  pb: 2,
                }}
              >
                <Box>
                  <Heading size={"sm"} color={"casDarkBlue"}>
                    {options.find((o) => o.value === field.goal_id)?.label}
                  </Heading>
                  <Text color={"casBlueGrey"}>
                    <Currency value={field.amount} />
                  </Text>
                </Box>
                <IconButton
                  variant={"ghost"}
                  colorScheme={"gray"}
                  aria-label={t("transactionDetails.removeContribution")}
                  icon={<DeleteIcon />}
                  onClick={() => handleRemove(index)}
                  isLoading={deleting && deletingIndex === index}
                />
              </HStack>
            </ListItem>
          ) : (
            <ListItem key={field.id} bg={"gray.50"} p={4} borderRadius={"md"}>
              <HStack justifyContent={"space-between"} mb={2}>
                <Heading size={"sm"} color={"casDarkBlue"}>
                  {t("transactionDetails.newContribution")}
                </Heading>
                <CloseButton
                  onClick={() => contributionsFields.remove(index)}
                />
              </HStack>
              <VStack spacing={4}>
                <FormControl
                  isRequired
                  isInvalid={
                    errors.contributions &&
                    !!errors.contributions[index]?.goal_id
                  }
                >
                  <FormLabel
                    size={"md"}
                    color={"casDarkBlue"}
                    as={"legend"}
                    htmlFor={"goal"}
                  >
                    {t("transactionDetails.goal")}
                  </FormLabel>
                  <Select
                    bg={"white"}
                    id={"goal"}
                    placeholder={t("transactionDetails.selectGoal")}
                    {...register(`contributions.${index}.goal_id` as const, {
                      required: t("common.fieldRequired"),
                    })}
                  >
                    {options?.map((goal) => (
                      <option key={goal.value} value={goal.value}>
                        {capitalize(goal.label)}
                      </option>
                    ))}
                  </Select>
                  <ErrorMessage
                    name={`contributions.${index}.goal_id`}
                    as={FormErrorMessage}
                    errors={errors}
                  />
                </FormControl>
                <FormControl
                  isInvalid={
                    errors.contributions &&
                    !!errors.contributions[index]?.amount
                  }
                  isRequired
                >
                  <FormLabel htmlFor="amount">
                    {t("transactionDetails.amount")}
                  </FormLabel>
                  <InputGroup>
                    <Input
                      bg={"white"}
                      id={"amount"}
                      type="number"
                      {...register(`contributions.${index}.amount` as const, {
                        valueAsNumber: true,
                        required: t("common.fieldRequired"),
                        max: {
                          value: contributionMaxValue || 0,
                          message: t("transactionDetails.maxAmountError", {
                            value: format(contributionMaxValue || 0),
                          }),
                        },
                      })}
                    />
                    <InputRightElement aria-label={t("transaction.currency")}>
                      <MdEuro />
                    </InputRightElement>
                  </InputGroup>
                  <ErrorMessage
                    errors={errors}
                    name={`contributions.${index}.amount`}
                    as={FormErrorMessage}
                  />
                </FormControl>
                <Button
                  onClick={() => handleSave(index)}
                  isDisabled={!isValid}
                  isLoading={saving}
                  alignSelf={"flex-end"}
                  size={"sm"}
                >
                  {t("common.save")}
                </Button>
              </VStack>
            </ListItem>
          );
        })}
      </List>
    </VStack>
  );
};
