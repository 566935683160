import { Button, Heading, Text, VStack } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CircleIcon } from "../components/CircleIcon.tsx";

export const OnboardingLinkAccountError = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleComplete = () => {
    navigate("/dashboard");
  };

  return (
    <VStack py={10} px={6} alignItems={"center"}>
      <CircleIcon
        backgroundColor={"red.500"}
        color={"white"}
        boxSize={12}
        icon={<CloseIcon />}
      />
      <Heading
        as="h2"
        size="lg"
        mt={6}
        mb={2}
        color={"casDarkBlue"}
        textAlign={"center"}
      >
        {t("onboardingLinkAccountError.title")}
      </Heading>
      <Text color={"casBlueGrey"}>
        {t("onboardingLinkAccountError.description")}
      </Text>
      <Button mt={8} colorScheme="blue" onClick={handleComplete}>
        {t("onboardingLinkAccountError.cta")}
      </Button>
    </VStack>
  );
};
