import { Center, Container } from "@chakra-ui/react";
import { ReactNode } from "react";

interface FormLayoutProp {
  children: ReactNode;
}

export const FormLayout = ({ children }: FormLayoutProp) => {
  return (
    <Center
      w={"100vw"}
      h={"100vh"}
      overflow={"scroll"}
      sx={{
        backgroundImage: "url(/header_bg_light.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <Container
        as={"main"}
        w={"max"}
        boxShadow={"lg"}
        backgroundColor={"white"}
        p={8}
        borderRadius={"2xl"}
      >
        {children}
      </Container>
    </Center>
  );
};
