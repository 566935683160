import { useMemo } from "react";
import { gql } from "@apollo/client";
import { WidgetPlaceholder } from "../../components/WidgetPlaceholder.tsx";
import NetWorthOverviewView from "./NetWorthOverviewView.tsx";
import {
  NetWorthBalanceFragment,
  NetWorthOverviewFragment,
} from "../../generated/graphql.ts";

interface NetWorthOverviewProps {
  data: {
    assets: NetWorthOverviewFragment[];
    liabilities: NetWorthOverviewFragment[];
    assets_balance: NetWorthBalanceFragment;
    liabilities_balance: NetWorthBalanceFragment;
  };
}

export const NetWorthOverview = ({ data }: NetWorthOverviewProps) => {
  const assets = useMemo(() => data?.assets || [], [data]);
  const liabilities = useMemo(() => data?.liabilities || [], [data]);
  const assetsBalance = useMemo(
    () => data?.assets_balance.aggregate?.sum?.balance || 0,
    [data],
  );
  const liabilitiesBalance = useMemo(
    () => data?.liabilities_balance.aggregate?.sum?.balance || 0,
    [data],
  );

  if (!assets.length && !liabilities.length) {
    return <WidgetPlaceholder widgetId={"net_worth_overview"} />;
  }

  return (
    <NetWorthOverviewView
      {...{ assetsBalance, liabilitiesBalance, assets, liabilities }}
    />
  );
};

NetWorthOverview.fragments = {
  netWorthOverview: gql`
    fragment NetWorthOverview on accounts_new {
      id
      name
      type
      currency
      balance: converted_balance
      updated_at
      connection_id
      institution {
        id
        name
      }
    }
  `,
  netWorthBalance: gql`
    fragment NetWorthBalance on accounts_new_aggregate {
      aggregate {
        sum {
          balance: converted_balance
        }
      }
    }
  `,
};
