import {
  Avatar,
  Box,
  ButtonGroup,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import logo from "../../assets/logo.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { OutstandingTasks } from "../../OutstandingTasks/OutstandingTasks.tsx";
import { useAuth } from "../../Auth/useAuth.ts";

export const Header = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box
      bg="white"
      pe={8}
      borderBottom="1px solid"
      borderLeft="1px solid"
      borderColor="border"
      paddingInline={8}
      h={20}
    >
      <HStack
        spacing={4}
        justifyContent={"space-between"}
        alignItems={"center"}
        h={"100%"}
      >
        <Image src={logo} h={{ base: 8, md: 10 }}></Image>
        <ButtonGroup spacing={4}>
          <OutstandingTasks />
          <Menu>
            <MenuButton>
              <Avatar size={{ base: "xs", md: "sm" }} />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => navigate("/settings")}>
                {t("settings.self")}
              </MenuItem>
              <MenuItem onClick={() => logout()}>
                {t("common.signOut")}
              </MenuItem>
            </MenuList>
          </Menu>
        </ButtonGroup>
      </HStack>
    </Box>
  );
};
