import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { SectionHeading } from "../components/SectionHeading";
import { GoalItem } from "./GoalItem";
import { Link } from "react-router-dom";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { WidgetPlaceholder } from "../components/WidgetPlaceholder.tsx";
import { GoalProgress } from "../generated/graphql.ts";

interface GoalTrackingProps {
  data: {
    goals: GoalProgress[];
  };
}

export const GoalTracking = ({ data }: GoalTrackingProps) => {
  const { t } = useTranslation();

  if (!data?.goals?.length)
    return <WidgetPlaceholder widgetId={"goal_tracking"} />;

  return (
    <VStack spacing={4} alignItems="stretch" width="full">
      <HStack justifyContent="space-between" width="full">
        <SectionHeading>{t("goalTracking.self")}</SectionHeading>
      </HStack>
      <VStack
        layerStyle={"frame"}
        spacing={4}
        width="100%"
        alignItems={"stretch"}
      >
        <Button
          variant={"link"}
          size={"sm"}
          alignSelf={"flex-end"}
          as={Link}
          to={"/goals/create"}
          leftIcon={<PlusSquareIcon />}
        >
          {t("goalTracking.addGoal")}
        </Button>
        {!data?.goals.length && (
          <Text fontSize={"sm"} color={"casBlueGrey"}>
            {t("goalTracking.noData")}
          </Text>
        )}
        {data?.goals?.map((goal) => <GoalItem key={goal.id} goal={goal} />)}
      </VStack>
    </VStack>
  );
};
