import { theme } from "../theme/theme";
import { useMemo } from "react";
import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import { capitalize } from "lodash";
import i18n, { t } from "i18next";
import { useProfile } from "../User/useProfile.tsx";

const barThickness = 48;

const toGraphData = (
  left: number,
  expenses: number,
  upcomingExpenses: number,
): ChartData<"bar", number[]> => {
  const graphData = {
    labels: [""],
    datasets: [
      {
        label: t("common.spent"),
        data: [expenses],
        backgroundColor: theme.colors.casDarkBlue,
        maxBarThickness: barThickness,
      },
      {
        label: t("common.upcoming"),
        data: [upcomingExpenses],
        backgroundColor: theme.colors.casBlue,
        maxBarThickness: barThickness,
      },
      {
        label: left < 0 ? t("common.overBudget") : t("common.left"),
        data: [Math.abs(left)],
        backgroundColor: left < 0 ? theme.colors.casRed : theme.colors.casGreen,
        maxBarThickness: barThickness,
      },
    ],
  };

  return graphData;
};

const generateGraphOptions = (currency: string): ChartOptions<"bar"> => {
  const graphOptions = {
    indexAxis: "y" as const,
    maintainAspectRatio: false,
    backgroundColor: "#ccc",
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: TooltipItem<"bar">) => {
            return `${capitalize(context.dataset.label)}: ${(
              context.raw as number
            ).toLocaleString(i18n.language, {
              style: "currency",
              currency,
              maximumFractionDigits: 0,
            })}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        display: false,
      },
      y: {
        stacked: true,
        display: false,
      },
    },
  };

  return graphOptions;
};

export const useCashflowChart = (
  left: number,
  spent: number,
  upcoming: number,
) => {
  const profile = useProfile();
  const graphOptions = useMemo(
    () => generateGraphOptions(profile?.preferredCurrency || "EUR"),
    [],
  );
  const graphData = useMemo(
    () => toGraphData(left, spent, upcoming),
    [left, spent, upcoming],
  );

  return { graphOptions, graphData };
};
