import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { GoBack } from "../components/GoBack.tsx";
import { SectionHeading } from "../components/SectionHeading";
import { Page } from "../Layout/Page/Page";
import { useEffect, useState } from "react";
import { EditProfile } from "./EditProfile";
import { EditBudget } from "./EditBudget";
import { EditAccounts } from "./EditAccounts";
import { useSearchParams } from "react-router-dom";
import bg from "../assets/header_bg_raw.svg";
import { useTranslation } from "react-i18next";

const tabs = ["profile", "budget", "accounts"];

export const Settings = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTranslation();

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
    setSearchParams({ tab: tabs[index] });
  };

  useEffect(() => {
    if (searchParams) {
      const index = tabs.indexOf(searchParams.get("tab") || "profile");
      if (index > -1) {
        setTabIndex(index);
      }
    }
  }, [searchParams]);

  return (
    <Page
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <Box py={6}>
        <GoBack to={"/"} />
      </Box>
      <Box mb={8}>
        <SectionHeading>{t("settings.self")}</SectionHeading>
      </Box>
      <Tabs colorScheme={"blue"} index={tabIndex} onChange={handleTabsChange}>
        <TabList>
          <Tab>{t("settings.editProfile")}</Tab>
          <Tab>{t("settings.editBudget")}</Tab>
          <Tab>{t("settings.manageAccounts")}</Tab>
        </TabList>

        <TabPanels>
          <TabPanel backgroundColor={"white"} p={4} borderRadius={"md"}>
            <EditProfile />
          </TabPanel>
          <TabPanel backgroundColor={"white"} p={4} borderRadius={"md"}>
            <EditBudget />
          </TabPanel>
          <TabPanel backgroundColor={"white"} p={4} borderRadius={"md"}>
            <EditAccounts />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Page>
  );
};
