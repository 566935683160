import IErrorHandler from "./types";
import * as Sentry from "@sentry/react";

type GenericErrorObject<T> = {
  error: T;
};

class GenericErrorHandler<T> implements IErrorHandler<GenericErrorObject<T>> {
  handle({ error }: GenericErrorObject<T>): void {
    console.error(`[Generic error]: ${error}`);
    Sentry.captureException(error);
  }
}

export default new GenericErrorHandler();
