import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";
import { gql } from "@apollo/client";
import { AccountHeaderAccountsFragment } from "../generated/graphql";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { UpdatedAt } from "../components/UpdatedAt.tsx";
import AccountName from "../Accounts/AccountName.tsx";

type AccountHeaderProps = {
  accounts: AccountHeaderAccountsFragment[];
  onSelectedAccounts: (ids: string[]) => void;
  selectedAccounts?: string[];
};

export const AccountHeader = ({
  accounts,
  onSelectedAccounts,
  selectedAccounts,
}: AccountHeaderProps) => {
  const { t } = useTranslation();

  if (!accounts?.length) {
    return null;
  }

  if (accounts.length === 1) {
    const account = accounts[0];
    return (
      <HStack width="100%" justifyContent="space-between">
        <Box>
          <Text
            fontSize={{ base: "md", md: "lg" }}
            color="casDarkBlue"
            fontWeight="medium"
          >
            {account.name} ({account.institution?.name})
          </Text>
          <UpdatedAt date={account.updated_at} />
        </Box>
      </HStack>
    );
  }

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        maxW={"min-content"}
        variant={"outline"}
      >
        {accounts.length} {t("accounts.account", { count: accounts.length })}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          type={"checkbox"}
          value={selectedAccounts}
          onChange={(val) => onSelectedAccounts(val as string[])}
        >
          {accounts.map((account) => (
            <MenuItemOption key={account.id} value={account.id}>
              <AccountName account={account} />
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

AccountHeader.fragments = {
  accounts: gql`
    fragment AccountHeaderAccounts on accounts_new {
      id
      name
      balance
      updated_at
      type
      institution {
        id
        name
      }
      connection {
        id
        external_id
        state
        lastUpdate: updated_at
      }
    }
  `,
};
