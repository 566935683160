import { HStack, IconButton, Text } from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

type CalendarNavigationProps = {
  now: Date;
  onNext(): void;
  onPrev(): void;
};

export const CalendarNavigation = ({
  now,
  onNext,
  onPrev,
}: CalendarNavigationProps) => {
  const { t, i18n } = useTranslation();

  const month = new Intl.DateTimeFormat(i18n.language, {
    month: "long",
  }).format(now);
  return (
    <HStack justifyContent="space-between" py={4}>
      <IconButton
        onClick={onPrev}
        aria-label={t("calendar.previous", { context: "month" })}
        icon={<ArrowLeftIcon />}
        size="sm"
      />
      <Text casing={"capitalize"} fontWeight={500} color={"casDarkBlue"}>
        {month}
      </Text>
      <IconButton
        onClick={onNext}
        aria-label={t("calendar.next", { context: "month" })}
        icon={<ArrowRightIcon />}
        size="sm"
      />
    </HStack>
  );
};
