import { Box, Divider, HStack, Text, VStack } from "@chakra-ui/react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";
import { useCashflowChart } from "./useCashflowGraph";
import { theme } from "../theme/theme";
import { SectionHeading } from "../components/SectionHeading";
import { gql } from "@apollo/client";
import {
  AccountsCountFragment,
  CashflowBreakdownFragment,
} from "../generated/graphql";
import { useTranslation } from "react-i18next";
import { Currency } from "../components/Currency";
import { useMemo } from "react";
import { WidgetPlaceholder } from "../components/WidgetPlaceholder.tsx";

ChartJS.register(...registerables);

/**
 * This component is responsible for displaying the cashflow graph
 * what is the total saved, expenses and upcoming expenses
 */

interface MonthCashflowProps {
  data: {
    cashflow_breakdown: CashflowBreakdownFragment[];
    accounts_count: AccountsCountFragment;
  };
}

export const MonthCashflow = ({ data }: MonthCashflowProps) => {
  const { t } = useTranslation();

  const { remaining, spent, upcomingexpenses } = useMemo(() => {
    const breakdown = data?.cashflow_breakdown[0];
    return {
      income: breakdown?.income || 0,
      remaining: breakdown?.remaining || 0,
      spent: breakdown?.spent || 0,
      upcomingexpenses: breakdown?.upcomingexpenses || 0,
    };
  }, [data]);

  const { graphOptions, graphData } = useCashflowChart(
    remaining,
    spent,
    upcomingexpenses,
  );

  if (!data?.accounts_count?.aggregate?.count) {
    return <WidgetPlaceholder widgetId={"month_cashflow"} />;
  }

  return (
    <VStack spacing={4} alignItems="stretch" width="100%">
      <SectionHeading>
        {t("cashflowSnapshot.current", {
          month: new Date(),
          formatParams: { month: { month: "long" } },
        })}
      </SectionHeading>
      <VStack
        layerStyle={"frame"}
        spacing={2}
        width="100%"
        alignItems={"flex-start"}
      >
        <Box height="128px" width="100%">
          <Bar options={graphOptions} data={graphData} />
        </Box>
        <HStack alignItems="center" justifyContent="space-between" width="100%">
          <HStack>
            <Box
              height="8px"
              sx={{ aspectRatio: "1/1" }}
              backgroundColor="casDarkBlue"
            ></Box>
            <Text fontSize={{ base: "sm", md: "md" }}>{t("common.spent")}</Text>
          </HStack>
          <Text fontSize={{ base: "sm", md: "md" }}>
            <Currency value={spent} options={{ maximumFractionDigits: 0 }} />
          </Text>
        </HStack>
        <HStack alignItems="center" justifyContent="space-between" width="100%">
          <HStack>
            <Box
              height="8px"
              sx={{ aspectRatio: "1/1" }}
              backgroundColor="casBlue"
            ></Box>
            <Text fontSize={{ base: "sm", md: "md" }}>
              {t("common.upcoming")}
            </Text>
          </HStack>
          <Text fontSize={{ base: "sm", md: "md" }}>
            <Currency
              value={upcomingexpenses}
              options={{ maximumFractionDigits: 0 }}
            />
          </Text>
        </HStack>
        <Divider />
        <HStack alignItems="center" justifyContent="space-between" width="100%">
          <HStack>
            <Box
              height={2}
              sx={{ aspectRatio: "1/1" }}
              backgroundColor={
                remaining < 0 ? theme.colors.casRed : theme.colors.casTeal
              }
            ></Box>
            <Text fontWeight={"semibold"} fontSize={{ base: "sm", md: "md" }}>
              {t("common.forecasted")}
            </Text>
          </HStack>
          <Text fontWeight={"semibold"} fontSize={{ base: "sm", md: "md" }}>
            <Currency
              value={remaining}
              options={{ maximumFractionDigits: 0 }}
            />
          </Text>
        </HStack>
      </VStack>
    </VStack>
  );
};

MonthCashflow.fragments = {
  breakdown: gql`
    fragment CashflowBreakdown on user_cashflow_breakdown {
      income
      remaining
      spent
      upcomingexpenses
    }
  `,
  accountsAggregate: gql`
    fragment AccountsCount on accounts_new_aggregate {
      aggregate {
        count
      }
    }
  `,
};
