import { Flex, Heading, VStack } from "@chakra-ui/react";
import { IncomeIcon } from "../components/icons/IncomeIcon";
import { BalanceIcon } from "../components/icons/BalanceIcon";
import { useMemo } from "react";
import { Currency } from "../components/Currency";
import { SavingsIcon } from "../components/icons/SavingsIcon";
import { gql } from "@apollo/client";
import {
  AccountsCountFragment,
  KeyNumbersFragment,
} from "../generated/graphql";
import { useTranslation } from "react-i18next";
import { KeyNumber, KeyNumberPlaceholder } from "../components/KeyNumber.tsx";
import { SectionHeading } from "../components/SectionHeading.tsx";
import { useNavigate } from "react-router-dom";

interface KeyNumbersProps {
  data: {
    accounts_count: AccountsCountFragment;
    numbers: KeyNumbersFragment[];
  };
}

export const KeyNumbers = ({ data }: KeyNumbersProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const keyNumbers = useMemo(() => data?.numbers[0], [data]);
  const hasAccounts = useMemo(
    () => (data?.accounts_count?.aggregate?.count || 0) > 0,
    [data],
  );

  const cashflow = useMemo(() => keyNumbers?.cashflow || 0, [data]);
  const netWorth = useMemo(() => keyNumbers?.net_worth || 0, [data]);
  const savingsRate = useMemo(() => {
    return ((keyNumbers?.savings_rate || 0) * 0.01).toLocaleString(
      i18n.language,
      {
        style: "percent",
        maximumFractionDigits: 0,
      },
    );
  }, [data]);

  if (!hasAccounts) {
    return <KeyNumbersPlaceholder />;
  }

  return (
    <VStack spacing={2} alignItems={"stretch"}>
      <SectionHeading>{t("keyNumbers.self")}</SectionHeading>
      <Flex gap={4} direction={{ base: "column", md: "row" }}>
        <KeyNumber
          icon={<BalanceIcon boxSize={{ base: 12, md: 16 }} />}
          label={t("keyNumbers.netWorth")}
          value={<Currency value={netWorth} />}
          onClick={() => navigate("/net-worth")}
        />
        <KeyNumber
          icon={<IncomeIcon boxSize={{ base: 12, md: 16 }} />}
          label={t("keyNumbers.remainingBudget")}
          value={<Currency value={cashflow} />}
          helpText={t("glossary.remainingBudget")}
        />

        <KeyNumber
          icon={<SavingsIcon boxSize={{ base: 12, md: 16 }} />}
          label={t("keyNumbers.savingsRate")}
          value={savingsRate}
          helpText={t("glossary.savingsRate")}
        />
      </Flex>
    </VStack>
  );
};

const KeyNumbersPlaceholder = () => {
  const { t } = useTranslation();
  return (
    <VStack spacing={2} alignItems={"stretch"}>
      <Heading size={"md"} color={"casDarkBlue"}>
        {t("keyNumbers.self")}
      </Heading>
      <Flex gap={4} direction={{ base: "column", md: "row" }}>
        <KeyNumberPlaceholder
          icon={<BalanceIcon boxSize={{ base: 12, md: 16 }} />}
          label={t("keyNumbers.netWorth")}
          value={t("keyNumbers.netWorthHelper")}
        />

        <KeyNumberPlaceholder
          icon={<IncomeIcon boxSize={{ base: 12, md: 16 }} />}
          label={t("keyNumbers.remainingBudget")}
          value={t("keyNumbers.remainingBudgetHelper")}
        />

        <KeyNumberPlaceholder
          icon={<SavingsIcon boxSize={{ base: 12, md: 16 }} />}
          label={t("keyNumbers.savingsRate")}
          value={t("keyNumbers.savingsRateHelper")}
        />
      </Flex>
    </VStack>
  );
};

KeyNumbers.fragments = {
  accountCount: gql`
    fragment AccountsCount on accounts_new_aggregate {
      aggregate {
        count
      }
    }
  `,
  keyNumbers: gql`
    fragment KeyNumbers on user_financial_summary {
      cashflow
      net_worth
      savings_rate
    }
  `,
};
