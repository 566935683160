import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Center,
  CloseButton,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PasswordResetEmailSent = () => {
  const { t } = useTranslation();
  return (
    <Center w={"100vw"} h={"100vh"} bg="casBackground" overflow={"hidden"}>
      <Alert
        status="success"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        height="200px"
        maxW={"md"}
        layerStyle={"frame"}
        position={"relative"}
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          {t("auth.resetPasswordSentTitle")}
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          {t("auth.resetPasswordSentDescription")}
        </AlertDescription>
        <CloseButton
          as={Link}
          position="absolute"
          right={2}
          top={2}
          to={"/login"}
          aria-label={t("common.close")}
        />
      </Alert>
    </Center>
  );
};

export default PasswordResetEmailSent;
