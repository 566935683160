import { gql } from "@apollo/client";
import { Box, HStack, VStack } from "@chakra-ui/react";
import { SectionHeading } from "../../components/SectionHeading.tsx";
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  registerables,
  TooltipItem,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ContextualHelp from "../../components/ContextualHelp.tsx";
import { WallchartFragment } from "../../generated/graphql.ts";
import { WidgetPlaceholder } from "../../components/WidgetPlaceholder.tsx";
import { useCurrency } from "../../utils/useCurrency.ts";
import { capitalize } from "lodash";

ChartJS.register(...registerables);

interface WallchartProps {
  data: WallchartFragment[];
}

export const Wallchart = ({ data }: WallchartProps) => {
  const { t, i18n } = useTranslation();
  const { format, formatCompact } = useCurrency();

  if (!data.length) {
    return <WidgetPlaceholder widgetId={"wallchart"} />;
  }

  const chartData: ChartData<"line", number[]> = {
    labels: data.map((d) => {
      return new Date(d.month);
    }),
    datasets: [
      {
        label: t("common.expense", { count: 2 }),
        data: data.map((d) => d.total_expenses),
        borderColor: "#FF82AC",
        backgroundColor: "rgba(255,130,172,0.5)",
        tension: 0.4,
        fill: false,
        pointRadius: 0,
        pointHoverBackgroundColor: "#FF82AC",
      },
      {
        label: t("common.income"),
        data: data.map((d) => d.total_income),
        borderColor: "#16DBCC",
        backgroundColor: "rgba(22,219,204,0.5)",
        tension: 0.4,
        fill: {
          target: "0",
          above: "rgba(22,219,204,0.1)",
          below: "rgba(255,130,172,0.1)",
        },
        pointRadius: 0,
        pointHoverBackgroundColor: "#16DBCC",
      },
    ],
  };
  const chartConfig: ChartOptions<"line"> = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title(tooltipItems: TooltipItem<"line">[]): string | string[] | void {
            return capitalize(
              new Date(tooltipItems[0].parsed.x as number).toLocaleString(
                i18n.language,
                {
                  month: "short",
                  year: "numeric",
                },
              ),
            );
          },
          label(tooltipItem: TooltipItem<"line">) {
            let label = tooltipItem.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (tooltipItem.parsed.y !== null) {
              label += format(tooltipItem.parsed.y, {
                maximumFractionDigits: 0,
              });
            }
            return label;
          },
          footer(tooltipItems: TooltipItem<"line">[]) {
            const index = tooltipItems[0].dataIndex;
            const d = data[index];
            return `${
              d.total_savings > 0 ? t("common.savings") : t("common.overspent")
            }: ${format(Math.abs(d.total_savings), {
              maximumFractionDigits: 0,
            })}`;
          },
        },
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    scales: {
      x: {
        type: "time",
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        time: {
          unit: "month",
          tooltipFormat: "MMM yyyy",
        },
        ticks: {
          callback: function (_, index, ticks) {
            const date = new Date(ticks[index].value);
            const month = date.toLocaleString(i18n.language, {
              month: "short",
            });

            if (date.getMonth() === 0) {
              return date.getFullYear();
            }
            return capitalize(month);
          },
        },
      },
      y: {
        border: {
          display: false,
          dash: [4, 4],
        },
        grid: {
          display: true,
          drawTicks: false,
        },
        ticks: {
          callback: (value) => {
            return formatCompact(value as number);
          },
        },
      },
    },
  };

  return (
    <VStack spacing={4} alignItems="stretch" h={"full"}>
      <HStack alignItems={"center"}>
        <SectionHeading>{t("wallchart.title")}</SectionHeading>
        <ContextualHelp helpText={t("glossary.cashflow")} />
      </HStack>
      <Box
        layerStyle={"frame"}
        h={{ base: "xs", md: "full" }}
        position={"relative"}
      >
        <Box position={"absolute"} top={4} bottom={4} right={4} left={4}>
          <Line data={chartData} options={chartConfig} />
        </Box>
      </Box>
    </VStack>
  );
};

Wallchart.fragments = {
  entry: gql`
    fragment Wallchart on wallchart_data {
      month
      total_expenses
      total_income
      total_savings
    }
  `,
};
