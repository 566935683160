import { gql, useSuspenseQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { NetWorthOverviewPlaceholderQuery } from "../generated/graphql.ts";
import { useMemo } from "react";
import { Button, Image, Text, VStack } from "@chakra-ui/react";
import { SectionHeading } from "./SectionHeading.tsx";
import { Link } from "react-router-dom";

interface WidgetPlaceholderProps {
  widgetId: string;
}

export const WidgetPlaceholder = ({ widgetId }: WidgetPlaceholderProps) => {
  const { i18n } = useTranslation();
  const { data: placeholderData } =
    useSuspenseQuery<NetWorthOverviewPlaceholderQuery>(PLACEHOLDER_QUERY, {
      variables: {
        id: widgetId,
        locale: i18n.language.substring(0, 2),
      },
    });

  const placeholder = useMemo(
    () => (placeholderData?.widget_placeholders || [])[0],
    [placeholderData],
  );

  return (
    <VStack spacing={4} alignItems="stretch" width="full">
      <SectionHeading>{placeholder.title}</SectionHeading>
      <VStack
        layerStyle={"frame"}
        width="100%"
        alignItems={"center"}
        spacing={8}
        position={"relative"}
        minH={"xs"}
      >
        <Image
          src={placeholder?.illustration?.url as string}
          maxW={"75%"}
          alt={placeholder?.illustration?.alternative_text as string}
        />
        <VStack
          position={"absolute"}
          left={0}
          right={0}
          top={0}
          bottom={0}
          justifyContent={"flex-end"}
          alignItems={"stretch"}
          bg={
            "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 40%)"
          }
          px={8}
          py={16}
          spacing={4}
        >
          <Text fontSize={{ base: "sm", md: "md" }} color="casDarkBlue">
            {placeholder.content}
          </Text>
          <Button
            as={Link}
            to={"/goals/create"}
            variant={"link"}
            colorScheme={"purple"}
            size={"sm"}
          >
            {placeholder.cta}
          </Button>
        </VStack>
      </VStack>
    </VStack>
  );
};

const PLACEHOLDER_QUERY = gql`
  query NetWorthOverviewPlaceholder($id: String!, $locale: String!) {
    widget_placeholders(
      where: { widget_id: { _eq: $id }, locale: { _eq: $locale } }
    ) {
      illustration {
        ext
        hash
        alternative_text
        height
        url
      }
      title
      widget_id
      locale
      content
      cta
      id
    }
  }
`;
