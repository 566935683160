import {
  chakra,
  Circle,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Text,
} from "@chakra-ui/react";
import { MdWarning } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { UserOutstandingTasksFragment } from "../../generated/graphql.ts";
import { useNavigate } from "react-router-dom";

interface ConnectionsErrorsProps {
  onClick: (id: string, onCompleted: () => void) => void;
  data: UserOutstandingTasksFragment;
}

const FixConnectionsErrorsTask = ({
  onClick,
  data: { id },
}: ConnectionsErrorsProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleClick = () => {
    onClick(id, () => navigate("/settings?tab=accounts"));
  };

  return (
    <LinkBox w={"full"}>
      <HStack layerStyle={"hoverableListItem"}>
        <Circle bg={"red.500"} size={8} centerContent={true}>
          <Icon as={MdWarning} color={"white"} boxSize={4} />
        </Circle>

        <LinkOverlay onClick={handleClick}>
          <Text color={"casDarkBlue"} fontWeight={700}>
            <chakra.span color={"red.400"}>{1}</chakra.span>{" "}
            {t("outstandingTasks.accountRequireAction", { count: 1 })}
          </Text>
        </LinkOverlay>
      </HStack>
    </LinkBox>
  );
};

export default FixConnectionsErrorsTask;
