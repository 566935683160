import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icon";

export const SavingsIcon = (props: IconProps) => (
  <Icon viewBox="0 0 70 70" {...props}>
    <circle cx="35" cy="35" r="35" fill="#DCFAF8" />
    <g clipPath="url(#clip0_126_76)">
      <path
        d="M47.9818 34.3653H46.1975C45.7913 33.2291 45.1368 32.2118 44.2991 31.3801L47.0509 30.1654C47.444 29.9918 47.6593 29.5581 47.5475 29.1433C47.2156 27.9118 46.0955 27.0059 44.7648 27.0059H42.4951C41.19 27.0059 40.088 27.8776 39.733 29.0729H37.3359C36.4356 32.3986 33.3946 34.8522 29.7913 34.8522C27.5917 34.8522 25.6018 33.9377 24.1802 32.469C23.7799 33.0403 23.4519 33.6664 23.2102 34.3342C22.4923 34.1771 21.9019 33.6092 21.7369 32.8697C21.6312 32.3959 21.1611 32.0972 20.6876 32.2033C20.2139 32.3091 19.9155 32.7788 20.0213 33.2526C20.2011 34.0581 20.6535 34.7894 21.2951 35.3118C21.7343 35.6693 22.2507 35.9182 22.7978 36.043C22.7552 36.378 22.7332 36.7195 22.7332 37.066V37.9182C22.7332 41.1482 24.6402 43.9303 27.3846 45.191V47.1279C27.3846 48.182 28.2392 49.0366 29.2933 49.0366C30.3475 49.0366 31.2021 48.182 31.2021 47.1279V45.9113H38.3524V47.1279C38.3524 48.182 39.207 49.0366 40.2612 49.0366C41.3154 49.0366 42.1699 48.182 42.1699 47.1279V45.1165C44.1766 44.1404 45.7132 42.3424 46.3467 40.1577H47.9816C49.0964 40.1577 50.0001 39.2498 50.0001 38.1298V36.3931C50.0002 35.2732 49.0965 34.3653 47.9818 34.3653ZM41.4964 34.8727C41.0128 34.8727 40.6207 34.4807 40.6207 33.997C40.6207 33.5133 41.0127 33.1213 41.4964 33.1213C41.9801 33.1213 42.3722 33.5133 42.3722 33.997C42.3722 34.4807 41.9801 34.8727 41.4964 34.8727Z"
        fill="#16DBCC"
      />
      <path
        d="M29.7912 20.9634C26.4507 20.9634 23.7332 23.6844 23.7332 27.0289C23.7332 30.3734 26.4508 33.0944 29.7912 33.0944C33.1317 33.0944 35.8493 30.3734 35.8493 27.0289C35.8493 23.6842 33.1316 20.9634 29.7912 20.9634ZM29.2287 26.1021H30.3538C31.623 26.1021 32.6554 27.1353 32.6554 28.4053C32.6554 29.5678 31.7901 30.5314 30.6702 30.6862V31.0163C30.6702 31.5017 30.2766 31.8953 29.7912 31.8953C29.3059 31.8953 28.9123 31.5017 28.9123 31.0163V30.7085H28.6881C27.7171 30.7085 26.927 29.9181 26.927 28.9465C26.927 28.4611 27.3205 28.0675 27.8059 28.0675C28.2913 28.0675 28.6848 28.4611 28.6848 28.9465L30.3538 28.9507C30.6536 28.9507 30.8976 28.706 30.8976 28.4053C30.8976 28.1046 30.6536 27.8599 30.3538 27.8599H29.2286C27.9595 27.8599 26.927 26.8267 26.927 25.5567C26.927 24.3941 27.7924 23.4305 28.9123 23.2757V23.0414C28.9123 22.556 29.3059 22.1625 29.7912 22.1625C30.2766 22.1625 30.6702 22.556 30.6702 23.0414V23.2534H30.8943C31.8654 23.2534 32.6554 24.0439 32.6554 25.0155C32.6554 25.5009 32.2619 25.8944 31.7765 25.8944C31.2911 25.8944 30.8976 25.5009 30.8976 25.0155C30.8976 25.0141 30.8975 25.0135 30.8975 25.0135L29.2287 25.0112C28.9288 25.0112 28.6849 25.2559 28.6849 25.5567C28.6849 25.8574 28.9288 26.1021 29.2287 26.1021Z"
        fill="#16DBCC"
      />
    </g>
    <defs>
      <clipPath id="clip0_126_76">
        <rect
          width="30"
          height="30"
          fill="white"
          transform="translate(20 20)"
        />
      </clipPath>
    </defs>
  </Icon>
);
