import {
  chakra,
  Circle,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Text,
} from "@chakra-ui/react";

import { MdTipsAndUpdates } from "react-icons/md";
import { Trans } from "react-i18next";
import { UserOutstandingTasksFragment } from "../../generated/graphql.ts";
import { useNavigate } from "react-router-dom";

interface UncategorizedTransactionsProps {
  onClick: (id: string, onCompleted: () => void) => void;
  data: UserOutstandingTasksFragment;
}

export const CategorizeTransactionsTask = ({
  onClick,
  data: {
    metadata: { count },
    id,
  },
}: UncategorizedTransactionsProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    onClick(id, () => navigate("/transactions/uncategorized"));
  };

  if (!count) {
    return null;
  }

  return (
    <LinkBox w={"full"}>
      <HStack layerStyle={"hoverableListItem"}>
        <Circle bg={"purple.500"} size={8} centerContent={true}>
          <Icon as={MdTipsAndUpdates} color={"white"} boxSize={4} />
        </Circle>

        <LinkOverlay onClick={handleClick}>
          <Text color={"casDarkBlue"} fontWeight={700}>
            <Trans i18nKey={"uncategorizedTransactions.alert"} count={count}>
              You have <chakra.span color={"red.400"}>{count}</chakra.span>{" "}
              uncategorized transactions
            </Trans>
          </Text>
        </LinkOverlay>
      </HStack>
    </LinkBox>
  );
};
