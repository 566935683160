import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Switch,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { MdEuro } from "react-icons/md";
import { useMemo } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  EditProfileQuery,
  InsertUserProfileMutation,
} from "../generated/graphql";
import { useTranslation } from "react-i18next";
import { parseNumber, toLocaleNumber } from "../utils/utils.ts";
import gqlErrorHandler from "../utils/gqlErrorHandler.ts";
import { useAuth } from "../Auth/useAuth.ts";
import SelectCurrency from "../components/SelectCurrency.tsx";

export const EditProfile = () => {
  const { user } = useAuth();
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const { data: userData } = useQuery<EditProfileQuery>(GET_PROFILE, {
    variables: {
      user_id: user?.uid,
    },
    skip: !user?.uid,
  });
  const [saveProfile, { loading: savingProfile }] =
    useMutation<InsertUserProfileMutation>(SAVE_PROFILE, {
      onCompleted: () => {
        toast({
          title: t("profile.saveSuccessTitle"),
          status: "success",
        });
      },
      onError: (err) => {
        toast({
          title: gqlErrorHandler.getPrettyMessage(err),
          status: "error",
        });
      },
    });

  const initialData = useMemo(() => userData?.users_by_pk?.profile, [userData]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<FormData>({
    values: {
      firstName: initialData?.first_name || "",
      lastName: initialData?.last_name || "",
      monthlyIncome: initialData?.estimated_monthly_income || 0,
      emailAlerts: initialData?.subscribe_emails as boolean,
      currency: initialData?.preferred_currency || "EUR",
    },
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  const onSubmit = async (formData: FieldValues) => {
    await saveProfile({
      variables: {
        profile: {
          first_name: formData.firstName,
          last_name: formData.lastName,
          estimated_monthly_income: formData.monthlyIncome,
          subscribe_emails: formData.emailAlerts,
          preferred_currency: formData.currency,
        },
      },
    });
  };
  return (
    <VStack
      as={"form"}
      onSubmit={handleSubmit(onSubmit)}
      spacing={6}
      maxW={{ base: "full", md: "sm" }}
      alignItems={"flex-start"}
    >
      <HStack spacing={2} flexWrap={"wrap"} alignItems={"flex-start"}>
        <FormControl isInvalid={!!errors.firstName}>
          <FormLabel>{t("profile.firstName")}</FormLabel>
          <Input
            type="text"
            {...register("firstName", { required: t("common.fieldRequired") })}
          />
          <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!errors.lastName}>
          <FormLabel>{t("profile.lastName")}</FormLabel>
          <Input
            type="text"
            {...register("lastName", { required: t("common.fieldRequired") })}
          />
          <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
        </FormControl>
      </HStack>

      <FormControl isInvalid={!!errors.monthlyIncome}>
        <FormLabel>{t("profile.estimatedMonthlyIncome")}</FormLabel>
        <InputGroup>
          <Controller
            render={({ field }) => (
              <Input
                {...field}
                inputMode={"numeric"}
                value={toLocaleNumber(field.value, i18n.language)}
                onBlur={(e) => field.onChange(parseNumber(e.target.value))}
              />
            )}
            name={"monthlyIncome"}
            control={control}
            rules={{
              min: {
                value: 0,
                message: t("common.minValueError", { minValue: 0 }),
              },
            }}
          />
          <InputRightElement>
            <MdEuro />
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>{errors.monthlyIncome?.message}</FormErrorMessage>
      </FormControl>

      <FormControl>
        <FormLabel>{t("profile.preferredCurrency")}</FormLabel>
        <Controller
          render={({ field }) => <SelectCurrency {...field} />}
          name={"currency"}
          control={control}
        />
      </FormControl>

      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="email-alerts" mb={0}>
          {t("profile.subscribeEmailAlerts")}
        </FormLabel>
        <Controller
          render={({ field }) => {
            return (
              <Switch
                id="email-alerts"
                onChange={(e) => field.onChange(e.target.checked)}
                isChecked={field.value}
              />
            );
          }}
          name={"emailAlerts"}
          control={control}
        />
      </FormControl>

      {/* Submit button */}
      <Button
        type="submit"
        colorScheme={"blue"}
        isDisabled={!isValid}
        isLoading={savingProfile}
      >
        {t("common.save")}
      </Button>
    </VStack>
  );
};

const SAVE_PROFILE = gql`
  mutation SaveUserProfile($profile: profiles_insert_input!) {
    insert_profiles_one(
      object: $profile
      on_conflict: {
        update_columns: [
          first_name
          last_name
          full_name
          estimated_monthly_income
          subscribe_emails
          preferred_currency
        ]
        constraint: profiles_user_id_key
      }
    ) {
      first_name
      last_name
      full_name
      estimated_monthly_income
      subscribe_emails
      preferred_currency
    }
  }
`;

const GET_PROFILE = gql`
  query EditProfile($user_id: String!) {
    users_by_pk(auth_provider_id: $user_id) {
      id
      profile {
        id
        first_name
        last_name
        estimated_monthly_income
        subscribe_emails
        preferred_currency
      }
    }
  }
`;

interface FormData {
  firstName: string;
  lastName: string;
  monthlyIncome: number;
  emailAlerts: boolean;
  currency: string;
}
