import { CategorySeriesData } from "../types/series";
import { capitalize } from "lodash";
import { transparentize } from "../utils/utils";
import { theme } from "../theme/theme";
import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import i18n, { t } from "i18next";

export type CategoryBudget = CategorySeriesData & {
  target: number;
};

export const toChartOptions = (
  expenses: CategoryBudget[],
  currency: string,
): ChartOptions<"bar"> => {
  return {
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title(tooltipItems) {
            return capitalize(
              t(`category.${expenses[tooltipItems[0].dataIndex]?.category}`),
            );
          },
          label: (context: TooltipItem<"bar">) => {
            const planned = expenses[context.dataIndex].target as number;
            const spent = expenses[context.dataIndex].value as number;
            const overspent = spent > planned ? spent - planned : 0;

            const formatCurrency = (value: number, currency = "EUR") => {
              return (
                value?.toLocaleString(i18n.language, {
                  style: "currency",
                  currency,
                  maximumFractionDigits: 0,
                }) ?? "--"
              );
            };

            switch (context.datasetIndex) {
              case 0:
                return `${t("common.spent")}: ${formatCurrency(
                  spent as number,
                  currency,
                )}`;
              case 1:
                return `${t("common.overspent")}: ${formatCurrency(
                  overspent,
                  currency,
                )}`;
              case 2:
                return `${t("common.planned")}: ${formatCurrency(
                  planned as number,
                  currency,
                )}`;
              default:
                return "";
            }
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: true,

    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          callback: (value) => {
            return capitalize(
              t(`category.${expenses[value as number]?.category}`),
            );
          },
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
        beginAtZero: true,
      },
    },
  };
};

export const toChartData = (
  expenses: CategoryBudget[],
): ChartData<"bar", number[]> => {
  const labels = expenses.map((exp) => exp.category);

  return {
    labels,
    datasets: [
      {
        label: t("common.spent"),
        data: expenses.map((exp) =>
          exp.value > exp.target ? exp.target : exp.value,
        ),
        backgroundColor: transparentize(theme.colors.casTeal, 0.1),
        borderSkipped: false,
        barThickness: 50,
        datalabels: {
          color: theme.colors.casDarkTeal,
          anchor: "center",
          align: "end",
          offset: 4,
          clamp: true,
          font: {
            family: theme.fonts.body,
            size: theme.fontSizes.sm,
            weight: 500,
          },
        },
      },
      {
        label: t("common.overspent"),
        data: expenses.map((exp) =>
          exp.value > exp.target ? exp.target + (exp.value - exp.target) : 0,
        ),
        backgroundColor: theme.colors.casRed,
        borderSkipped: false,
        barThickness: 50,
        datalabels: {
          display: false,
        },
      },
      {
        label: t("common.planned"),
        data: expenses.map((exp) => exp.target),
        backgroundColor: theme.colors.casLightGrey,
        borderSkipped: false,
        barThickness: 50,
        datalabels: {
          display: false,
        },
      },
    ],
  };
};
