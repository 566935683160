import { useNavigate } from "react-router-dom";
import { FirebaseError } from "firebase/app";
import * as Sentry from "@sentry/react";
import { ApolloError } from "@apollo/client";
import gqlErrorHandler from "../utils/gqlErrorHandler.ts";
import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const useError = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toastr = useToast();

  const handle = <T>(error: T) => {
    if (error instanceof FirebaseError) {
      return handleFBError(error);
    }

    if (error instanceof ApolloError) {
      return gqlErrorHandler.handle(error, (errors) => {
        if (errors.some((e) => e.message === "Network request failed")) {
          return navigate("/error?code=NetworkError");
        }

        errors.forEach((e) => {
          toastr({
            title: t(e.message),
            status: "error",
            isClosable: true,
          });
        });
      });
    }

    Sentry.captureException(error);
  };

  const handleFBError = (error: FirebaseError) => {
    if (
      error.code === "auth/invalid-action-code" ||
      error.code === "auth/expired-action-code"
    ) {
      return navigate("/error?code=InvalidLink");
    }

    if (error.code === "auth/user-disabled") {
      return navigate("/error?code=DisabledAccount");
    }

    if (error.code === "auth/user-not-found") {
      return navigate("/error?code=UserNotFound");
    }

    if (error.code === "auth/network-request-failed") {
      return navigate("/error?code=NetworkError");
    }

    return navigate("/error?code=Unknown");
  };

  return { handle, handleFBError };
};
