import {
  Box,
  Button,
  Heading,
  HStack,
  IconButton,
  List,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/react";

import { EditIcon } from "@chakra-ui/icons";
import { UpcomingTransaction } from "./types";
import { useTranslation } from "react-i18next";
import { groupBy } from "lodash";
import { useCallback, useMemo } from "react";
import { gql, useMutation } from "@apollo/client";
import { useCurrency } from "../utils/useCurrency.ts";

interface UpcomingBillsListProps {
  onEdit: (t: UpcomingTransaction) => void;
  upcomingBills: UpcomingTransaction[];
}

const UpcomingBillItem = ({
  transaction,
  onEdit,
}: {
  transaction: UpcomingTransaction;
  onEdit(t: UpcomingTransaction): void;
}) => {
  const { t } = useTranslation();
  const { format } = useCurrency();
  const [markAsPaid, { loading }] = useMutation(MARK_AS_PAID, {
    refetchQueries: ["AccountTransactions"],
    variables: { transaction_id: transaction.id },
  });

  return (
    <ListItem>
      <HStack justifyContent="space-between" alignItems="center">
        <Box>
          <Text
            fontSize={{ base: "sm", md: "md" }}
            color="casDarkBlue"
            fontWeight="medium"
          >
            {transaction.wording}
          </Text>
        </Box>
        <HStack spacing={2}>
          <Text
            fontSize={{ base: "sm", md: "md" }}
            fontWeight="medium"
            color="casDarkBlue"
          >
            {format(transaction.value)}
          </Text>
          <IconButton
            aria-label={t("upcomingTransactions.editButton")}
            icon={<EditIcon />}
            backgroundColor="white"
            onClick={() => onEdit(transaction)}
          />
        </HStack>
      </HStack>
      <Button
        onClick={() => markAsPaid()}
        isLoading={loading}
        variant={"link"}
        size={"sm"}
      >
        {t("upcomingTransactions.markAsPaid")}
      </Button>
    </ListItem>
  );
};

export const UpcomingBillsList = ({
  onEdit,
  upcomingBills,
}: UpcomingBillsListProps) => {
  const { t, i18n } = useTranslation();

  const transactionsByDate = groupBy(upcomingBills, "date");

  const dates = useMemo(
    () =>
      Object.keys(transactionsByDate).sort(
        (a, b) => new Date(a).getTime() - new Date(b).getTime(),
      ),
    [transactionsByDate],
  );

  const formatDate = useCallback(
    (date: string) => {
      return new Date(date).toLocaleString(i18n.language, {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
    [i18n.language],
  );

  return (
    <>
      {!upcomingBills?.length && (
        <VStack spacing={2}>
          <Heading color={"casBlueGrey"} size={"sm"}>
            {t("upcomingTransactions.noDataTitle")}
          </Heading>
          <Text color={"casBlueGrey"}>
            {t("upcomingTransactions.noDataDescription")}
          </Text>
        </VStack>
      )}
      <List spacing={2} width="100%">
        {dates.map((date) => {
          return (
            <ListItem key={date} width="100%" alignItems={"stretch"}>
              <Box p={2} bg={"gray.100"} flex={1}>
                <Heading color={"casBlueGrey"} size={"sm"}>
                  {formatDate(date)}
                </Heading>
              </Box>
              <List spacing={1}>
                {transactionsByDate[date].map((transaction) => {
                  return (
                    <UpcomingBillItem
                      transaction={transaction}
                      onEdit={onEdit}
                      key={transaction.id}
                    />
                  );
                })}
              </List>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

const MARK_AS_PAID = gql`
  mutation MarkAsPaid($transaction_id: String!) {
    mark_bill_as_paid(transaction_id: $transaction_id) {
      message
    }
  }
`;
