import {
  Box,
  HStack,
  LinkBox,
  Stat,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import ContextualHelp from "./ContextualHelp.tsx";
import { ChevronRightIcon } from "@chakra-ui/icons";

interface KeyNumberProps {
  icon: ReactNode;
  label: string;
  value: number | ReactNode;
  helpText?: string;
  onClick?: () => void;
}

export const KeyNumber = ({
  icon,
  label,
  value,
  helpText,
  onClick,
}: KeyNumberProps) => {
  if (onClick) {
    return (
      <LinkBox
        bg="white"
        px={{ base: 12, md: 6 }}
        py={6}
        borderRadius="xl"
        width="100%"
        onClick={onClick}
        cursor="pointer"
        _hover={{ boxShadow: "md" }}
      >
        <HStack spacing={4}>
          {icon}
          <Stat>
            <HStack alignItems={"center"}>
              <StatLabel
                color="casBlueGrey"
                fontSize={{ base: "sm", md: "md" }}
                fontWeight="regular"
              >
                {label}
              </StatLabel>
              {helpText && <ContextualHelp helpText={helpText} />}
            </HStack>
            <StatNumber color="casDarkBlue" fontSize={{ base: "md", md: "lg" }}>
              {value || "--"}
            </StatNumber>
          </Stat>
          <ChevronRightIcon color="casBlueGrey" boxSize={8} />
        </HStack>
      </LinkBox>
    );
  }

  return (
    <Box
      bg="white"
      px={{ base: 12, md: 6 }}
      py={6}
      borderRadius="xl"
      width="100%"
    >
      <HStack spacing={4}>
        {icon}
        <Stat>
          <HStack alignItems={"center"}>
            <StatLabel
              color="casBlueGrey"
              fontSize={{ base: "sm", md: "md" }}
              fontWeight="regular"
            >
              {label}
            </StatLabel>
            {helpText && <ContextualHelp helpText={helpText} />}
          </HStack>
          <StatNumber color="casDarkBlue" fontSize={{ base: "md", md: "lg" }}>
            {value || "--"}
          </StatNumber>
        </Stat>
      </HStack>
    </Box>
  );
};

export const KeyNumberPlaceholder = ({
  icon,
  label,
  value,
}: KeyNumberProps) => {
  return (
    <Box
      bg="white"
      px={{ base: 12, md: 6 }}
      py={6}
      borderRadius="xl"
      width="100%"
    >
      <HStack spacing={4}>
        {icon}
        <Stat>
          <StatLabel
            color="casBlueGrey"
            fontSize={{ base: "xs", md: "md" }}
            fontWeight="regular"
          >
            {label}
          </StatLabel>
          <StatNumber
            color="casDarkBlue"
            fontSize={{ base: "sm", md: "md" }}
            fontWeight={400}
          >
            {value}
          </StatNumber>
        </Stat>
      </HStack>
    </Box>
  );
};
