import { Icon } from "@chakra-ui/react";
import { theme } from "../../theme/theme";
import { IconProps } from "@chakra-ui/icon";

export const QuestionIcon = (props: IconProps) => (
  <Icon viewBox="0 0 70 70" {...props}>
    <circle cx="35" cy="35" r="35" fill={theme.colors.casLightGrey} />
    <path
      d="M38.5086 36.7424C41.2774 35.3406 43 32.4849 43 29.2907C43 27.1119 42.1603 25.0366 40.631 23.4551C39.1018 21.8702 37.1025 21 35.0001 21C30.5886 21 27 24.7189 27 29.2909H31.0549C31.0549 27.0362 32.824 25.2025 34.9999 25.2025C36.0195 25.2025 37.0024 25.6375 37.7622 26.4282C38.5252 27.219 38.9449 28.2342 38.9449 29.2942C38.9449 30.8687 38.0952 32.2775 36.7293 32.9717C34.4468 34.1216 32.9742 36.6803 32.9742 39.4806V41H37.0291V39.4806C37.0257 38.2722 37.6088 37.1982 38.5084 36.7425L38.5086 36.7424Z"
      fill={theme.colors.casBlueGrey}
    />
    <path
      d="M35.5 43C34.1215 43 33 44.1218 33 45.4935C33 46.8817 34.1248 48 35.5 48C36.8785 48 38 46.8782 38 45.4935C38 44.1218 36.8785 43 35.5 43Z"
      fill={theme.colors.casBlueGrey}
    />
  </Icon>
);
