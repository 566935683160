import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Image,
  Input,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { FirebaseError } from "firebase/app";
import { Link, Navigate } from "react-router-dom";
import { FormEvent } from "react";
import { useAuth } from "./useAuth.ts";
import logo from "../assets/logo.svg";
import { GrGoogle } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import { SplashScreen } from "../SplashScreen/SplashScreen.tsx";
import { handleAuthErrors } from "./authErrors.ts";

interface SignupForm extends HTMLFormControlsCollection {
  email: HTMLInputElement;
  password: HTMLInputElement;
}

interface SignupFormElement extends HTMLFormElement {
  readonly elements: SignupForm;
}

const Signup = () => {
  const toast = useToast();
  const { signup, loginWithGoogle, user, loading } = useAuth();
  const { t } = useTranslation();

  const handleSignup = async (event: FormEvent<SignupFormElement>) => {
    event.preventDefault();
    const formData = event.currentTarget.elements;

    try {
      await signup(formData.email.value, formData.password.value);
    } catch (error) {
      if (error instanceof FirebaseError) {
        toast({
          title: t("auth.loginError"),
          description: t(handleAuthErrors(error)),
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  if (loading) {
    return <SplashScreen />;
  }

  if (user && !loading) {
    return <Navigate to={"/onboarding"} />;
  }

  return (
    <Center w={"100vw"} h={"100vh"} bg="casBackground" overflow={"hidden"}>
      <Box layerStyle={"frame"} p={8}>
        <form onSubmit={handleSignup}>
          <VStack
            spacing={4}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
          >
            <Image
              src={logo}
              h={{ base: 8, md: 10 }}
              mb={8}
              alignSelf={"center"}
            ></Image>
            <FormControl isRequired>
              <FormLabel htmlFor="email">{t("common.email")}</FormLabel>
              <Input id="email" type="email" />
            </FormControl>
            <FormControl isRequired mt={4}>
              <FormLabel htmlFor="password">{t("common.password")}</FormLabel>
              <Input id="password" type="password" />
            </FormControl>
            <Text>
              {t("auth.alreadyHaveAccountQuestion")}{" "}
              <Button as={Link} variant="link" to={"/login"}>
                {t("auth.signIn")}
              </Button>
            </Text>
            <Button type="submit" w={"full"} variant={"primary"}>
              {t("auth.signUp")}
            </Button>
            <Button
              onClick={loginWithGoogle}
              leftIcon={<GrGoogle />}
              w={"full"}
            >
              {t("auth.signUpWithGoogle")}
            </Button>
          </VStack>
        </form>
      </Box>
    </Center>
  );
};

export default Signup;
