import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icon";

export const SettingsIcon = (props: IconProps) => (
  <Icon viewBox="0 0 70 70" {...props}>
    <circle cx="35" cy="35" r="35" fill="#E7EDFF" />
    <g clipPath="url(#clip0_104_47)">
      <path
        d="M49.977 25.2237C49.8494 23.7736 48.0891 23.128 47.0587 24.1585L45.5869 25.6304C45.2578 25.9594 44.7175 25.9648 44.3825 25.6423C44.0348 25.3077 44.0529 24.7312 44.3946 24.3895L45.8422 22.9418C46.8716 21.9124 46.2287 20.1514 44.7774 20.0236C40.833 19.6768 37.7043 23.3452 38.7504 27.2351L20.8322 45.1533C19.7227 46.2628 19.7225 48.0588 20.8322 49.1685C21.9392 50.2754 23.7403 50.2755 24.8474 49.1685L42.7609 31.2548C46.6044 32.2999 50.3275 29.2041 49.977 25.2237Z"
        fill="#396AFF"
      />
      <path
        d="M21.875 26.8824C21.917 26.9652 21.9718 27.0409 22.0375 27.1066L24.581 29.65C24.9242 29.9932 25.4807 29.9932 25.8238 29.65L27.1173 28.3565L29.5875 30.8267L26.9466 33.4676C26.2623 34.1531 26.2629 35.2681 26.9472 35.953L27.2244 36.2301L36.238 27.2171L35.9609 26.9399C35.273 26.2533 34.1631 26.2523 33.4743 26.9399L30.8304 29.5838L28.3602 27.1136L29.6537 25.8201C29.9969 25.4769 29.9969 24.9204 29.6537 24.5772L27.1103 22.0338C27.0439 21.9674 26.9672 21.912 26.8833 21.8699L23.6324 20.2368C23.2938 20.0666 22.8844 20.1327 22.6164 20.4007L20.3951 22.6219C20.1264 22.8906 20.0609 23.3015 20.2326 23.6406L21.875 26.8824Z"
        fill="#396AFF"
      />
      <path
        d="M41.3823 35.1198L35.126 41.3761L42.311 48.5629C44.0394 50.2913 46.8378 50.2918 48.5667 48.5629C50.2917 46.838 50.2917 44.0315 48.5667 42.3071L41.3823 35.1198ZM46.5307 46.5251C46.1897 46.8673 45.6318 46.8689 45.288 46.5251L40.2996 41.5366C39.9562 41.1933 39.9562 40.6367 40.2996 40.2939C40.6429 39.9506 41.1995 39.9506 41.5423 40.2939L46.5307 45.2824C46.874 45.6257 46.874 46.1817 46.5307 46.5251Z"
        fill="#396AFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_104_47">
        <rect
          width="30"
          height="30"
          fill="white"
          transform="translate(20 20)"
        />
      </clipPath>
    </defs>
  </Icon>
);
