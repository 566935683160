import { BoxProps, VStack } from "@chakra-ui/react";
import { ReactNode } from "react";

export const CircleIcon = ({
  icon,
  ...props
}: { icon: ReactNode } & BoxProps) => {
  return (
    <VStack
      boxSize={props.boxSize}
      borderRadius={"full"}
      backgroundColor={props.backgroundColor}
      color={props.color}
      alignItems={"center"}
      justifyContent={"center"}
    >
      {icon}
    </VStack>
  );
};
