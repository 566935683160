import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../Auth/useAuth.ts";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SplashScreen } from "../SplashScreen/SplashScreen.tsx";
import { gql, useMutation } from "@apollo/client";
import errorHandler from "../utils/errorHandler.ts";
import { useError } from "../Error/useError.ts";

export const OnboardingVerifyEmail = () => {
  const [pending, setPending] = useState(true);
  const { verifyCode, user, loading: userLoading } = useAuth();
  const { handle } = useError();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [updateProfile, { loading }] = useMutation(MUTATION, {
    onError: (error) => {
      handle(error);
      navigate("/onboarding");
    },
  });

  const verifyEmail = useCallback(async () => {
    if (userLoading) return;

    if (user?.emailVerified) {
      return navigate("/onboarding");
    }

    if (!searchParams.get("oobCode")) {
      return navigate("/error?code=InvalidLink");
    }

    try {
      await verifyCode(searchParams.get("oobCode") as string);
    } catch (error: unknown) {
      errorHandler.handle({ error });
    } finally {
      setPending(false);
    }

    try {
      await updateProfile({
        variables: {
          user_id: user?.uid,
        },
      });
    } catch (error: unknown) {
      navigate("/onboarding");
    }
  }, [searchParams, user, userLoading, verifyCode, updateProfile, navigate]);

  useEffect(() => {
    (async () => {
      await verifyEmail();
    })();
  }, [searchParams, user, loading]);

  if (pending || loading || userLoading) {
    return <SplashScreen />;
  }

  return null;
};

const MUTATION = gql`
  mutation UpdateUserProfile($user_id: String!) {
    update_profiles(
      where: { user_id: { _eq: $user_id } }
      _set: { onboarding_status: "EMAIL_VERIFIED" }
    ) {
      affected_rows
    }
  }
`;
