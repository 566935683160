import { FirebaseError } from "firebase/app";

export const handleAuthErrors = (error: FirebaseError) => {
  switch (error.code) {
    case "auth/invalid-credential":
      return "auth.invalidCredential";
    case "auth/wrong-password":
      return "auth.wrongPassword";
    case "auth/too-many-requests":
      return "auth.tooManyRequests";
    case "auth/invalid-email":
      return "auth.invalidEmail";
    case "auth/email-already-in-use":
      return "auth.emailInUse";
    case "auth/weak-password":
      return "auth.weakPassword";
    default:
      return "auth.unknownError";
  }
};
