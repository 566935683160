import { Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icon";

export const ExpenseIcon = (props: IconProps) => (
  <Icon viewBox="0 0 70 70" {...props}>
    <circle cx="35" cy="35" r="35" fill="#FFE0EB" />
    <path
      d="M45.1323 20C43.4164 20 42.0254 21.391 42.0254 23.1069V32.3773H47.3604C47.8457 32.3773 48.2393 31.9838 48.2393 31.4984V23.1069C48.2393 21.391 46.8482 20 45.1323 20V20Z"
      fill="#FF82AC"
    />
    <path
      d="M30.6266 42.0898C30.6266 36.7805 34.9456 32.4076 40.3261 32.4223V23.4855C40.3261 22.1486 40.8293 20.9271 41.656 20H26.1541C23.7318 20 21.7607 21.9711 21.7607 24.3934V49.1053C21.7607 49.8959 22.7279 50.2844 23.2748 49.7129L24.3992 48.5387L25.5869 49.727C25.9297 50.0697 26.4863 50.0697 26.8297 49.727L27.9553 48.602L29.092 49.7293C29.4348 50.0691 29.9873 50.0691 30.3301 49.7293L31.485 48.5844L32.6404 49.7293C32.9826 50.0691 33.5346 50.0691 33.8773 49.7299L34.1012 49.5084C31.922 47.6864 30.6266 44.9923 30.6266 42.0898ZM28.8709 27.0898H30.6055V25.3552C30.6055 24.8698 30.999 24.4763 31.4844 24.4763C31.9698 24.4763 32.3633 24.8698 32.3633 25.3552V27.0898H34.0979C34.5833 27.0898 34.9768 27.4834 34.9768 27.9688C34.9768 28.4541 34.5833 28.8477 34.0979 28.8477H32.3633V30.5823C32.3633 31.0677 31.9698 31.4612 31.4844 31.4612C30.999 31.4612 30.6055 31.0677 30.6055 30.5823V28.8477H28.8709C28.3855 28.8477 27.992 28.4541 27.992 27.9688C27.992 27.4834 28.3855 27.0898 28.8709 27.0898ZM26.2109 33.2451H32.1289C32.6143 33.2451 33.0078 33.6386 33.0078 34.124C33.0078 34.6094 32.6143 35.0029 32.1289 35.0029H26.2109C25.7255 35.0029 25.332 34.6094 25.332 34.124C25.332 33.6386 25.7255 33.2451 26.2109 33.2451ZM29.1435 42.0283H26.205C25.7196 42.0283 25.3261 41.6348 25.3261 41.1494C25.3261 40.664 25.7196 40.2705 26.205 40.2705H29.1435C29.6289 40.2705 30.0224 40.664 30.0224 41.1494C30.0224 41.6348 29.6289 42.0283 29.1435 42.0283ZM29.7295 38.5186H26.2079C25.7225 38.5186 25.329 38.125 25.329 37.6396C25.329 37.1543 25.7225 36.7607 26.2079 36.7607H29.7295C30.2149 36.7607 30.6084 37.1543 30.6084 37.6396C30.6084 38.125 30.2149 38.5186 29.7295 38.5186Z"
      fill="#FF82AC"
    />
    <path
      d="M40.2947 34.1797C35.933 34.1797 32.3845 37.7282 32.3845 42.0898C32.3845 46.4515 35.933 50 40.2947 50C44.6563 50 48.2048 46.4515 48.2048 42.0898C48.2048 37.7282 44.6563 34.1797 40.2947 34.1797ZM41.1763 45.4747V45.5583C41.1763 46.0437 40.7828 46.4372 40.2974 46.4372C39.812 46.4372 39.4185 46.0437 39.4185 45.5583V45.5434C39.0248 45.4496 38.6596 45.2945 38.3699 45.105C37.9637 44.8392 37.8499 44.2946 38.1156 43.8883C38.3814 43.482 38.926 43.3682 39.3323 43.634C39.692 43.8693 40.285 43.9587 40.5764 43.8216C40.7652 43.7327 40.9114 43.543 40.9317 43.3603C40.9752 42.9712 40.3655 42.7541 40.0015 42.6103C39.4506 42.3927 38.7649 42.1219 38.3669 41.4033C37.7995 40.3791 38.2965 39.0287 39.4185 38.5669V38.5227C39.4185 38.0373 39.812 37.6437 40.2974 37.6437C40.778 37.6437 41.168 38.0296 41.1756 38.5084C41.492 38.6073 41.7834 38.7655 42.0167 38.9807C42.3736 39.3098 42.396 39.8658 42.0669 40.2226C41.7378 40.5795 41.1817 40.6019 40.825 40.2728C40.6804 40.1394 40.2348 40.0856 40.0411 40.2156C39.9109 40.3031 39.8599 40.4709 39.9045 40.5514C40.0901 40.8865 41.0622 41.0013 41.7978 41.5746C43.3007 42.7457 42.7778 44.8604 41.1763 45.4747Z"
      fill="#FF82AC"
    />
  </Icon>
);
