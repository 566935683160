import { Box, Heading } from "@chakra-ui/react";
import { UpdatedAt } from "../components/UpdatedAt.tsx";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { gql } from "@apollo/client";
import {
  AccountsLastUpdateFragment,
  ProfileFragment,
} from "../generated/graphql.ts";

interface DashboardHeadingProps {
  data: {
    accounts: AccountsLastUpdateFragment;
    profile: ProfileFragment;
  };
}

export const DashboardHeading = ({ data }: DashboardHeadingProps) => {
  const { t } = useTranslation();
  const updated_at = useMemo(
    () => data?.accounts?.aggregate?.max?.updated_at,
    [data],
  );
  const welcomeMsg = useMemo(() => {
    if (!data?.profile) return t("dashboard.welcome", { appName: "Cashbah" });

    return data.profile.first_name
      ? t("dashboard.welcomeUser", { name: data.profile.first_name })
      : t("dashboard.welcome", { appName: "Cashbah" });
  }, [data, t]);

  if (!data) {
    return <DashboardHeadingPlaceholder />;
  }

  return (
    <Box>
      <Heading size={"lg"} color={"casDarkBlue"}>
        {welcomeMsg}
      </Heading>
      {!!updated_at && <UpdatedAt date={updated_at} />}
    </Box>
  );
};

const DashboardHeadingPlaceholder = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Heading size={"lg"} color={"casDarkBlue"}>
        {t("dashboard.welcome", { appName: "Cashbah" })}
      </Heading>
    </Box>
  );
};

DashboardHeading.fragments = {
  entry: gql`
    fragment AccountsLastUpdate on accounts_new_aggregate {
      aggregate {
        max {
          updated_at
        }
      }
    }
  `,
};
