import { ReactNode } from "react";
import { Box, BoxProps, Container } from "@chakra-ui/react";
import { Header } from "../Header/Header";

interface PageProps extends BoxProps {
  children: ReactNode;
}

export const Page = ({ children, ...props }: PageProps) => {
  return (
    <Box maxW="100vw" minH="100vh" bg="casBackground">
      <Header />
      <Container maxW={props.maxW || "container.xl"} pb={8}>
        {children}
      </Container>
    </Box>
  );
};
