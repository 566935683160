export enum AppError {
  InvalidLink = "InvalidLink",
  DisabledAccount = "DisabledAccount",
  UserNotFound = "UserNotFound",
  Unknown = "Unknown",
  NetworkError = "NetworkError",
}

export const AppErrorMessages: Record<
  AppError,
  {
    title: string;
    message: string;
  }
> = {
  [AppError.InvalidLink]: {
    title: "error.title",
    message: "error.invalidLink",
  },
  [AppError.DisabledAccount]: {
    title: "error.title",
    message: "error.disabledAccount",
  },
  [AppError.UserNotFound]: {
    title: "error.title",
    message: "error.userNotFound",
  },
  [AppError.Unknown]: {
    title: "error.title",
    message: "error.unknown",
  },
  [AppError.NetworkError]: {
    title: "error.networkErrorTitle",
    message: "error.networkErrorDescription",
  },
};
