import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { MdSettings, MdWarning } from "react-icons/md";
import { Currency } from "../../components/Currency.tsx";
import {
  accountTypeToCoreAccount,
  coreAccounts,
  NormalizedAccountType,
} from "../../Accounts/types.ts";
import { useTranslation } from "react-i18next";
import { NetWorthOverviewFragment } from "../../generated/graphql.ts";
import { camelCase, sumBy } from "lodash";
import { useMemo } from "react";
import { differenceInDays } from "date-fns";

interface NetWorthOverviewViewProps {
  // the assets total balance
  assetsBalance: number;
  // the liabilities total balance
  liabilitiesBalance: number;
  // the assets accounts
  assets: NetWorthOverviewFragment[];
  // the liabilities accounts
  liabilities: NetWorthOverviewFragment[];
}

const NetWorthOverviewView = ({
  assetsBalance,
  assets,
  liabilitiesBalance,
  liabilities,
}: NetWorthOverviewViewProps) => {
  const { t } = useTranslation();

  const accountsByType = useMemo(() => {
    return coreAccounts.reduce(
      (acc, type) => {
        acc[type] = assets.filter((account) =>
          accountTypeToCoreAccount[type].includes(
            account.type as NormalizedAccountType,
          ),
        );
        return acc;
      },
      {} as Record<NormalizedAccountType, NetWorthOverviewFragment[]>,
    );
  }, [assets]);

  return (
    <VStack
      layerStyle={"frame"}
      spacing={2}
      width="100%"
      alignItems={"stretch"}
    >
      <Button
        as={Link}
        to={"/settings?tab=accounts"}
        variant={"link"}
        leftIcon={<MdSettings />}
        size={"sm"}
        alignSelf={"flex-end"}
      >
        {t("settings.manageAccounts")}
      </Button>
      <HStack justifyContent={"space-between"} bg={"gray.100"} p={[2, 4]}>
        <Text>{t("common.assets")}</Text>
        <Currency value={assetsBalance} />
      </HStack>
      <Box pl={4}>
        {assetsBalance === 0 ? (
          <Text
            fontSize={{ base: "sm", md: "md" }}
            color="casBlueGrey"
            fontWeight="medium"
            noOfLines={1}
            py={4}
          >
            {t("netWorthOverview.noAssets")}
          </Text>
        ) : (
          coreAccounts.map((type) => {
            {
              if (!accountsByType[type]?.length) return null;
              if (accountsByType[type].length === 1)
                return (
                  <SingleAccount
                    type={type}
                    key={type}
                    account={accountsByType[type][0]}
                  />
                );
              return (
                <AccountsGroup
                  type={type}
                  accounts={accountsByType[type]}
                  key={type}
                />
              );
            }
          })
        )}
      </Box>
      <HStack justifyContent={"space-between"} bg={"gray.100"} p={[2, 4]}>
        <Text>{t("common.liabilities")}</Text>
        <Currency value={liabilitiesBalance} />
      </HStack>
      <Box pl={4}>
        {liabilitiesBalance === 0 ? (
          <Text
            fontSize={{ base: "sm", md: "md" }}
            color="casBlueGrey"
            fontWeight="medium"
            noOfLines={1}
            py={4}
          >
            {t("netWorthOverview.noLiabilities")} <span aria-hidden>😎</span>
          </Text>
        ) : (
          liabilities.map((account) => (
            <SingleAccount
              type={account.type as NormalizedAccountType}
              key={account.id}
              account={account}
            />
          ))
        )}
      </Box>
    </VStack>
  );
};

interface SingleAccountProps {
  type: NormalizedAccountType;
  account: NetWorthOverviewFragment;
}

const SingleAccount = ({ type, account }: SingleAccountProps) => {
  const { t } = useTranslation();

  const institution = account?.institution as { name: string; id: string };

  return (
    <LinkBox
      color="casDarkBlue"
      transition={"color 0.2s ease-in-out"}
      _hover={{ color: "casBlue" }}
      cursor={"pointer"}
      py={2}
    >
      <LinkOverlay
        as={Link}
        to={
          account.connection_id
            ? `/accounts?account_id=${account.id}`
            : `/offline-accounts/${account.id}`
        }
      >
        <HStack
          spacing={4}
          justifyContent="space-between"
          width="full"
          alignItems={"flex-start"}
        >
          <Box>
            <HStack spacing={1} alignItems={"center"}>
              <Text
                fontSize={{ base: "sm", md: "md" }}
                fontWeight="medium"
                noOfLines={1}
              >
                {t(`accountTypes.${camelCase(type)}.name`)}
              </Text>
              <OutOfDate
                name={account.name}
                lastUpdated={new Date(account.updated_at)}
              />
            </HStack>
            {!!institution && (
              <Text fontSize={{ base: "xs", md: "sm" }} color="casBlueGrey">
                {institution.name}
              </Text>
            )}
          </Box>
          <Text
            fontSize={{ base: "md", md: "lg" }}
            fontWeight="medium"
            color={account.balance < 0 ? "casRed" : "casGreen"}
          >
            <Currency value={account.balance} />
          </Text>
        </HStack>
      </LinkOverlay>
    </LinkBox>
  );
};

interface AccountsGroupProps {
  type: NormalizedAccountType;
  accounts: NetWorthOverviewFragment[];
}

const AccountsGroup = ({ type, accounts }: AccountsGroupProps) => {
  const { t } = useTranslation();
  const balance = useMemo(
    () => sumBy(accounts, (account) => account.balance),
    [accounts],
  );

  return (
    <Accordion allowToggle>
      <AccordionItem border="none">
        <HStack
          as={AccordionButton}
          width="100%"
          justifyContent="space-between"
          borderBlockEnd={"1px solid"}
          _last={{ border: "none" }}
          borderColor={"border"}
          transition={"color 0.2s ease-in-out"}
          _hover={{ color: "casBlue", bg: "transparent" }}
          cursor={"pointer"}
          borderRadius={"sm"}
          color="casDarkBlue"
          px={0}
          py={2}
        >
          <VStack alignItems={"stretch"} spacing={0} w={"full"}>
            <HStack spacing={2} justifyContent={"space-between"}>
              <Text
                fontSize={{ base: "sm", md: "md" }}
                fontWeight="medium"
                noOfLines={1}
              >
                {t(`accountTypes.${camelCase(type)}.name`)}
              </Text>
              <Text
                fontSize={{ base: "md", md: "lg" }}
                fontWeight="medium"
                color={balance < 0 ? "casRed" : "casGreen"}
              >
                <Currency value={balance} />
              </Text>
            </HStack>
            <HStack justifyContent={"space-between"}>
              <Text
                fontSize={{ base: "xs", md: "sm" }}
                color={"casBlueGrey"}
                noOfLines={1}
              >
                {accounts.length}{" "}
                {t(`common.account`, { count: accounts.length })}
              </Text>
              <AccordionIcon alignSelf={"flex-end"} />
            </HStack>
          </VStack>
        </HStack>
        <AccordionPanel>
          {accounts?.length ? (
            accounts.map((account) => (
              <LinkBox
                key={account.id}
                _hover={{ color: "casBlue" }}
                color={"casBlueGrey"}
                transition={"color 0.2s ease-in-out"}
              >
                <LinkOverlay
                  as={Link}
                  to={
                    account.connection_id
                      ? `/accounts/${account.id}`
                      : `/offline-accounts/${account.id}`
                  }
                >
                  <HStack
                    key={account.id}
                    width="100%"
                    justifyContent="space-between"
                    pb={2}
                  >
                    <HStack spacing={1} alignItems={"center"}>
                      <Text
                        fontSize={{ base: "xs", md: "sm" }}
                        fontWeight="medium"
                        noOfLines={1}
                      >
                        {account.name}
                      </Text>
                      <OutOfDate
                        name={account.name}
                        lastUpdated={new Date(account.updated_at)}
                      />
                    </HStack>
                    <Text
                      fontSize={{ base: "xs", md: "sm" }}
                      fontWeight="medium"
                    >
                      <Currency value={account.balance} />
                    </Text>
                  </HStack>
                </LinkOverlay>
              </LinkBox>
            ))
          ) : (
            <Text
              fontSize={{ base: "sm", md: "md" }}
              color="casDarkBlue"
              fontWeight="medium"
              noOfLines={1}
            >
              {t("netWorthOverview.noAccounts")}
            </Text>
          )}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

interface OutOfDateProps {
  name: string;
  lastUpdated: Date;
}

const OutOfDate = ({ name, lastUpdated }: OutOfDateProps) => {
  const { t } = useTranslation();
  const diff = differenceInDays(new Date(), lastUpdated);

  if (diff < 30) return null;

  return (
    <Icon
      as={MdWarning}
      color={"orange.400"}
      aria-label={t("account.outOfDate", { name })}
      title={t("account.outOfDate", { name })}
    />
  );
};

export default NetWorthOverviewView;
