import { ControllerRenderProps } from "react-hook-form";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { TransactionCategory } from "../Transactions/types";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

export const CategorySelector = ({ ...field }: ControllerRenderProps) => {
  const { t } = useTranslation();

  const categories = useMemo(
    () => [
      {
        label: t("category.needs"),
        value: TransactionCategory.needs,
      },
      {
        label: t("category.wants"),
        value: TransactionCategory.wants,
      },
      {
        label: t("category.goals"),
        value: TransactionCategory.goals,
      },
    ],
    [t],
  );
  return (
    <ButtonGroup variant={"outline"} colorScheme={"blue"}>
      {categories.map((category) => (
        <Button
          key={category.value}
          onClick={() => {
            field.onChange(category.value);
          }}
          isActive={field.value === category.value}
        >
          {category.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};
