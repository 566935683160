import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
import { selectAnatomy } from "@chakra-ui/anatomy";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    fontSize: "1rem",
    color: "casDarkBlue",
    borderRadius: "2xl",
    _placeholder: {
      color: "casBlueGrey",
    },
  },
});

export const Select = defineMultiStyleConfig({ baseStyle });
