import { ReactNode } from "react";
import { useAuth } from "./useAuth.ts";
import { Navigate } from "react-router-dom";
import { SplashScreen } from "../SplashScreen/SplashScreen.tsx";

interface ProtectedRouteProps {
  children: ReactNode;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <SplashScreen />;
  }

  if (!user && !loading) {
    return <Navigate to={"/login"} />;
  }

  return <>{children}</>;
};
