import { Center, Container } from "@chakra-ui/react";
import bg from "../assets/header_bg_light.svg";

interface OnboardingLayoutProps {
  children: React.ReactNode;
}

export const OnboardingLayout = ({ children }: OnboardingLayoutProps) => {
  return (
    <Center
      h={{ md: "100vh" }}
      w={{ md: "100vw" }}
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <Container as={"main"}>{children}</Container>
    </Center>
  );
};
