import { Box, Center, CloseButton, Container, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import bg from "../assets/header_bg_light.svg";
import { OnlineAccountForm } from "./OnlineAccountForm.tsx";

export const OnlineAccountDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const redirectUrl = "settings?tab=accounts";

  return (
    <Center
      w={"100vw"}
      h={"100vh"}
      overflow={"scroll"}
      sx={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <Container
        as={"main"}
        maxW={"container.sm"}
        boxShadow={"lg"}
        backgroundColor={"white"}
        p={8}
        borderRadius={"2xl"}
        position={"relative"}
      >
        <CloseButton
          onClick={() => navigate("/")}
          position={"absolute"}
          right={2}
          top={2}
        />
        <Box mb={8}>
          <Text textStyle={"sectionTitle"} lineHeight={2}>
            {t("addAccount.addOnlineAccountTitle")}
          </Text>
          <Text fontSize={"sm"} color={"casBlueGrey"}>
            {t("addAccount.addOnlineAccountDescription")}
          </Text>
        </Box>
        <OnlineAccountForm redirect={redirectUrl} />
      </Container>
    </Center>
  );
};
