import { enUS, fr } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import formatRelative from "date-fns/formatRelative";
import { utcToZonedTime } from "date-fns-tz";

import { Text } from "@chakra-ui/react";
import { differenceInDays } from "date-fns";
import { useProfile } from "../User/useProfile.tsx";

interface UpdatedAtProps {
  date: string;
}

const toLocale: Record<string, Locale> = {
  fr: fr,
  en: enUS,
  "fr-FR": fr,
  "en-US": fr,
};

export const UpdatedAt = ({ date }: UpdatedAtProps) => {
  const { timeZone: tz } = useProfile();
  const { t, i18n } = useTranslation();
  const locale = toLocale[i18n.language];
  const formattedDate = formatRelative(
    utcToZonedTime(new Date(date), tz),
    utcToZonedTime(new Date(), tz),
    { locale },
  );
  const diff = Math.abs(differenceInDays(new Date(), new Date(date)));
  return (
    <Text fontSize="sm" color="gray.500" textAlign={"left"}>
      {t(diff > 6 ? "common.lastUpdatedAt" : "common.lastUpdated", {
        date: formattedDate,
      })}
    </Text>
  );
};
