import { useCombobox } from "downshift";
import { useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  forwardRef,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Spinner,
  Text,
} from "@chakra-ui/react";
import {
  Institution,
  useGetInstitutions,
  useSaveInstitution,
} from "../Accounts/useInstitutions";
import { ControllerRenderProps } from "react-hook-form";
import { AddIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { take } from "lodash";
import defaultInstitutionLogo from "../assets/logo_short.png";
import { useTranslation } from "react-i18next";

export const SelectInstitutions = ({ ...field }: ControllerRenderProps) => {
  const { t } = useTranslation();
  const [inputItems, setInputItems] = useState<Institution[]>([]);
  const {
    data: institutionsData,
    loading: loadingInstitutions,
    refetch,
  } = useGetInstitutions();
  const institutions = (institutionsData?.institutions as Institution[]) || [];
  const [saveInstitution, { loading: savingInstitution }] =
    useSaveInstitution();
  const items = useMemo(() => {
    if (inputItems.length) return inputItems;
    const selectedInstitution = institutions.find((i) => i.id === field.value);
    if (selectedInstitution) return [selectedInstitution];
    return [];
  }, [inputItems, field, institutions]);

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    inputValue,
    setInputValue,
    toggleMenu,
  } = useCombobox({
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      if (!inputValue) {
        setInputItems(take(institutions, 10));
        return;
      }
      const filteredItems = institutions.filter((item) =>
        item.name.toLowerCase().startsWith(inputValue.toLowerCase()),
      );
      setInputItems(filteredItems);
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) return;
      field.onChange(selectedItem.id);
      toggleMenu();
    },
    itemToString: (item) => {
      return institutions.find((i) => i.id === item)?.name || "";
    },
    selectedItem: field.value,
  });

  const handleAddNewInstitution = async (val: string) => {
    setInputValue(val);
    toggleMenu();
    await saveInstitution({
      variables: {
        institution: {
          name: val,
        },
      },
      onCompleted: (data) => {
        setInputItems([data.insert_institutions_one]);
        field.onChange(data.insert_institutions_one.id);
        refetch();
      },
    });
  };

  useEffect(() => {
    if (!institutions?.length) return;
    setInputValue(institutions.find((i) => i.id === field.value)?.name || "");
  }, [institutions, field.value, setInputValue]);

  return (
    <Box>
      <InputGroup>
        <InputRightElement>
          {savingInstitution ? <Spinner size={"sm"} /> : null}
          {items.length && !savingInstitution ? (
            <ChevronDownIcon boxSize={5} />
          ) : null}
        </InputRightElement>
        <Input
          {...getInputProps()}
          placeholder={t("selectInstitution.placeholder")}
          isDisabled={savingInstitution || loadingInstitutions}
        />
      </InputGroup>
      <BoxWithRef
        border="1px solid gray"
        display={isOpen ? "block" : "none"}
        maxHeight="150px"
        overflowY="auto"
      >
        <List {...getMenuProps()}>
          {items.map((item, index) => (
            <ListItem
              bg={highlightedIndex === index ? "gray.300" : "white"}
              p={2}
              key={item.id}
              {...getItemProps({ item, index })}
            >
              <HStack spacing={4}>
                <Avatar
                  name={item.name}
                  src={item.logo || defaultInstitutionLogo}
                  size={"sm"}
                />
                <Text>{item.name}</Text>
              </HStack>
            </ListItem>
          ))}
          {inputValue && inputItems.length === 0 && (
            <ListItem p={2}>
              <Button
                w={"full"}
                rightIcon={<AddIcon />}
                onClick={() => handleAddNewInstitution(inputValue)}
                variant={"ghost"}
                size={"sm"}
                isDisabled={!inputValue.trim()}
              >
                {inputValue}
              </Button>
            </ListItem>
          )}
        </List>
      </BoxWithRef>
    </Box>
  );
};

const BoxWithRef = forwardRef((props, ref) => <Box ref={ref} {...props} />);
