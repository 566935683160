import {
  IconButton,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { FC } from "react";

interface ContextualHelpProps {
  helpText: string;
}

const ContextualHelp: FC<ContextualHelpProps> = ({ helpText }) => {
  const { t } = useTranslation();
  return (
    <Popover>
      <PopoverTrigger>
        <IconButton
          icon={<InfoOutlineIcon color={"casBlueGrey"} />}
          aria-label={t("common.help")}
          variant={"ghost"}
          isRound
        />
      </PopoverTrigger>
      <PopoverContent p={4} boxShadow={"xs"}>
        <PopoverArrow />
        <PopoverCloseButton />
        <Text>{helpText}</Text>
      </PopoverContent>
    </Popover>
  );
};

export default ContextualHelp;
