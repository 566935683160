import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  nullUpcomingTransaction,
  transactionTypes,
  UpcomingTransaction,
  UpcomingTransactionFormData,
} from "./types";
import { omitBy } from "lodash";
import { format } from "date-fns";
import { useUpdateUpcomingTransaction } from "./useUpdateUpcomingTransaction";
import { useDeleteUpcomingTransaction } from "./useDeleteUpcomingTransaction";
import { RecurrenceFrequency } from "../Transactions/types";
import { useTranslation } from "react-i18next";
import { gql, useMutation } from "@apollo/client";
import {
  AccountHeaderAccountsFragment,
  InsertUpcomingTransactionMutation,
  InsertUpcomingTransactionMutationVariables,
} from "../generated/graphql";
import { MdDelete } from "react-icons/md";
import { parseNumber, toLocaleNumber } from "../utils/utils.ts";
import { useAuth } from "../Auth/useAuth.ts";
import AccountName from "../Accounts/AccountName.tsx";

type UpcomingBillFormProps = {
  transaction?: UpcomingTransaction;
  accounts: AccountHeaderAccountsFragment[];
  onSave(transaction: UpcomingTransactionFormData): void;
  onCancel(): void;
};

const toFormData = (data?: UpcomingTransaction) =>
  ({
    ...nullUpcomingTransaction,
    ...data,
    frequency: data?.frequency || nullUpcomingTransaction.frequency,
    date: data?.date
      ? format(new Date(data?.date as string), "yyyy-MM-dd")
      : "",
  }) as UpcomingTransactionFormData;

export const UpcomingBillForm = ({
  onSave,
  onCancel,
  transaction,
  accounts,
}: UpcomingBillFormProps) => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const [formData, setFormData] = useState(toFormData(transaction));
  const [insertUpcomingTransaction, { loading: inserting }] = useMutation<
    InsertUpcomingTransactionMutation,
    InsertUpcomingTransactionMutationVariables
  >(SAVE_TRANSACTION);
  const { updateUpcomingTransaction, loading: updating } =
    useUpdateUpcomingTransaction();
  const { deleteUpcomingTransaction, loading: deleting } =
    useDeleteUpcomingTransaction();
  const toast = useToast();

  const handleSaveTransaction = async () => {
    if (!formData.id) {
      await insertUpcomingTransaction({
        variables: {
          user_id: user?.uid as string,
          new_account_id: (formData.accountId as string) || accounts[0].id,
          date: formData.date,
          value: parseFloat(formData.value),
          status: "accepted",
          type: formData.type,
          wording: formData.wording,
          frequency: formData.frequency,
        },
        onCompleted: () => {
          onSave(formData);
          toast({
            title: t("upcomingTransactions.saveSuccessTitle"),
            status: "success",
          });
        },
        onError: () =>
          toast({
            title: t("upcomingTransactions.saveErrorTitle"),
            description: t("upcomingTransactions.saveErrorDescription"),
            status: "error",
          }),
        refetchQueries: [
          "AccountTransactions",
          "GetCurrentMonthUpcomingExpenses",
        ],
      });
    } else {
      await updateUpcomingTransaction({
        variables: omitBy(
          {
            ...formData,
            value: parseFloat(formData.value),
          },
          (el) => !el,
        ),
        onCompleted: () => onSave(formData),
        refetchQueries: [
          "AccountTransactions",
          "GetCurrentMonthUpcomingExpenses",
        ],
      });
    }
  };

  const handleDelete = async () => {
    await deleteUpcomingTransaction({
      variables: {
        id: formData.id,
      },
      onCompleted: () => onSave(formData),
      refetchQueries: [
        "AccountTransactions",
        "GetCurrentMonthUpcomingExpenses",
      ],
    });
  };

  const handleChange = (
    prop: keyof UpcomingTransactionFormData,
    value: unknown,
  ) => {
    setFormData((curr) => ({
      ...curr,
      [prop]: value,
    }));
  };

  return (
    <VStack spacing={4} width="100%">
      {formData.id && (
        <IconButton
          alignSelf={"flex-end"}
          icon={<MdDelete />}
          onClick={handleDelete}
          isLoading={deleting}
          colorScheme={"red"}
          aria-label={t("transaction.delete")}
          variant={"outline"}
        />
      )}

      {accounts.length > 1 && (
        <FormControl>
          <FormLabel htmlFor="account">{t("account.self")}</FormLabel>
          <Select
            id="account"
            value={formData.accountId}
            onChange={(e) => handleChange("accountId", e.currentTarget.value)}
          >
            {accounts.map((account) => (
              <option key={account.id} value={account.id}>
                <AccountName account={account} />
              </option>
            ))}
          </Select>
        </FormControl>
      )}

      <FormControl>
        <FormLabel htmlFor="date">{t("transaction.date")}</FormLabel>
        <Input
          id="date"
          type="date"
          value={formData.date}
          onInput={(e) => handleChange("date", e.currentTarget.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="wording">{t("transaction.wording")}</FormLabel>
        <Input
          id="wording"
          type="text"
          value={formData.wording}
          onInput={(e) => handleChange("wording", e.currentTarget.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="value">{t("transaction.amount")}</FormLabel>
        <InputGroup>
          <Input
            id="value"
            type="text"
            inputMode="numeric"
            value={toLocaleNumber(
              formData.value as unknown as number,
              i18n.language,
            )}
            onChange={(e) => handleChange("value", e.target.value)}
            onBlur={(e) => handleChange("value", parseNumber(e.target.value))}
          />
          <InputRightElement aria-label={t("transaction.currency")}>
            <Text size={"md"}>€</Text>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="type">{t("transaction.type.self")}</FormLabel>
        <Select
          value={formData.type}
          onChange={(e) => handleChange("type", e.currentTarget.value)}
        >
          {transactionTypes.map((type) => (
            <option key={type.value} value={type.value}>
              {t(`transaction.type.${type.label}`)}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>{t("common.frequency")}</FormLabel>
        <Select
          value={formData.frequency}
          onChange={(e) =>
            handleChange(
              "frequency",
              e.currentTarget.value as RecurrenceFrequency,
            )
          }
        >
          <option key={0} value="one_off">
            {t("common.oneOff", { context: "female" })}
          </option>
          <option key={1} value="monthly">
            {t("common.monthly", { context: "female" })}
          </option>
          <option key={2} value="yearly">
            {t("common.yearly", { context: "female" })}
          </option>
          <option key={3} value="weekly">
            {t("common.weekly", { context: "female" })}
          </option>
          <option key={4} value="daily">
            {t("common.daily", { context: "female" })}
          </option>
        </Select>
      </FormControl>
      <ButtonGroup alignSelf={"flex-end"}>
        <Button onClick={onCancel}>{t("common.cancel")}</Button>
        <Button
          variant="primary"
          onClick={handleSaveTransaction}
          disabled={deleting || inserting}
          isLoading={updating || inserting}
        >
          {t("common.save")}
        </Button>
      </ButtonGroup>
    </VStack>
  );
};

const SAVE_TRANSACTION = gql`
  mutation InsertUpcomingTransaction(
    $user_id: String!
    $new_account_id: uuid!
    $frequency: recurring_transaction_frequency!
    $date: date!
    $value: numeric!
    $status: recurring_transaction_status!
    $type: String!
    $wording: String!
  ) {
    insert_upcoming_transactions_one(
      object: {
        user_id: $user_id
        new_account_id: $new_account_id
        frequency: $frequency
        date: $date
        value: $value
        status: $status
        type: $type
        wording: $wording
      }
      on_conflict: {
        constraint: upcoming_transactions_pkey
        update_columns: [frequency, date, value, status, type, wording]
      }
    ) {
      id
    }
  }
`;
