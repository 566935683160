import IErrorHandler, { ErrorCallback, ErrorMessage } from "./types";
import i18n from "i18next";
import * as Sentry from "@sentry/react";
import { ApolloError } from "@apollo/client";

class GqlErrorHandler implements IErrorHandler<ApolloError> {
  private getUserFriendlyMessage(errorCode: string): string {
    switch (errorCode) {
      case "access-denied":
        return i18n.t("gqlError.accessDenied");
      case "graphql-parse-failed":
        return i18n.t("gqlError.graphqlParseFailed");
      case "graphql-validation-failed":
        return i18n.t("gqlError.graphqlValidationFailed");
      case "data-exception":
        return i18n.t("gqlError.dataException");
      case "unique-violation":
        return i18n.t("gqlError.uniqueViolation");
      case "foreign-key-violation":
        return i18n.t("gqlError.foreignKeyViolation");
      case "not-null-violation":
        return i18n.t("gqlError.notNullViolation");
      case "check-violation":
        return i18n.t("gqlError.checkViolation");
      case "query-timeout":
        return i18n.t("gqlError.queryTimeout");
      case "network-error":
        return i18n.t("gqlError.networkError");
      default:
        return i18n.t("gqlError.defaultError");
    }
  }

  getPrettyMessage(error: ApolloError): string {
    return this.getUserFriendlyMessage(error.message);
  }

  handle(error: ApolloError, onError: ErrorCallback): void {
    if (error.graphQLErrors) {
      const msg = error.graphQLErrors.reduce((acc, graphQLError) => {
        Sentry.captureException(graphQLError.message);
        const errorCode = graphQLError.extensions?.code as string;
        const errorMessage = this.getUserFriendlyMessage(errorCode);
        acc.push({ message: errorMessage, code: errorCode });
        return acc;
      }, [] as ErrorMessage[]);

      onError(msg);
    }

    if (error.networkError) {
      console.error(`[Network error]: ${error.networkError.message}`);
      Sentry.captureException(error.networkError.message);
      const errorMessage = this.getUserFriendlyMessage("network-error");
      onError([{ message: errorMessage, code: "network-error" }]);
    }
  }
}

export default new GqlErrorHandler();
