import { ArrowBackIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const GoBack = ({ to = "/" }: { to?: string }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Button
      variant={"link"}
      aria-label={t("common.back")}
      role={"link"}
      onClick={() => navigate(to)}
      leftIcon={<ArrowBackIcon />}
    >
      {t("common.back")}
    </Button>
  );
};
