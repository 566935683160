import { Page } from "../Layout/Page/Page";
import { MonthCashflow } from "../Cashflow/MonthCashflow.tsx";
import { Box, Grid, GridItem, Stack, VStack } from "@chakra-ui/react";
import { SpendingDetails } from "../SpendingDetails/SpendingDetails";
import { KeyNumbers } from "../Home/KeyNumbers";
import { GoalTracking } from "../Goals/GoalTracking.tsx";
import { CoachingCorner } from "../Education/CoachingCorner.tsx";
import { DashboardHeading } from "./DashboardHeading.tsx";
import { Wallchart } from "./Wallchart/Wallchart.tsx";
import { gql, useSuspenseQuery } from "@apollo/client";
import { DashboardQuery, GoalProgress } from "../generated/graphql.ts";
import { profileFragments } from "../User/useProfile.tsx";
import { useTranslation } from "react-i18next";
import LastTransactions from "./LastTransactions.tsx";
import { TransactionList } from "../Transactions/TransactionList.tsx";

export const Dashboard = () => {
  const { i18n } = useTranslation();
  const { data } = useSuspenseQuery<DashboardQuery>(QUERY, {
    variables: { locale: i18n.language.substring(0, 2) },
  });

  if (!data) {
    return null;
  }

  return (
    <Page>
      <Grid gap={{ base: 4, md: 6 }} templateColumns="repeat(6, 1fr)">
        <GridItem colSpan={{ base: 6 }} pt={6}>
          <DashboardHeading
            data={{
              accounts: data.accounts_new_aggregate,
              profile: data.profiles[0],
            }}
          />
        </GridItem>
        <GridItem colSpan={{ base: 6 }}>
          <KeyNumbers
            data={{
              accounts_count: data.accounts_new_aggregate,
              numbers: data.user_financial_summary,
            }}
          />
        </GridItem>
        <GridItem colSpan={{ base: 6 }}>
          <Stack
            spacing={4}
            alignItems={"stretch"}
            justifyContent={"stretch"}
            direction={{ base: "column", md: "row" }}
          >
            <Box flex={1}>
              <Wallchart data={data.wallchart_data} />
            </Box>
            <VStack spacing={4}>
              <MonthCashflow
                data={{
                  cashflow_breakdown: data.cashflow_breakdown,
                  accounts_count: data.accounts_new_aggregate,
                }}
              />
              <SpendingDetails
                data={{
                  accountsCount: data.accounts_new_aggregate,
                  expenses_by_category: data.user_month_expenses_breakdown,
                }}
              />
            </VStack>
          </Stack>
        </GridItem>
        <GridItem colSpan={{ base: 6 }}>
          <Stack
            spacing={4}
            alignItems={"stretch"}
            justifyContent={"stretch"}
            direction={{ base: "column", md: "row" }}
          >
            <LastTransactions data={data.transactions} />
            <GoalTracking
              data={{
                goals: data.goals as GoalProgress[],
              }}
            />
          </Stack>
        </GridItem>
        <GridItem colSpan={{ base: 6 }}>
          <CoachingCorner
            data={{
              financial_tips: data.financial_tips,
            }}
          />
        </GridItem>
      </Grid>
    </Page>
  );
};

const QUERY = gql`
  query Dashboard($locale: String!) {
    accounts_new_aggregate {
      ...AccountsLastUpdate
      ...AccountsCount
    }
    user_financial_summary {
      ...KeyNumbers
    }
    wallchart_data(order_by: { month: asc }) {
      ...Wallchart
    }
    cashflow_breakdown: user_cashflow_breakdown {
      ...CashflowBreakdown
    }
    profiles {
      ...Profile
    }
    user_month_expenses_breakdown {
      ...ExpensesByCategory
    }
    goals: goals_progress {
      contributions_total
      id
      name
      progress
      target_amount
      target_date
    }
    financial_tips(
      where: { locale: { _eq: $locale }, published_at: { _is_null: false } }
      order_by: { order: asc }
    ) {
      ...Tip
    }
    transactions: transactions_new(
      where: { deleted_at: { _is_null: true }, date: { _lte: "now()" } }
      order_by: { date: desc }
      limit: 5
    ) {
      ...Transaction
    }
  }
  ${DashboardHeading.fragments.entry}
  ${KeyNumbers.fragments.accountCount}
  ${KeyNumbers.fragments.keyNumbers}
  ${Wallchart.fragments.entry}
  ${MonthCashflow.fragments.breakdown}
  ${profileFragments}
  ${SpendingDetails.fragments.expensesByCategory}
  ${CoachingCorner.fragments.tip}
  ${TransactionList.fragments.entry}
`;
