import { useProfile } from "../User/useProfile.tsx";
import i18n from "i18next";

export const Currency = ({
  value,
  options,
}: {
  value: number;
  options?: Intl.NumberFormatOptions;
}) => {
  const profile = useProfile();

  if (value === undefined || value === null || !profile) {
    return null;
  }

  return (
    <>
      {value.toLocaleString(i18n.language, {
        ...(options || {}),
        style: "currency",
        currency: profile.preferredCurrency,
      })}
    </>
  );
};
