import { gql, useSuspenseQuery } from "@apollo/client";
import { GetUserProfileQuery, ProfileFragment } from "../generated/graphql";

type Profile = {
  id: string;
  userId: string;
  timeZone: string;
  estimatedIncome: number;
  firstName: string;
  lastName: string;
  preferredCurrency: string;
};

const toProfile = (profileFragment?: ProfileFragment): Profile => {
  return {
    id: profileFragment?.id || "",
    firstName: profileFragment?.first_name || "",
    lastName: profileFragment?.last_name || "",
    timeZone: profileFragment?.timezone || "Europe/Paris",
    estimatedIncome: profileFragment?.estimated_monthly_income || 0,
    preferredCurrency: profileFragment?.preferred_currency || "EUR",
  } as Profile;
};

export const useProfile = () => {
  const { data } = useSuspenseQuery<GetUserProfileQuery>(QUERY);

  return toProfile(data?.profiles[0] as ProfileFragment);
};

export const profileFragments = gql`
  fragment Profile on profiles {
    id
    user_id
    first_name
    last_name
    timezone
    estimated_monthly_income
    onboarding_status
    preferred_currency
  }
`;

const QUERY = gql`
  query GetUserProfile {
    profiles {
      ...Profile
    }
  }
  ${profileFragments}
`;
