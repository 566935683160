import {
  Avatar,
  Box,
  Center,
  Heading,
  HStack,
  Img,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  List,
  ListItem,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { ExternalLinkIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Institution,
  useGetOnlineInstitutions,
} from "../Accounts/useInstitutions.tsx";
import logo from "../assets/logo.svg";
import goCardlessLogo from "../assets/gocardless_logo.png";
import { useMemo, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  CreateConnectionMutation,
  CreateConnectionMutationVariables,
} from "../generated/graphql.ts";
import { debounce, take } from "lodash";
import { useTranslation } from "react-i18next";
import { useAuth } from "../Auth/useAuth.ts";

interface OnlineAccountFormProps {
  redirect?: string;
}

export const OnlineAccountForm = ({
  redirect = "/",
}: OnlineAccountFormProps) => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const { data: institutionsData, loading: loadingInstitutions } =
    useGetOnlineInstitutions();
  const [createConnection, { loading: creatingConnection }] = useMutation<
    CreateConnectionMutation,
    CreateConnectionMutationVariables
  >(CREATE_CONNECTION);
  const toast = useToast();
  const [currentSelection, setCurrentSelection] = useState<string | null>(null);
  const institutions = institutionsData?.institutions;

  const filteredInstitutions = useMemo(() => {
    if (!institutions || !searchTerm.trim()) return [];
    return take(
      institutions.filter((i) =>
        i.name.toLowerCase().startsWith(searchTerm.toLowerCase()),
      ),
      10,
    ).sort((a, b) => a.name.localeCompare(b.name));
  }, [institutions, searchTerm]);

  const handleSelectInstitution = async ({ external_id, id }: Institution) => {
    setCurrentSelection(id);
    await createConnection({
      variables: {
        input: {
          institution_id: external_id as string,
          user_id: user?.uid as string,
          success_redirect_uri: `${window.location.origin}/${redirect}`,
          error_redirect_uri: `${window.location.origin}/${redirect}`,
          lang: i18n.language.substring(0, 2),
        },
      },
      onCompleted: (data) => {
        if (!data.create_connection) return;
        setCurrentSelection(null);
        window.location.href = data.create_connection.link;
      },
      onError: () => {
        toast({
          status: "error",
          title: t("settings.linkAccountErrorTitle"),
          description: t("settings.linkAccountErrorDescription"),
        });
        setCurrentSelection(null);
      },
    });
  };

  const handleSearch = debounce((value: string) => {
    setSearchTerm(value);
  }, 100);

  return (
    <VStack alignItems={"stretch"}>
      <Box as={"form"}>
        <InputGroup mb={4}>
          <Input
            type={"search"}
            placeholder={t("addAccount.searchInstitutions")}
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            aria-autocomplete="list"
            aria-controls={"suggestions"}
            aria-expanded={!!filteredInstitutions.length}
            role={"combobox"}
          />
          <InputRightElement>
            {loadingInstitutions ? (
              <Spinner size={"sm"} color={"casBlueGrey"} />
            ) : (
              <SearchIcon color={"casBlueGrey"} />
            )}
          </InputRightElement>
        </InputGroup>

        {!filteredInstitutions.length ? (
          <Center h={"2xs"}>
            <Heading color={"casBlueGrey"} size={"md"}>
              {t("addAccount.addOnlineAccountHelper")}
            </Heading>
          </Center>
        ) : (
          <>
            <List
              h={"2xs"}
              overflowX={"auto"}
              spacing={2}
              cursor={"pointer"}
              id="suggestions"
              role={"listbox"}
              aria-label={t("addAccount.listBoxAriaLabel")}
            >
              {filteredInstitutions.map((institution) => (
                <ListItem
                  key={institution.id}
                  display="flex"
                  alignItems="center"
                  p={4}
                  role={"option"}
                  _hover={{
                    bg: "blue.100",
                    "& .chakra-icon": { display: "block" },
                  }}
                  borderRadius={"md"}
                  transition={"background-color 0.3s ease"}
                  onClick={() =>
                    handleSelectInstitution(institution as Institution)
                  }
                >
                  <HStack
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    w={"full"}
                  >
                    <HStack flex={1}>
                      <Avatar size="xs" src={institution.logo || logo} mr={8} />
                      <Text>{institution.name}</Text>
                    </HStack>
                    {creatingConnection &&
                    currentSelection === institution.id ? (
                      <Spinner size={"sm"} color={"casBlueGrey"} />
                    ) : (
                      <ExternalLinkIcon display={"none"} />
                    )}
                  </HStack>
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Box>
      <HStack as={"footer"} mt={8}>
        <Text fontSize={"sm"} color={"casBlueGrey"}>
          {t("addAccount.securelyPoweredBy")}
        </Text>
        <Link href={"https://gocardless.com/legal/"} isExternal>
          <Img
            src={goCardlessLogo}
            alt={"GoCarless"}
            w={"auto"}
            h={"auto"}
            maxH={16}
            maxW={"auto"}
          />
        </Link>
      </HStack>
    </VStack>
  );
};

const CREATE_CONNECTION = gql`
  mutation CreateConnection($input: CreateConnectionInput!) {
    create_connection(input: $input) {
      link
      id
    }
  }
`;
