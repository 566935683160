import { ComponentWithAs, Icon } from "@chakra-ui/react";
import { IconProps } from "@chakra-ui/icon";

export const BalanceIcon: ComponentWithAs<"svg", IconProps> = (
  props: IconProps,
) => (
  <Icon viewBox="0 0 70 70" {...props}>
    <circle cx="35" cy="35" r="35" fill="#FFF5D9" />
    <g clipPath="url(#clip0_152_662)">
      <path
        d="M29.9901 25.625H40.0101L42.1726 23.895C42.9089 23.3075 43.1839 22.3538 42.8726 21.4638C42.5614 20.5737 41.7526 20 40.8126 20H29.1876C28.2476 20 27.4389 20.575 27.1276 21.4638C26.8164 22.3525 27.0914 23.3075 27.8264 23.8938L29.9901 25.625Z"
        fill="#FFBB38"
      />
      <path
        d="M40.1813 27.5H29.8188C26.405 30.8512 23.75 36.7312 23.75 41.5625C23.75 45.7612 25.9725 50 30.9375 50H39.375C43.6162 50 46.25 46.7663 46.25 41.5625C46.25 36.7312 43.595 30.8512 40.1813 27.5ZM34.525 37.8125H35.475C36.9363 37.8125 38.125 39.0013 38.125 40.4625C38.125 41.7763 37.1775 42.8575 35.9375 43.0775V44.0613C35.9375 44.5788 35.5175 44.9988 35 44.9988C34.4825 44.9988 34.0625 44.5788 34.0625 44.0613V43.125H32.8125C32.295 43.125 31.875 42.705 31.875 42.1875C31.875 41.67 32.295 41.25 32.8125 41.25H35.475C35.9025 41.25 36.25 40.9025 36.25 40.475C36.25 40.035 35.9025 39.6875 35.475 39.6875H34.525C33.0637 39.6875 31.875 38.4988 31.875 37.0375C31.875 35.7238 32.8225 34.6425 34.0625 34.4225V33.4375C34.0625 32.92 34.4825 32.5 35 32.5C35.5175 32.5 35.9375 32.92 35.9375 33.4375V34.375H37.1875C37.705 34.375 38.125 34.795 38.125 35.3125C38.125 35.83 37.705 36.25 37.1875 36.25H34.525C34.0975 36.25 33.75 36.5975 33.75 37.025C33.75 37.465 34.0975 37.8125 34.525 37.8125V37.8125Z"
        fill="#FFBB38"
      />
    </g>
    <defs>
      <clipPath id="clip0_152_662">
        <rect
          width="30"
          height="30"
          fill="white"
          transform="translate(20 20)"
        />
      </clipPath>
    </defs>
  </Icon>
);
