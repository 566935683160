import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { MdEuro } from "react-icons/md";
import { Controller, FieldValues, useForm } from "react-hook-form";

import { Link, useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { InsertUserProfileMutation } from "../generated/graphql";
import gqlErrorHandler from "../utils/gqlErrorHandler.ts";
import { parseNumber, toLocaleNumber } from "../utils/utils.ts";

interface FormData {
  fullName: string;
  monthlyIncome: number;
}

export const OnboardingProfileSetup = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [saveProfile, { loading }] = useMutation<InsertUserProfileMutation>(
    query,
    {
      refetchQueries: ["Profiles"],
    },
  );
  const toast = useToast();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>();

  const onSubmit = async (formData: FieldValues) => {
    // Do something with the profile data
    await saveProfile({
      variables: {
        profile: {
          full_name: formData.fullName,
          estimated_monthly_income: formData.monthlyIncome,
          onboarding_status: "BUDGET_SETUP",
        },
      },
      onError(error) {
        gqlErrorHandler.handle(error, (errors) => {
          errors.forEach((error) => {
            toast({
              title: error.message,
              status: "error",
              isClosable: true,
            });
          });
        });
      },
    });
    navigate("../");
  };

  return (
    <VStack
      spacing={2}
      alignItems="stretch"
      w={"container.md"}
      layerStyle={"frame"}
    >
      <VStack alignItems={"stretch"} spacing={2}>
        <Text textStyle="sectionTitle">{t("profileSetup.title")}</Text>
        <Text textStyle="sectionSubtitle">{t("profileSetup.subtitle")}</Text>
      </VStack>

      <VStack
        as={"form"}
        onSubmit={handleSubmit(onSubmit)}
        spacing={6}
        alignItems={"flex-start"}
      >
        <FormControl isInvalid={!!errors.fullName}>
          <FormLabel>{t("profileSetup.formFullNameLabel")}</FormLabel>
          <Input
            type="text"
            placeholder={t("profileSetup.formFullNamePlaceholder")}
            {...register("fullName", { required: t("common.fieldRequired") })}
          />
          <FormErrorMessage>{errors.monthlyIncome?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.monthlyIncome}>
          <FormLabel>{t("profileSetup.formMonthlyIncomeLabel")}</FormLabel>
          <InputGroup>
            <Controller
              render={({ field }) => (
                <Input
                  {...field}
                  inputMode={"numeric"}
                  value={toLocaleNumber(field.value, i18n.language)}
                  onBlur={(e) => field.onChange(parseNumber(e.target.value))}
                />
              )}
              name={"monthlyIncome"}
              control={control}
              rules={{
                min: {
                  value: 0,
                  message: t("common.minValueError", { minValue: 0 }),
                },
              }}
            />
            <InputRightElement>
              <MdEuro />
            </InputRightElement>
          </InputGroup>
          <FormHelperText>
            {t("profileSetup.formMonthlyIncomeHelpText")}
          </FormHelperText>
          <FormErrorMessage>{errors.monthlyIncome?.message}</FormErrorMessage>
        </FormControl>

        <ButtonGroup>
          <Button
            as={Link}
            variant={"outline"}
            colorScheme={"blue"}
            to={"/onboarding"}
          >
            {t("common.cancel")}
          </Button>
          <Button
            type="submit"
            variant={"primary"}
            isDisabled={!isValid}
            isLoading={loading}
          >
            {t("profileSetup.formSubmitButton")}
          </Button>
        </ButtonGroup>
      </VStack>
    </VStack>
  );
};

const query = gql`
  mutation InsertUserProfile($profile: profiles_insert_input!) {
    insert_profiles_one(
      object: $profile
      on_conflict: {
        update_columns: [full_name, estimated_monthly_income, onboarding_status]
        constraint: profiles_user_id_key
      }
    ) {
      onboarding_status
      full_name
      estimated_monthly_income
    }
  }
`;
