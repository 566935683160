import { Outlet, useSearchParams } from "react-router-dom";
import { addMonths, endOfMonth, startOfMonth, subMonths } from "date-fns";
import { Page } from "../Layout/Page/Page";
import { Box, Grid, GridItem, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { TransactionList } from "./TransactionList";
import { UpcomingBills } from "../UpcomingBills/UpcomingBills";
import { AccountHeader } from "./AccountHeader";
import { CalendarNavigation } from "./CalendarNavigation";
import { GoBack } from "../components/GoBack.tsx";
import { gql, useSuspenseQuery } from "@apollo/client";
import {
  AccountHeaderAccountsFragment,
  AccountListQuery,
  AccountTransactionsQuery,
  BalancesAggregateFragment,
  TransactionsAggregateFragment,
  UpcomingBillsFragment,
} from "../generated/graphql";
import { AccountStats } from "./AccountStats";
import { useProfile } from "../User/useProfile";
import { formatInTimeZone } from "date-fns-tz";
import { SplashScreen } from "../SplashScreen/SplashScreen.tsx";

export const AccountTransactions = () => {
  const [now, setNow] = useState(new Date());
  const { timeZone } = useProfile();
  const interval = { start: startOfMonth(now), end: endOfMonth(now) };
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [searchParams] = useSearchParams();

  const { data: accountsRes } = useSuspenseQuery<AccountListQuery>(ACCOUNTS);

  const { data: transactionsRes } = useSuspenseQuery<AccountTransactionsQuery>(
    ACCOUNT_TRANSACTION_QUERY,
    {
      variables: {
        account_ids: selectedAccounts,
        min_date: formatInTimeZone(interval.start, timeZone, "yyyy-MM-dd"),
        max_date: formatInTimeZone(interval.end, timeZone, "yyyy-MM-dd"),
      },
      skip: !timeZone || !selectedAccounts.length,
    },
  );

  useEffect(() => {
    // set selected account from search params
    if (searchParams.has("account_id")) {
      const account = searchParams.get("account_id");
      setSelectedAccounts([account as string]);
    }
  }, [searchParams]);

  useEffect(() => {
    // remove account_id from search params if more than one account is selected
    if (selectedAccounts.length > 1) {
      searchParams.delete("account_id");
    }
  }, [selectedAccounts]);

  useEffect(() => {
    // set selected accounts from accounts query
    setSelectedAccounts(accountsRes.accounts.map((a) => a.id) || []);
  }, [accountsRes?.accounts]);

  const handleNext = async () => {
    setNow(addMonths(now, 1));
  };
  const handlePrev = async () => {
    setNow(subMonths(now, 1));
  };

  const handleFilterAccounts = (ids: string[]) => {
    if (!ids.length) {
      setSelectedAccounts(accountsRes?.accounts.map((a) => a.id) || []);
      return;
    }
    setSelectedAccounts(ids);
  };

  if (!accountsRes || !transactionsRes) {
    return <SplashScreen />;
  }

  return (
    <Page>
      <VStack spacing={4} alignItems="stretch" width="100%" my={6}>
        <Box>
          <GoBack to={"/"} />
        </Box>
        <AccountHeader
          accounts={accountsRes?.accounts as AccountHeaderAccountsFragment[]}
          onSelectedAccounts={handleFilterAccounts}
          selectedAccounts={selectedAccounts}
        />
      </VStack>
      <Grid gap={4} templateColumns="repeat(6, 1fr)">
        <GridItem colSpan={{ base: 6, md: 4 }}>
          <VStack spacing={4} alignItems="stretch" width="100%">
            <AccountStats
              data={{
                balance: transactionsRes?.balances as BalancesAggregateFragment,
                income:
                  transactionsRes?.income as TransactionsAggregateFragment,
                expenses:
                  transactionsRes?.expenses as TransactionsAggregateFragment,
              }}
            />
            <Box layerStyle={"frame"} width="100%">
              <CalendarNavigation
                now={now}
                onNext={handleNext}
                onPrev={handlePrev}
              />
              <TransactionList
                transactions={transactionsRes?.transactions || []}
              />
              <Outlet />
            </Box>
          </VStack>
        </GridItem>
        <GridItem colSpan={{ base: 6, md: 2 }}>
          <UpcomingBills
            upcomingBills={
              transactionsRes?.month_upcoming_transactions as UpcomingBillsFragment[]
            }
            accounts={accountsRes?.accounts as AccountHeaderAccountsFragment[]}
          />
        </GridItem>
      </Grid>
    </Page>
  );
};

const ACCOUNTS = gql`
  query AccountList {
    accounts: accounts_new(
      where: { type: { _eq: "checking" } }
      order_by: { name: asc }
    ) {
      ...AccountHeaderAccounts
    }
  }
  ${AccountHeader.fragments.accounts}
`;

const ACCOUNT_TRANSACTION_QUERY = gql`
  query AccountTransactions(
    $min_date: date!
    $max_date: date!
    $account_ids: [uuid!]
  ) {
    month_upcoming_transactions {
      ...UpcomingBills
    }
    transactions: transactions_new(
      where: {
        date: { _gte: $min_date, _lte: $max_date }
        account_id: { _in: $account_ids }
      }
      order_by: { date: desc }
    ) {
      ...Transaction
    }

    expenses: transactions_new_aggregate(
      where: {
        value: { _lt: 0 }
        category_id: { _neq: 4 }
        date: { _gte: $min_date, _lte: $max_date }
      }
    ) {
      aggregate {
        sum {
          value
        }
      }
    }
    income: transactions_new_aggregate(
      where: {
        _or: [{ category_id: { _is_null: true } }, { category_id: { _neq: 4 } }]
        value: { _gt: 0 }
        date: { _gte: $min_date, _lte: $max_date }
        account_id: { _in: $account_ids }
      }
    ) {
      aggregate {
        sum {
          value
        }
      }
    }
    balances: accounts_new_aggregate(
      where: { type: { _eq: "checking" }, id: { _in: $account_ids } }
    ) {
      aggregate {
        sum {
          balance
        }
      }
    }
  }
  ${TransactionList.fragments.entry}
  ${UpcomingBills.fragments.transactions}
  ${AccountStats.fragments.transactions_agg}
  ${AccountStats.fragments.balance_agg}
`;
