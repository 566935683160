import { useSearchParams } from "react-router-dom";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Center,
  Container,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AppError, AppErrorMessages } from "./errors.ts";

export const ErrorPage = ({ errorCode }: { errorCode?: string }) => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code") || errorCode || AppError.Unknown;
  const { t } = useTranslation();
  const errorMessage = t(
    AppErrorMessages[code as AppError]?.message ||
      AppErrorMessages[AppError.Unknown].message,
  );

  return (
    <Center h={{ md: "100vh" }} w={{ md: "100vw" }}>
      <Container as={"main"}>
        <Alert
          status="error"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            {t("error.title")}
          </AlertTitle>
          <AlertDescription maxWidth="sm">{errorMessage}</AlertDescription>
        </Alert>
      </Container>
    </Center>
  );
};
