import { gql, useMutation, useQuery } from "@apollo/client";
import {
  InstitutionsQuery,
  OnlineInstitutionsQuery,
} from "../generated/graphql";

export type Institution = {
  id: string;
  name: string;
  logo: string;
  external_id: string;
};

export const useGetInstitutions = () => {
  return useQuery<InstitutionsQuery>(INSTITUTIONS);
};

export const useGetOnlineInstitutions = () => {
  return useQuery<OnlineInstitutionsQuery>(ONLINE_INSTITUTIONS);
};

export const useSaveInstitution = () => {
  return useMutation(SAVE_INSTITUTION);
};

const INSTITUTIONS = gql`
  query Institutions {
    institutions(order_by: { name: asc }) {
      id
      external_id
      name
      logo
    }
  }
`;

const ONLINE_INSTITUTIONS = gql`
  query OnlineInstitutions {
    institutions(
      where: { provider_id: { _eq: "gocardless" } }
      order_by: { name: asc }
    ) {
      id
      external_id
      name
      logo
    }
  }
`;

const SAVE_INSTITUTION = gql`
  mutation SaveInstitution($institution: institutions_insert_input!) {
    insert_institutions_one(object: $institution) {
      id
      name
      logo
      external_id
    }
  }
`;
