import {
  Box,
  Center,
  Grid,
  GridItem,
  Heading,
  Icon,
  LinkBox,
  LinkOverlay,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";

import { ShoppingIcon } from "../components/icons/ShoppingIcon";
import { ExpenseIcon } from "../components/icons/ExpenseIcon";
import { SavingsIcon } from "../components/icons/SavingsIcon";
import { SettingsIcon } from "../components/icons/SettingsIcon";
import { QuestionIcon } from "../components/icons/QuestionIcon";
import { IncomeIcon } from "../components/icons/IncomeIcon";
import { MdHourglassBottom } from "react-icons/md";
import { CircleIcon } from "../components/CircleIcon";
import { theme } from "../theme/theme";
import { Currency } from "../components/Currency";
import { Link } from "react-router-dom";
import { gql } from "@apollo/client";
import { TransactionFragment } from "../generated/graphql";
import { TransactionCategory } from "./types";
import { useTranslation } from "react-i18next";
import { groupBy } from "lodash";
import { useCallback, useMemo } from "react";

type TransactionListProps = { transactions: TransactionFragment[] };

type TransactionItemProps = {
  transaction: TransactionFragment;
  inert?: boolean;
};

const transactionLabel = (transaction: TransactionFragment) => {
  const tag = transaction?.category_id;
  if (transaction?.date && new Date(transaction?.date) > new Date()) {
    return "category.incoming";
  }

  if (transaction?.value > 0 && tag !== TransactionCategory.off_budget)
    return "category.income";

  if (tag === TransactionCategory.wants) return "category.wants";

  if (tag === TransactionCategory.needs) return "category.needs";

  if (tag === TransactionCategory.goals) return "category.goals";

  if (tag === TransactionCategory.off_budget) return "category.offBudget";

  return "category.uncategorized";
};

const TransactionIcon = (transaction?: TransactionFragment) => {
  const { t } = useTranslation();
  const tag = transaction?.category_id;
  if (transaction?.date && new Date(transaction?.date) > new Date())
    return (
      <CircleIcon
        icon={<Icon as={MdHourglassBottom} boxSize={5} />}
        boxSize={10}
        color={theme.colors.casBlueGrey}
        backgroundColor={theme.colors.casLightGrey}
        aria-label={t("category.incoming")}
      />
    );
  if (transaction?.value > 0 && tag !== TransactionCategory.off_budget)
    return <IncomeIcon boxSize={10} aria-label={t("category.income")} />;
  if (tag === TransactionCategory.wants)
    return <ShoppingIcon boxSize={10} aria-label={t("category.wants")} />;
  if (tag === TransactionCategory.needs)
    return <ExpenseIcon boxSize={10} aria-label={t("category.needs")} />;
  if (tag === TransactionCategory.goals)
    return <SavingsIcon boxSize={10} aria-label={t("category.goals")} />;
  if (tag === TransactionCategory.off_budget)
    return <SettingsIcon boxSize={10} aria-label={t("category.offBudget")} />;
  return (
    <QuestionIcon
      boxSize={10}
      aria-label={t("category.uncategorized", { context: "female" })}
    />
  );
};

export const TransactionItem = ({
  transaction,
  inert,
}: TransactionItemProps) => {
  return inert ? (
    <InertTransactionItem transaction={transaction} />
  ) : (
    <InteractiveTransactionItem transaction={transaction} />
  );
};

const InteractiveTransactionItem = ({ transaction }: TransactionItemProps) => {
  const { t } = useTranslation();
  return (
    <ListItem>
      <LinkBox>
        <Grid
          templateColumns={"repeat(16, 1fr)"}
          gap={4}
          alignItems={"flex-start"}
        >
          <GridItem
            colSpan={1}
            position={"relative"}
            display={{ base: "none", md: "block" }}
          >
            {TransactionIcon(transaction)}
          </GridItem>
          <GridItem colSpan={{ base: 10, md: 12 }}>
            <LinkOverlay as={Link} to={`transaction/${transaction.id}`}>
              <Text
                fontSize={{ base: "sm", md: "md" }}
                color="casDarkBlue"
                fontWeight="medium"
                noOfLines={1}
              >
                {transaction.wording}
              </Text>
              <Text fontSize={{ base: "xs", md: "sm" }} color="casBlueGrey">
                {t(transactionLabel(transaction))}
              </Text>
            </LinkOverlay>
          </GridItem>
          <GridItem colSpan={{ base: 6, md: 3 }}>
            <Text
              fontSize={{ base: "sm", md: "md" }}
              fontWeight="medium"
              color={transaction.value < 0 ? "casRed" : "casGreen"}
              textAlign={"right"}
            >
              <Currency value={transaction.value || 0} />
            </Text>
          </GridItem>
        </Grid>
      </LinkBox>
    </ListItem>
  );
};

const InertTransactionItem = ({ transaction }: TransactionItemProps) => {
  const { t } = useTranslation();
  return (
    <ListItem>
      <Grid
        templateColumns={"repeat(16, 1fr)"}
        gap={4}
        alignItems={"flex-start"}
      >
        <GridItem
          colSpan={1}
          position={"relative"}
          display={{ base: "none", md: "block" }}
        >
          {TransactionIcon(transaction)}
        </GridItem>
        <GridItem colSpan={{ base: 10, md: 12 }}>
          <Text
            fontSize={{ base: "sm", md: "md" }}
            color="casDarkBlue"
            fontWeight="medium"
            noOfLines={1}
          >
            {transaction.wording}
          </Text>
          <Text fontSize={{ base: "xs", md: "sm" }} color="casBlueGrey">
            {t(transactionLabel(transaction))}
          </Text>
        </GridItem>
        <GridItem colSpan={{ base: 6, md: 3 }}>
          <Text
            fontSize={{ base: "sm", md: "md" }}
            fontWeight="medium"
            color={transaction.value < 0 ? "casRed" : "casGreen"}
            textAlign={"right"}
          >
            <Currency value={transaction.value || 0} />
          </Text>
        </GridItem>
      </Grid>
    </ListItem>
  );
};

export const TransactionList = ({ transactions }: TransactionListProps) => {
  const { t, i18n } = useTranslation();

  const transactionsByDate = groupBy(transactions, "date");

  const dates = useMemo(
    () =>
      Object.keys(transactionsByDate).sort(
        (a, b) => new Date(b).getTime() - new Date(a).getTime(),
      ),
    [transactionsByDate],
  );

  const formatDate = useCallback(
    (date: string) => {
      return new Date(date).toLocaleString(i18n.language, {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
    [i18n.language],
  );
  return (
    <>
      {!transactions.length && (
        <Center p={8}>
          <Text textStyle={"noData"}>{t("transactions.noData")}</Text>
        </Center>
      )}
      <List spacing={2}>
        {dates.map((date) => (
          <ListItem key={date}>
            <Box p={2} bg={"gray.100"} my={4}>
              <Heading color="casBlueGrey" size={"sm"}>
                {formatDate(date)}
              </Heading>
            </Box>
            <List spacing={2}>
              {transactionsByDate[date].map((transaction) => (
                <TransactionItem
                  key={transaction.id}
                  transaction={transaction}
                />
              ))}
            </List>
          </ListItem>
        ))}
      </List>
    </>
  );
};

TransactionList.fragments = {
  entry: gql`
    fragment Transaction on transactions_new {
      id
      date
      value
      wording
      category_id
    }
  `,
};
