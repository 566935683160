import { FormLayout } from "../components/FormLayout";
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Button,
  ButtonGroup,
  chakra,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useMemo, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { SectionHeading } from "../components/SectionHeading";
import { useTranslation } from "react-i18next";
import { gql, useMutation, useSuspenseQuery } from "@apollo/client";
import {
  AccountsQuery,
  GoalDetailsFragment,
  GoalDetailsQuery,
  Goals_Insert_Input,
  SaveGoalMutation,
  SaveGoalMutationVariables,
} from "../generated/graphql";
import { Link, useNavigate, useParams } from "react-router-dom";
import { differenceInMonths } from "date-fns";
import { Currency } from "../components/Currency";
import { MdDelete, MdEuro } from "react-icons/md";
import { parseNumber, toLocaleNumber } from "../utils/utils.ts";
import { useAuth } from "../Auth/useAuth.ts";
import { useCurrency } from "../utils/useCurrency.ts";
import { useProfile } from "../User/useProfile.tsx";

export const GoalDetails = () => {
  const { user } = useAuth();
  const profile = useProfile();
  const { id } = useParams<{ id: string }>();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { format } = useCurrency();

  const { data: accountsData } = useSuspenseQuery<AccountsQuery>(ACCOUNTS);
  const { data: goal } = useSuspenseQuery<GoalDetailsQuery>(GOAL_DETAILS, {
    variables: {
      goal_id: id,
    },
    skip: !id,
  });

  const { register, control, handleSubmit, watch, setValue } =
    useForm<GoalFormData>({
      defaultValues: {
        name: "",
        target_amount: 0,
        initial_amount: 0,
        target_date: new Date(),
      },
      values: toFormData(goal as GoalDetailsQuery),
    });

  const [saveGoal, { loading: savingGoal, error }] = useMutation<
    SaveGoalMutation,
    SaveGoalMutationVariables
  >(SAVE_GOAL, {
    refetchQueries: ["GoalsSnapshot"],
  });

  const onSubmit = async (data: GoalFormData) => {
    await saveGoal({
      variables: {
        goal: {
          ...data,
          currency: profile.preferredCurrency,
          user_id: user?.uid,
          id,
          target_date: data.target_date || null,
          account_id: data.account_id || null,
        } as Goals_Insert_Input,
      },
    });
    navigate("/");
  };

  const contribution = useMemo(() => {
    const now = new Date();
    const targetAmount = watch("target_amount");
    const initialAmount = watch("initial_amount");
    const targetDate = watch("target_date");
    const parsedTargetDate = new Date(targetDate);

    if (!targetAmount || !initialAmount || !targetDate) {
      return 0;
    }

    // calc contribution per month
    const months = Math.abs(differenceInMonths(parsedTargetDate, now));

    if (months === 0) {
      return 0;
    }

    return Math.round((targetAmount - initialAmount) / months);
  }, [watch(["target_amount", "initial_amount", "target_date"])]);

  useEffect(() => {
    const value = watch("account_id");

    if (!value) {
      return;
    }

    const selectedAccount = accountsData?.accounts.find(
      (account) => account.id === value,
    );

    setValue("initial_amount", Math.round(selectedAccount?.balance));
  }, [watch("account_id")]);

  return (
    <FormLayout>
      <HStack justifyContent={"flex-end"}>
        <DeleteGoal goal={goal?.goal as GoalDetailsFragment} />
      </HStack>
      <VStack
        as={"form"}
        onSubmit={handleSubmit(onSubmit)}
        spacing={4}
        alignItems={"stretch"}
      >
        <SectionHeading>
          {id ? t("goalDetails.edit") : t("goalDetails.add")}
        </SectionHeading>

        {error && (
          <Alert status={"error"}>
            <AlertIcon />
            {error.message}
          </Alert>
        )}

        <FormControl isRequired>
          <FormLabel>{t("goalDetails.name")}</FormLabel>
          <Input
            type={"text"}
            {...register("name", {
              required: true,
            })}
            placeholder={t("goalDetails.namePlaceholder")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>{t("goalDetails.linkAccount")}</FormLabel>
          <Select
            placeholder={t("goalDetails.linkAccountPlaceholder")}
            {...register("account_id")}
          >
            {accountsData?.accounts.map((account) => (
              <option key={account.id} value={account.id}>
                {account.name} <Currency value={account.balance} />
              </option>
            ))}
          </Select>
          <FormHelperText>{t("goalDetails.linkAccountHelper")}</FormHelperText>
        </FormControl>

        <HStack>
          <FormControl isRequired>
            <FormLabel>{t("goalDetails.targetAmount")}</FormLabel>
            <InputGroup>
              <Controller
                render={({ field }) => (
                  <Input
                    {...field}
                    inputMode={"numeric"}
                    value={toLocaleNumber(field.value, i18n.language)}
                    onBlur={(e) => field.onChange(parseNumber(e.target.value))}
                  />
                )}
                name={"target_amount"}
                control={control}
              />
              <InputRightElement>
                <MdEuro />
              </InputRightElement>
            </InputGroup>
          </FormControl>

          <FormControl>
            <FormLabel>{t("goalDetails.initialAmount")}</FormLabel>
            <InputGroup>
              <Controller
                render={({ field }) => (
                  <Input
                    {...field}
                    inputMode={"numeric"}
                    value={toLocaleNumber(field.value, i18n.language)}
                    onBlur={(e) => field.onChange(parseNumber(e.target.value))}
                  />
                )}
                name={"initial_amount"}
                control={control}
              />
              <InputRightElement>
                <MdEuro />
              </InputRightElement>
            </InputGroup>
          </FormControl>
        </HStack>

        <FormControl>
          <FormLabel>{t("goalDetails.targetDate")}</FormLabel>
          <Input type={"date"} {...register("target_date")} />
        </FormControl>

        {contribution > 0 && (
          <Alert status="info">
            <AlertIcon />
            {t("goalDetails.monthlyContribution", {
              amount: format(contribution),
            })}
          </Alert>
        )}
        <Text as={"small"}>
          <chakra.span color={"red.500"}>*</chakra.span>{" "}
          {t("common.requiredField")}
        </Text>
        <ButtonGroup alignSelf={"flex-end"}>
          <Button as={Link} to={"/"}>
            {t("common.cancel")}
          </Button>
          <Button type={"submit"} colorScheme={"blue"} isLoading={savingGoal}>
            {t("common.save")}
          </Button>
        </ButtonGroup>
      </VStack>
    </FormLayout>
  );
};

GoalDetails.fragments = {
  goal: gql`
    fragment GoalDetails on goals {
      id
      name
      target_amount
      initial_amount
      target_date
      account_id
    }
  `,
};

interface DeleteGoalProps {
  goal: GoalDetailsFragment | undefined;
}

const DeleteGoal = ({ goal }: DeleteGoalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const [deleteGoal, { loading }] = useMutation(DELETE_GOAL, {
    refetchQueries: ["GoalDetails"],
    onCompleted: () => {
      onClose();
      navigate("/");
    },
    variables: {
      id: goal?.id,
    },
  });

  const handleDelete = async () => {
    await deleteGoal();
  };

  return (
    <>
      <IconButton
        icon={<MdDelete />}
        variant={"outline"}
        colorScheme={"red"}
        onClick={onOpen}
        aria-label={t("common.delete")}
      />
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t("goalDetails.delete")}
            </AlertDialogHeader>

            <AlertDialogBody>
              {t("goalDetails.deleteConfirmation")}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {t("common.cancel")}
              </Button>
              <Button
                colorScheme="red"
                onClick={handleDelete}
                ml={3}
                isLoading={loading}
                isDisabled={!goal}
              >
                {t("common.delete")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

type GoalFormData = {
  name: string;
  description: string;
  target_amount: number;
  initial_amount: number;
  target_date: Date;
  account_id: string;
};

const DELETE_GOAL = gql`
  mutation DeleteGoal($id: uuid!) {
    delete_goals_by_pk(id: $id) {
      id
    }
  }
`;

const SAVE_GOAL = gql`
  mutation SaveGoal($goal: goals_insert_input!) {
    insert_goals_one(
      object: $goal
      on_conflict: {
        constraint: goals_pkey
        update_columns: [
          name
          initial_amount
          target_amount
          target_date
          account_id
        ]
      }
    ) {
      id
    }
  }
`;

const ACCOUNTS = gql`
  query Accounts {
    accounts: accounts_new(order_by: { name: asc }) {
      id
      name
      balance
    }
  }
`;

const GOAL_DETAILS = gql`
  query GoalDetails($goal_id: uuid!) {
    goal: goals_by_pk(id: $goal_id) {
      ...GoalDetails
    }
  }
  ${GoalDetails.fragments.goal}
`;

const toFormData = (data: GoalDetailsQuery) => {
  if (!data) return;

  const { goal } = data;

  return {
    name: goal?.name,
    target_amount: goal?.target_amount,
    initial_amount: goal?.initial_amount,
    target_date: goal?.target_date,
    account_id: goal?.account_id,
  } as GoalFormData;
};
