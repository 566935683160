import { Heading, TextProps } from "@chakra-ui/react";
import { ReactNode } from "react";

interface SectionHeadingProps extends TextProps {
  children: ReactNode;
}

export const SectionHeading = ({ children, ...props }: SectionHeadingProps) => {
  return (
    <Heading
      as="h2"
      size={{ base: "sm", md: "md" }}
      color="casDarkBlue"
      {...props}
    >
      {children}
    </Heading>
  );
};
