import { t } from "i18next";

export const accountTypes = [
  {
    name: "checking",
    displayName: t("accountTypes.checking.name"),
  },
  {
    name: "savings",
    displayName: t("accountTypes.savings.name"),
  },
  {
    name: "liability",
    displayName: t("accountTypes.liabilities.name"),
  },
  {
    name: "investment",
    displayName: t("accountTypes.investment.name"),
  },
  {
    name: "misc",
    displayName: t("accountTypes.misc.name"),
  },
  {
    name: "real_estate",
    displayName: t("accountTypes.realEstate.name"),
  },
  {
    name: "other",
    displayName: t("common.other"),
  },
];
